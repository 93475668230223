import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { cloneCampaign, createCampaign, generateMarketingPlan, getCampaign, updateCampaign, updateObject } from "@/api/campaigns";
import { getSessionToken, getTokenInfo } from "@/api/auth";
import { ItemGroup } from "./components/ItemGroup";

import Avatar from "@/ui/Avatar";

import { CampaignBuilder } from "../Funnels";
import { PopupSizes } from "@/ui/Modal/types";
import Button from "@/ui/Button";
import { ButtonTypes } from "@/ui/types";
import { Tag } from "./components/Tag";
import Modal from "@/ui/Modal";
import { Sparkle } from "@phosphor-icons/react";
import { Loading } from "@/ui/Layout/Loading";
import { getFunnelsInCatalog } from "@/api/catalog";
import { useCompletion } from "ai/react";

import { AGENTS_DOMAIN, AI_DOMAIN } from "@launchos/shared-utils/env";
import { getSystemPrompt } from "../AITools/ChatBot/prompts";
import promptToGenerateName from "../AITools/prompts/title";

import { isJsonString } from "@/utils";
import { complete, createOrUpdateChat } from "@/api/ai";
import artifacts from "../AITools/prompts/artifacts";
import create_funnel from "../Funnels/prompts/create_funnel";

interface FunnelItemDetailsProps {
  campaignId: string;
  title?: string;
  description?: string;
  categories?: string[];
  ownerDetails?: { src: string; label: string; caption: string };
  onClose?: () => void;
}

export const FunnelItemDetails: React.FC<FunnelItemDetailsProps> = ({
  campaignId,
  // image = "https://sandcastleassets.s3.amazonaws.com/templates/pageid/ckxq3zol5904z08119y28wgkw.png",
  title = "Funnel Framework",
  description = "This is a funnel framework that you can use to build your own funnel. It's a great starting point for your funnel and you can customize it however you want.",
  categories = ["Sales", "Sales", "Sales", "Sales"],
  ownerDetails = {
    src: "https://flowbite.com/docs/images/logo.svg",
    label: "By Launch OS",
    caption: "support@launchos.com",
  },
  onClose = () => false,
}) => {
  // const [campaignData, setCampaignData] = useState({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleCreateFunnel = async (withAI=false) => {
    setIsLoading(true);
    // console.log("handleCreateFunnel", item);

    // const campaignId = item?.campaign?.id;
    const token = getSessionToken();
    let name = '';
    let userPrompt;
    let chatCampaign;

    if (withAI) {
      userPrompt = window.prompt(
        "What kind of funnel would you like to create? (For example: 'A sales funnel for a fitness coaching business')"
      );
      if (userPrompt === null) return; // Exit if user cancels the prompt
      name = await complete(promptToGenerateName, userPrompt);

      // 1. [withAI] create a new chat campaign
      // 2. [withAI] add two messages: the prompt and the first response…
      // 3. [withAI] the response should be in artifact format ← set up artifact to respond properly (prompt format)
      chatCampaign = await createOrUpdateChat({
        messages: [],
        input: userPrompt,
        lastMessage: {
          id: Math.random().toString(36).substr(2, 9),
          role: "assistant",
          content: await complete(create_funnel(userPrompt), artifacts),
        },
        name,
      })

    } else {
      const funnelName = prompt("Enter a name for your funnel");
      if (funnelName !== null) name = funnelName;
      else return; // Exit the function if the user cancels the prompt
    }

    const response = await cloneCampaign(campaignId, { token, name });

    // console.log({ withAI }, "cloneCampaign", response);
    const newCampaignId = response?.data?.cloneCampaign?.response?.id;

    if (withAI) {
      // update the campaign to have a with AI flag

      // get all the page objects that are in the new campaign
      const campaignData = await getCampaign({ token, id: newCampaignId });
      console.log("getCampaign Response", campaignData);

      // 4. [withAI] when the campaign is created, store the chat id with the withAI settings so we know which one to load
      await updateCampaign(newCampaignId, {
        token,
        settings: {
          type: "", //"funnel",
          settings: {
            ...campaignData?.settings || {},
            withAI: true,
            chatSettings: {
              initialPrompt: userPrompt,
              chatSessionId: chatCampaign?.id,
            }
          }
        }
      })

      // 5. [withAI] when the campaign is loaded, if withAI, set copilot to be open
      // 6. [withAI] and set the loaded campaign to be the id of the chat campaign we created.

      const campaignObjects = campaignData?.objects || [];
      const pageObjects = campaignObjects.filter(({ type }) => type === "PageComponent");

      // loop through them and update the settings to have a withAI flag
      console.log("pageObjects", pageObjects);

      for (let i = 0; i < pageObjects.length; i++) {
        const object = pageObjects[i];
        console.log("object", object);

        const settings = object?.settings || {};
        console.log("settings", settings);

        const r = await updateObject(object.id, {
          token,
          settings: { ...settings.settings, withAI: true },
        });

        console.log("updateObject Response", r);

      }
    }

    if (newCampaignId) {
      // navigate to the new funnel
      navigate(`/funnels/${newCampaignId}`);
      setIsLoading(false);
    }
  };

  return (
    <Modal footer={false} size={PopupSizes.XXXLARGE} onClose={onClose}>
      {isLoading ? (
        <div className="p-9">
          <Loading type="gallery" />
        </div>
      ) : (
        <>
          <div
            className="rounded-lg overflow-hidden w-full h-96 mb-7 border dark:border-white/5"
            style={{ marginTop: "-1.75rem" }}
          >
            {campaignId && (
              <CampaignBuilder id={campaignId} readOnly showMiniMap={false} />
            )}
          </div>
          <div className="grid md:grid-cols-12 gap-7 px-3 w-full">
            <div className="rounded-lg overflow-hidden flex flex-col md:col-span-8">
              <h1 className="text-2xl font-bold mb-4">{title}</h1>
              <p
                className="text-md mb-2"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </div>
            <div className="flex flex-col gap-3 md:col-span-4 dark:bg-white/5 rounded-lg p-7">
              <Button
                label="Launch using this funnel"
                type={ButtonTypes.DEFAULT}
                className="w-full"
                onClick={() => handleCreateFunnel(false)}
              />

              {/* <Button
                label="✨ Launch using A.I."
                type={ButtonTypes.OUTLINED}
                defaultIcon={<Sparkle className="ml-3 text-lg" />}
                className="mt-4 w-full"
                onClick={() => handleCreateFunnel(true)}
              /> */}

              {Boolean(categories.length) && (
                <>
                  <div className="h-px dark:bg-white/5 bg-black/5 block my-8"></div>
                  <h2 className="text-lg font-bold mb-2">Tags</h2>
                  <div className="mb-7">
                    {categories.map((category, key) => (
                      <Tag key={key}>{category.toUpperCase()}</Tag>
                    ))}
                  </div>
                </>
              )}

              {/* divider */}
              <div className="h-px dark:bg-white/5 bg-black/5 block my-8"></div>

              <div className="mb-7">
                <Avatar type="Text" {...ownerDetails} />
              </div>

              <p className="text-xs text-black/40 dark:text-white/40">
                Last updated 6 months ago
              </p>
              <p className="text-xs text-black/40 dark:text-white/40">
                support@tenminutefunnels.com
              </p>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};

export const recommendFunnelFromContext = async ({ token, choices = [] }) => {
    console.log("recommendFunnelFromContext", token, choices);
    const userDetails = await getTokenInfo(token);

    const content = `
    
    Here is the list of funnels to choose from in JSON format.
    
    '''
    ${JSON.stringify(
      choices.slice(1).map(({ id, name, description, skill, categories }) => ({
        id,
        name,
        description: description.replace(/<[^>]*>/g, ""),
        skill,
        category: categories,
      })),
      null,
      2
    )}
    '''
    
    `;
    const promptObj = await getSystemPrompt({
      pageSettings: [],
      userDetails,
      assistant: { id: "marketResearch" },
    });

    const prompt = `${promptObj.mainStartPrompt}
    
    Based on the list of funnel choices provided below and the context provided about the user and their business, please choose one (1) to three (3) funnels that you think would be most helpful for the user.

    This is important: in your response, return a JSON array of ONLY the id's of the funnels you think would be most helpful, and nothing else.  No comments, no conversation, nothing.  ONLY the ids.  For example, if you think the funnels with the id's "123", "456", and "789" would be most helpful, you would return: ["123", "456", "789"].  Under absolutely no circumstsances are you to return anything other than a JSON array of the 1-3 id's of the funnels you think would be most helpful.
    
    ${content}
    
    ${promptObj.profileContextPrompt}`;

    let response;
    let attempts = 0;
    const maxAttempts = 3;

    while (attempts < maxAttempts) {
      try {
        console.log("About to try", attempts);
        const r = await fetch(`${AI_DOMAIN}/api/completion`, { 
          method: "post", 
          body: JSON.stringify({ prompt }) 
        });
        response = await r.json();

        if (isJsonString(response.text)) {
          break;
        }
      } catch (error) {
        console.error(`Attempt ${attempts + 1} failed:`, error);
      }

      attempts++;
    }

    if (!response || !isJsonString(response.text)) {
      console.error(`Failed to get a valid JSON response after ${maxAttempts} attempts`);
    }

  return response?.text;
}

const RecommendedFunnels: React.FC<{ choices: any[] }> = ({ 
  choices = [],
  setFunnelItemDetailsIsShowing = () => {},
  setActiveCatalogItem = () => null,
}) => {

  const [selections, setSelections] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {

    const loadSelections = async () => {
      // to do - cache this (store results in localStorage, and clear localStorage whenever saveData in smart planner is called)
      // skip this if recommendations are already in localStorage
      console.log("Loading Selections")
      
      const recommendedFunnels = localStorage.getItem("recommendedFunnels");
      console.log("Loading Selections", recommendedFunnels);
      if (recommendedFunnels) {
        setSelections(
          choices.filter(({ id }) => recommendedFunnels.includes(id))
        );
        setIsLoading(false);
        return;
      }

      const token = getSessionToken();
      console.log("About to load recommendFunnelFromContext", token);
      const recommendations = await recommendFunnelFromContext(
        { token, choices },
        // complete
      );

      setIsLoading(false);

      setSelections(choices.filter(({ id }) => recommendations.includes(id)));
      console.log("Recommended Funnels", recommendations);
      localStorage.setItem("recommendedFunnels", recommendations);
    }

    loadSelections();
  }, []);

  if (isLoading) return <Loading type="gallery" />;

  return (
    <>
      <ItemGroup
        title={"✨ A.I. Recommended Funnels"}
        caption="Based on what we know about you and your business, we think these funnels are right for you"
        // amountToShow={3}
        items={selections}
        customCardStyles={{
          backgroundSize: "contain",
          transition: "margin-top 0.25s ease",
        }}
        // href={`/launchpad/${type}`}
        onItemClick={(item) => {
          setActiveCatalogItem(item);
          setFunnelItemDetailsIsShowing(true);
        }}
      />
    </>
  );
};

interface FunnelGroupProps {
  type?: string;
  showAll?: boolean;
  tagFilter?: string[];
}
export const FunnelGroup: React.FC<FunnelGroupProps> = ({
  type,
  showAll = false,
}) => {
  const [funnelCampaigns, setFunnelCampaigns] = useState<any[]>([]);
  const [funnelItemDetailsIsShowing, setFunnelItemDetailsIsShowing] =
    useState(false);
  const [activeCatalogItem, setActiveCatalogItem] = useState(null);

  const loadCatalogCampaigns = async () => {
    const items = await getFunnelsInCatalog();
    setFunnelCampaigns(items);
  };

  useEffect(() => {
    loadCatalogCampaigns();
  }, []);

  if (!funnelCampaigns.length)
    return <Loading type="gallery" className="py-8" />;

  return (
    <>
      {funnelItemDetailsIsShowing && (
        <FunnelItemDetails
          campaignId={activeCatalogItem?.campaign?.id}
          title={activeCatalogItem?.name}
          description={activeCatalogItem?.description}
          categories={activeCatalogItem?.categories?.split(",") || []}
          onClose={() => setFunnelItemDetailsIsShowing(false)}
        />
      )}

      {showAll && (
        <RecommendedFunnels
          choices={funnelCampaigns}
          setFunnelItemDetailsIsShowing={setFunnelItemDetailsIsShowing}
          setActiveCatalogItem={setActiveCatalogItem}
        />
      )}

      <ItemGroup
        title={type.charAt(0).toUpperCase() + type.slice(1)}
        {...(!showAll && { amountToShow: 3 })}
        items={funnelCampaigns}
        customCardStyles={{
          backgroundSize: "contain",
          transition: "margin-top 0.25s ease",
        }}
        href={`/launchpad/${type}`}
        onItemClick={(item) => {
          setActiveCatalogItem(item);
          setFunnelItemDetailsIsShowing(true);
        }}
      />
    </>
  );
};
