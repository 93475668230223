import { useEffect, useContext, useState } from "react";

import { useNavigate } from "react-router-dom";
import { has, keys } from "lodash";

import { Topbar, LeftSidebar, RightSidebar } from "..";

import { getBasicUserInfo, getSessionToken, getTokenInfo, isLoggedIn } from "@/api/auth";
import { AppContext } from "@/AppContext";
import { ContainerProps, PageProps } from "./types";
import { triggerAsyncHook } from "@/plugins/client";
import { HookTriggers, ListItemsHooks } from "@/plugins/types";
import { NavGroupItem } from "../LeftSidebar/types";

// import { getSessionToken } from "@/api/auth";
import { CopilotWrapper } from "@/Apps/AITools/CopilotWrapper";
import { TourProvider, useTour } from "@reactour/tour";

import { AppThumbnail } from "@/Apps/Launchpad/components/Card";
import { PopupSizes } from "@/ui/Modal/types";

// Import tour manager functions
import { shouldShowTour, markTourCompleted } from "@/tourManager";

const TourStepContent = ({
  title,
  caption,
  image,
  video,
}: {
  title: string;
  caption: string;
  image: string;
  video: string;
}) => {
  return (
    <div className="relative -z-10">
      {(image || video) && (
        <div className="overflow-hidden max-h-[250px]">
          <AppThumbnail
            backgroundImage={image}
            backgroundVideo={video}
            title={title}
            size={PopupSizes.LARGE}
          />
        </div>
      )}
      <div className="flex flex-col pt-4 px-2 gap-2 items-center justify-center">
        <p className="text-xl text-center text-black font-bold">{title}</p>
        <p className="text-sm text-center text-gray-500">{caption}</p>
      </div>
    </div>
  );
};

export const Container: React.FC<ContainerProps> = ({
  children = <></>,
  className = "",
  fullWidth = false,
}) => {
  return (
    // Start Container
    <div
      className={`p-7 h-full ${className}`}
    >
      {/* Start Max Width Container */}
      {!fullWidth ? (
        <div className="mx-auto max-w-6xl h-full">{children}</div>
      ) : (
        children
      )}
      {/* End Max Width Container */}
    </div>
    // End Container
  );
};

export const Page: React.FC<PageProps> = ({
  tour = [],
  tourKeys,
  topBar = {},
  copilotSettings = {},
  children,
}) => {
  // Define state for the menu items
  const [menuItems, setMenuItems] = useState<NavGroupItem[]>([]);
  const [userInfo, setUserInfo] = useState<any>({});
  const [loggedIn, setIsLoggedIn] = useState<boolean>(false);  
  // New state for tour visibility
  const [showTour, setShowTour] = useState<boolean>(false);

  const { isOpen, setIsOpen } = useTour();

  // Get the app context
  const contextObj = useContext(AppContext);
  const { disableRightSidebar, copilotOpen, darkMode } = contextObj;
  const [copilotCampaignName, setCopilotCampaignName] = useState();
  const [copilotCampaignId, setCopilotCampaignId] = useState(copilotSettings?.id);

  // Check if logged in (check for a token)
  // const navigate = useNavigate();

  // Function to check if the token is valid
  // const checkToken = async () => {
  //   // Get the token from local storage
  //   const token = getSessionToken();
  //   // Call the API to check if the token is valid
  //   const response = await getTokenInfo(token);
  //   // Set the user info state
  //   setUserInfo(response);

  //   // If the response has an error property, redirect to login page with error message
  //   if (has(response, "error")) {
  //     navigate("/login", { state: { error: response.error } });
  //   }
  // };
  const checkToken = () => {
    const token = getSessionToken();
    const isUserloggedIn = Boolean(token);
    setIsLoggedIn(isUserloggedIn);

    if (isUserloggedIn) {
      const response = getBasicUserInfo();
      console.log("Basic User Info", response);
      setUserInfo(response);
    }
  }

  // Function to load in all menu items
  const loadInAllMenuItems = async () => {
    // Trigger the onListItems hook to get the menu items
    const menuItemsFromPlugin = await triggerAsyncHook(
      HookTriggers.onListItems,
      {
        id: ListItemsHooks.APP_DRAWER,
        position: "top",
      },
      null,
      null,
      contextObj
    );

    // Set the menu items state
    setMenuItems(menuItemsFromPlugin);
  };

  // Call checkToken function on component mount
  useEffect(() => {
    checkToken();
    loadInAllMenuItems();
  }, [contextObj]);

  useEffect(() => {
    contextObj?.setCopilotOpen(false); // start with a closed copilot
    // console.log("topBar", topBar?.page?.map(({ label }) => label))
  }, [])

  useEffect(() => {
    if (copilotSettings.id) setCopilotCampaignId(copilotSettings.id);
  }, [copilotSettings.id])

  // Check if the tour should be shown - now using tourKeys directly
  useEffect(() => {
    if (tour && tour.length > 0 && tourKeys) {
      const { groupKey, pageKey } = tourKeys;
      const shouldShow = shouldShowTour(pageKey, groupKey);
      setShowTour(shouldShow);
    }
  }, [tour, tourKeys]);

  // Handler for tour completion - now using tourKeys directly
  const handleTourComplete = () => {
    if (tourKeys) {
      markTourCompleted(tourKeys.pageKey);
    }
  };

  // Handler for tour skipping - now using tourKeys directly
  const handleTourSkip = () => {
    if (!tourKeys) return false;
    
    // If on the last step, just mark as completed without confirmation
    if (currentStep === tour.length - 1) {
      markTourCompleted(tourKeys.pageKey);
      setIsOpen(false);
      return true;
    }
    
    // Show a confirmation dialog
    const confirmSkip = window.confirm('Would you like to skip this tour? You can always restart it later if needed.');
    if (confirmSkip) {
      markTourCompleted(tourKeys.pageKey);
      setIsOpen(false);
    }
    // return confirmSkip;
  };

  // Add state to track current step
  const [currentStep, setCurrentStep] = useState(0);

  // Add this to detect tour completion
  useEffect(() => {
    if (currentStep === tour.length - 1 && tour.length > 1) {
      handleTourComplete();
    }
  }, [currentStep]);

  if (!loggedIn) return (
    <div className="main-content h-[100vh] flex-1">
      <CopilotWrapper
        id={copilotCampaignId}
        setCampaignId={setCopilotCampaignId}
        setCopilotCampaignname={setCopilotCampaignName}
        {...copilotSettings}
      >
        {children}
      </CopilotWrapper>
    </div>
  );

  return (
    <>
      <TourProvider
        key={showTour ? 'tour-open' : 'tour-closed'}
        steps={tour.map(
          ({ selector, title, content, image, video }) => ({
            selector,
            content: (
              <TourStepContent
                title={title}
                caption={content}
                image={image}
                video={video}
              />
            ),
          })
        )}
        showBadge={false}
        styles={{
          popover: (base) => ({
            ...base,
            padding: 0,
            borderRadius: 5,
            overflow: "hidden",
          }),
          controls: (base) => ({
            ...base,
            padding: "0 20px 20px",
          }),
        }}
        defaultOpen={showTour || isOpen}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        onClickClose={() => {
          handleTourSkip();
          setShowTour(false);
        }}
      >
        {/* Render the Sidebar */}
        <LeftSidebar
          menuItems={menuItems}
          avatarSettings={userInfo}
          showLogo={false}
          showSupportSection={userInfo?.permissions?.includes("launchOS")}
          // showSupportSection={false}
          showCopilotButton={userInfo?.permissions?.includes("copilot")}
        />

        {/* Render the Page Content */}
        <div
          data-testid="Page"
          className="main-content flex-1"
          // className="flex-1"
          style={disableRightSidebar ? { marginRight: 0 } : {}}
        >
          {/* Render the top bar component */}
          <Topbar {...topBar} />

          {/* Start Content */}
          <div
            data-testid="Page-Content"
            className="h-[calc(100vh-65px)] overflow-y-auto overflow-x-hidden"
          >
            {/* Render the child components */}
            {copilotOpen ? (
              <CopilotWrapper
                id={copilotCampaignId}
                setCampaignId={setCopilotCampaignId}
                setCopilotCampaignname={setCopilotCampaignName}
                {...copilotSettings}
              >
                {children}
              </CopilotWrapper>
            ) : (
              children
            )}
          </div>
          {/* End Content */}
        </div>

        {/* Render the Right Sidebar if not disabled */}
        {!disableRightSidebar && (
          <RightSidebar
            notifications={false}
            activities={false}
            contacts={false}
            chat={true}
            pageSettings={topBar?.page}
          />
        )}
      </TourProvider>
    </>
  );
};
