import { Page } from "@/ui/Layout";
import { Container } from "@/ui/Layout/Page";
import { Button } from "@/ui/Button";
import { ButtonTypes } from "@/ui/types";

import { FunnelGroup } from "./FunnelGroup";
import { PagesGroup } from "./PagesGroup";
import { BigBanner, BannerButton } from "./components/BigBanner";
import { useContext } from "react";
import { AppContext } from "@/AppContext";
import { AppGroup } from "./AppGroup";
import * as tours from "@/tours";

export const Launchpad = ({ type }) => {
  const { permissions = [] } = useContext(AppContext);

  const crumbs = [
    { label: "Launch Pad", href: "/launchpad" },
    ...(type !== "all"
      ? [{ label: type.charAt(0).toUpperCase() + type.slice(1) }]
      : []),
  ];

  // Determine if we should show the Pages template tour
  const isPagesTour = crumbs[1]?.label === "Pages";

  return (
    <Page
      tour={isPagesTour ? tours?.pages.template : []}
      tourKeys={isPagesTour ? { groupKey: 'pages', pageKey: 'template' } : undefined}
      topBar={{
        page: crumbs,
      }}
    >
      <Container>
        <div className="mx-auto max-w-5xl">
          {type === "all" && (
            <>
              <BigBanner
                title={`Welcome to the Launch Pad`}
                caption="Here you can find all the tools you need to launch your business."
              >
                <div className="grid gap-4 sm:grid-cols-3 justify-center max-w-2xl mx-auto">
                  <BannerButton label="Apps & Tools" href="/launchpad/apps" />
                  {permissions.includes("funnels") && (
                    <BannerButton
                      label="Funnel Frameworks"
                      href="/launchpad/funnels"
                    />
                  )}
                  {permissions.includes("pages") && (
                    <BannerButton
                      label="Page Templates"
                      href="/launchpad/pages"
                    />
                  )}
                  {permissions.includes("sites") && (
                    <BannerButton
                      label="Site Templates"
                      href="/launchpad/sites"
                    />
                  )}
                  {permissions.includes("forms") && (
                    <BannerButton
                      label="Forms & Quizzes"
                      href="/launchpad/forms"
                    />
                  )}
                </div>
              </BigBanner>

              {/* <WhatsNewGroup /> */}

              {permissions.includes("funnels") && (
                <FunnelGroup type="funnels" />
              )}

              {permissions.includes("pages") && (
                <PagesGroup type="pages" showAll={false} />
              )}

              {permissions.includes("sites") && (
                <PagesGroup type="sites" tagFilter={["sales", "home"]} />
              )}

              {permissions.includes("forms") && (
                <PagesGroup type="forms" tagFilter={["lead", "form"]} />
              )}

              {/* <AppGroup type="apps" /> */}

              {/* <CommunityGroup /> */}
            </>
          )}

          {type !== "all" && (
            <Button
              label="<- Back to Dashboard"
              type={ButtonTypes.SOFT}
              onClick={() => window.history.back()}
            />
          )}

          {type === "funnels" && <FunnelGroup type="funnels" showAll />}
          {type === "pages" && <PagesGroup type="pages" showAll />}
          {type === "sites" && (
            <PagesGroup
              type="sites"
              showAll
              tagFilter={["all", "sales", "content", "home"]}
            />
          )}
          {type === "forms" && (
            <PagesGroup type="forms" showAll tagFilter={["lead", "form"]} />
          )}
          {type === "apps" && <AppGroup type="apps" showAll />}
        </div>
      </Container>
    </Page>
  );
};
