import { IconTypes } from "@/Apps/Pages/WebComponents/v2/Icon/types";

export enum EditorObjectState {
  ACTIVE = "active",
  HOVER = "hover",
  NORMAL = "normal",
  HIDDEN = "hidden",
}

export enum ActionItems {
  DELETE,
  DUPLICATE,
}

export interface DragHandleProps {
  label: string;
  icon: IconTypes;
  state: EditorObjectState;
  color?: string;
  /**
   * The unique id of the editor object
   */
  id: string;

  /**
   * An array of objects that represent the items in to show in the dropdown menu and associated actions to take (id, label, icon, onSelect) e.g. (Duplicate, Delete, Copy, Paste, Save)
   */
  actions?: any[];
}

export interface EditorObjectProps {
  /**
   * The unique id of the editor object
   */
  id: string;

  /**
   * The caption to title the editor object
   */
  title: string;

  /**
   * The current state of the web object being housed by the editor object
   */
  state?: EditorObjectState;

  /**
   * The icon to use in the title
   */
  icon?: IconTypes;

  /**
   * The color of the editor object borders
   */
  color?: string;

  /**
   * The sytle to use for the editor object
   */
  style?: object;

  /**
   * The resize handles that are enabled (e.g. [`SW`] or [`N`,`S`,`E`,`W`]
   */
  resizeHandles?: any[];

  /**
   * Whether or not the component is resizable
   */
  isResizable?: Boolean;

  /**
   * Whether or not the editor is in `editing` mode
   */
  isEditing?: Boolean;

  /**
   * Whether or not to place a transparent <div /> on the component in order to prevent the user from manipulating the component while editing. For example, videos.  You likely don't want them to be able to play/pause videos while in the editor.  This would place an overlay over the video so that users can't interact with it
   */
  overlay?: Boolean;

  /**
   * An array of objects that represent the items in to show in the dropdown menu and associated actions to take (id, label, icon, onSelect) e.g. (Duplicate, Delete, Copy, Paste, Save)
   */
  actions?: any[];

  /**
   * Custom component to use instead of the default action component
   */
  actionComponent?: React.ReactNode;

  /**
   * The component to use for the object's properties
   */
  propertiesViewComponent?: React.ReactNode;

  /**
   * The component to use for the resize handles
   */
  resizeHandlesComponent?: React.ReactNode;

  /**
   * The component to use for the drag handle
   */
  dragHandleComponent?: React.ReactNode;

  /**
   * TODO: Add a description
   */
  children: React.ReactNode;

  /**
   * Triggers when a resize is supposed to take place
   */
  onResizing?: () => void;

  /**
   * Triggers when a resize has ended
   */
  onResizeEnd?: () => void;

  /**
   * Triggers when the editor object's drag handle has begun dragging
   */
  onDragStart?: () => void;

  /**
   * Triggers when the editor object is clicked
   */
  onClick?: () => void;

  /**
   * Triggers when the `right` mouse button is clicked
   */
  onContextMenu?: () => void;
}
