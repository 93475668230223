import * as React from "react";
import { get, uniq } from "lodash";

// import { RichEditor } from "@launchos/modules/editor/Builder/Properties";
import RichEditor from "@/Apps/Pages/Properties/RichEditor";

import EditorObject from "@/Apps/Pages/EditorObject"; // Uses v1 i think
import PropertiesBuilder from "@/Apps/Pages/Properties/components/generator/Generator";
import { updateSection } from "@/Apps/Pages/Properties/components/generator";
import { SectionTypes } from "@/Apps/Pages/Properties/types";

// import {
//   convertToRem,
//   convertFromRem,
// } from "@launchos/modules/editor/Builder/MobileResponsiveToggle/style";

import {
  generateInlineStyle,
  generateGoogleFontList,
} from "@/Apps/Pages/Properties/RichEditor/utilities";

import { IconTypes } from "../Icon";

import { ListItem } from "./live";
import { PluginProps } from "./types";
import { V1ObjectWrapper, convertProperties } from "../V1ObjectWrapper";

import listItemSettings from "./settings";
import { parseLiquidString } from "@/Apps/Pages/Page/LiquidRenderer";

export const Properties = (props) => {
  const { updateComponentSettings, updateComponentStyle, settings } = props;
  const { properties } = listItemSettings;
  const { sections } = properties.main;

  const withIcon = updateSection(sections, SectionTypes.ICONSELECTOR, {
    onChange: (e) => {
      updateComponentSettings(
        settings.id,
        { ...settings, icon: IconTypes[e] },
        true,
        0
      );
    },
  });

  const withSize = updateSection(withIcon, "customIconSize", {
    // value: get(settings, "properties.fontSize", "1pt").replace("pt", ""),
    onChange: (e) => {
      // console.log("size", e);
      switch (e) {
        case "GROW": {
          const value =
            parseInt(
              get(settings, "properties.fontSize", "1pt").replace("pt")
            ) + 5;

          updateComponentSettings(
            settings.id,
            {
              ...settings,
              iconStyle: { ...settings.iconStyle, fontSize: `${value}pt` },
            },
            // true,
            // 0
          );
          break;
        }
        case "SHRINK": {
          const value =
            parseInt(
              get(settings, "properties.fontSize", "6pt").replace("pt")
            ) - 5;

          // const value = convertFromRem(get(settings, "properties.fontSize"))

          updateComponentSettings(
            settings.id,
            {
              ...settings,
              iconStyle: { ...settings.iconStyle, fontSize: `${value}pt` },
            },
            // true,
            // 0
          );
          break;
        }
        default: {
          updateComponentSettings(
            settings.id,
            {
              ...settings,
              iconStyle: { ...settings.iconStyle, fontSize: `${e}pt` },
            },
            // true,
            // 0
          );
        }
      }
    },
  });

  const withPosition = updateSection(withSize, "customIconPosition", {
    // value: get(settings, "properties.fontSize", "1pt").replace("pt", ""),
    onChange: (e) => {
      console.log("size", e);
      switch (e) {
        case "GROW": {
          const value =
            parseInt(
              get(settings, "properties.fontSize", "1pt").replace("pt")
            ) + 5;
          updateComponentSettings(
            settings.id,
            {
              ...settings,
              iconStyle: { ...settings.iconStyle, marginTop: `${value}pt` },
            },
            // true,
            // false
          );
          break;
        }
        case "SHRINK": {
          const value =
            parseInt(
              get(settings, "properties.fontSize", "6pt").replace("pt")
            ) - 5;
          updateComponentSettings(
            settings.id,
            {
              ...settings,
              iconStyle: { ...settings.iconStyle, marginTop: `${value}pt` },
            },
            // true,
            // false
          );
          break;
        }
        default: {
          updateComponentSettings(
            settings.id,
            {
              ...settings,
              iconStyle: { ...settings.iconStyle, marginTop: `${e}pt` },
            },
            // false,
            // false
          );
        }
      }
    },
  });

  const withColor = updateSection(withPosition, SectionTypes.COLORPICKER, {
    onChange: (e) => {
      updateComponentSettings(
        settings.id,
        {
          ...settings,
          iconStyle: { ...settings.iconStyle, color: e.hex },
        },
        true,
        0
      );
    },
  });

  const updatedProperties = {
    ...properties,
    main: { ...properties.main, sections: withColor },
  };

  return (
    <PropertiesBuilder
      {...props}
      title="List Item Settings"
      data={updatedProperties}
    />
  );
};

export const ListItemComponent: React.FC<PluginProps> = (props) => {
  const [dragHandleIsShowing, setDragHandleIsShowing] = React.useState(true);
  const { id, mode, settings, match, updateComponentSettings, setIsEditing, setCanDrag, setCanUndo, changeState, pageContent } = props;
  const properties = convertProperties(settings.properties);
  // const buttonList = ["bold", "italic", "underline"];

  const buttonList = [
    ["bold", "italic", "underline", "strikethrough"],
    ["lineheight"],
    ["quicklink"],
    ["removeformat"],
  ];

  const menuList = [
    ["undo", "redo"],
    ["fontfamily", "googlefonts", "fontsizes", "align", "lineheight"],
    ["forecolor", "backcolor"],
    ["link", "selectall"],
  ];
  // get list of fonts used
  const googleFontList = generateGoogleFontList(settings, pageContent);

  const handleChange = (html, fontsList) => {
    const currentFontList = get(
      settings,
      "fontsUsed",
      []
    ).map((arr) => get(arr, "fontFamily"));
    const newFontList = uniq([...currentFontList, ...fontsList]);
    const fontsUsed = newFontList.map((font) => ({
      fontFamily: font,
    }));
    updateComponentSettings(settings.id, {
      ...settings,
      html,
      fontsUsed,
    });
  }


  return (
    <>
      <V1ObjectWrapper settings={settings}>
        <EditorObject
          {...props}
          PropertiesView={Properties}
          dragHandle={dragHandleIsShowing}
        >
          <ListItem {...settings} properties={properties} mode={mode}>
            <RichEditor
              id={`ed_${settings.id}`}
              content={parseLiquidString(settings.html)}
              style={generateInlineStyle(settings)}
              buttonList={buttonList}
              menuList={menuList}
              googleFontList={googleFontList}
              onChange={handleChange}
              onInstantChange={() => setDragHandleIsShowing(false)}
              onClick={() => {
                setDragHandleIsShowing(true);
              }}
              onMouseEnter={() => {
                setDragHandleIsShowing(true);
              }}
              onFocus={(e) => {
                console.log("List Item Focus");
                setIsEditing(true);
                setCanDrag(false);
                changeState(settings.id, "active");
                setCanUndo(false);
              }}
              onBlur={(e) => {
                setDragHandleIsShowing(true);
                setCanUndo(true);
                setTimeout(() => {
                  setIsEditing(false);
                  setCanDrag(true);
                }, 200);
              }}
              settings={settings}
            />
          </ListItem>
        </EditorObject>
      </V1ObjectWrapper>
    </>
  );
};
