import Modal, { Title } from "@/ui/Modal";
import TextInput from "@/ui/TextInput";
import { TextInputFlavors } from "@/ui/TextInput/types";
import { useState } from "react";

export const OptinPopup = ({ setOptInPopupIsShowing = () => {} }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  return (
    <Modal
      onClose={() => setOptInPopupIsShowing(false)}
      onSuccess={() => setOptInPopupIsShowing(false)}
    >
      <div>
        <Title>
          Welcome to Launch OS!
          <div className="text-center text-sm mt-2">Please enter your name & email to see the chat response.</div>
        </Title>

        <div className="flex flex-col gap-4 px-7">
          <TextInput
            label="Name"
            placeholder="Enter your name"
            value={name}
            flavor={TextInputFlavors.MODERN}
            onChange={(e) => setName(e.target.value)}
          />
          <TextInput
            label="Email"
            placeholder="Enter your email"
            value={email}
            flavor={TextInputFlavors.MODERN}
            onChange={(e) => setEmail(e.target.value)}
          />
          
        </div>
      </div>
    </Modal>
  );
};
