import { FullScreen, useFullScreenHandle } from "react-full-screen";
import {
  ArrowUUpLeft,
  ArrowUUpRight,
  Eye,
  FrameCorners,
  Sparkle,
} from "@phosphor-icons/react";
import { useEditorActions } from "./EditorCanvas";
import UndoRedoButtons from "../UndoRedoButtons";
import { DEFAULT_PAGE_ID } from "./types";
import { MobileState } from "../MobileResponsiveToggle/types";

export enum BrowserSize {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

export const BrowserWrapper = ({
  children,
  onClose = () => {},
  withUrl = true,
  withPreview = true,
  withUndoRedo = false,
  withFullScreen = true,
  withCustomButtons = false,
  customButtonIcons = [],
  url = "•••",
  mobileState = { type: MobileState.DESKTOP },
  size = BrowserSize.LARGE,
}: {
  withUrl?: boolean;
  withPreview?: boolean;
  withUndoRedo?: boolean;
  withFullScreen?: boolean;
  withCustomButtons?: boolean;
  customButtonIcons?: any;
  selected?: boolean;
  url?: string;
  children: React.ReactNode;
  mobileState: { type: MobileState };
  size: BrowserSize;
}) => {
  const { doUndoRedo } = useEditorActions();
  const handle = useFullScreenHandle();

  if (mobileState?.type === MobileState.FULLSCREEN) return children;
  if (mobileState?.type === MobileState.TABLET)
    return (
      <div className="flex h-full items-center justify-center">
        <div className="relative w-[768px] h-[1024px] mx-auto bg-gray-200 dark:bg-white/10 rounded-[30px] border-[14px] border-gray-300 dark:border-white/20 shadow-xl overflow-hidden">
          <div className="absolute top-0 left-1/2 transform -translate-x-1/2 w-20 h-5 bg-gray-300 dark:bg-white/20 rounded-b-lg"></div>
          <div className="w-full h-full bg-white dark:bg-white/5 overflow-auto">
            {children}
          </div>
          <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 w-12 h-12 rounded-full border-2 border-gray-400 dark:border-black/30"></div>
        </div>
      </div>
    );
  if (mobileState?.type === MobileState.SMARTPHONE)
    return (
      <div className="flex h-full items-center justify-center">
        <div className="relative w-[375px] h-[812px] mx-auto bg-gray-200 dark:bg-white/10 rounded-[50px] border-[14px] border-gray-300 dark:border-white/20 shadow-xl overflow-hidden">
          <div className="absolute top-0 left-1/2 transform -translate-x-1/2 w-40 h-6 bg-gray-300 dark:bg-white/20 rounded-b-3xl"></div>
          <div className="w-full h-full bg-white dark:bg-white/5 overflow-auto">
            {children}
          </div>
          <div className="absolute bottom-1 left-1/2 transform -translate-x-1/2 w-32 h-1 bg-gray-400 dark:bg-black/30 rounded-full"></div>
        </div>
      </div>
    );

  return (
    <FullScreen handle={handle} className="w-full h-full">
      <div
        className={`rounded-md overflow-hidden shadow-3xl h-full w-full border border-black/10 dark:border-white/20 relative`}
      >
        <div className={`bg-white dark:bg-white/10 border-b border-black/5 ${size === BrowserSize.SMALL ? "p-0" : size === BrowserSize.MEDIUM ? "p-1" : "p-3"}`}>
          <div className="">
            {/* Three circles to represent browser chrome buttons */}
            <div className={`flex absolute space-x-1 py-px ${size === BrowserSize.SMALL ? "scale-50" : size === BrowserSize.MEDIUM ? "scale-75" : "scale-100"}`}>
              <div
                onClick={onClose}
                className="w-3 h-3 rounded-full bg-red-500 cursor-pointer"
              ></div>
              <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
              <div className="w-3 h-3 rounded-full bg-green-500"></div>
              {withUndoRedo && (
                <UndoRedoButtons
                  doUndoRedo={doUndoRedo}
                  pageId={DEFAULT_PAGE_ID}
                />
              )}
            </div>
            {/* Show the url in the middle */}
            {withUrl && (
              <div className={`text-center text-xs text-gray-500 ${size === BrowserSize.SMALL ? "scale-50" : size === BrowserSize.MEDIUM ? "scale-75" : "scale-100"}`}>
                <a href={url} target="_blank">
                  <span className="p-1 rounded bg-black/5 px-7 rounded-lg hover:text-underline">
                    {url}
                  </span>
                </a>
              </div>
            )}

            {(withPreview || withFullScreen || withCustomButtons) && (
              <div className="absolute right-3 top-2 py-px gap-0 flex">
                {/* Show custom buttons */}
                {withCustomButtons &&
                  customButtonIcons &&
                  customButtonIcons.map(({ icon, onClick }) => (
                    <button
                      onClick={onClick}
                      className="px-3 py-1 rounded hover:bg-black/5 bg-transparent text-xs text-gray-500"
                    >
                      {icon}
                    </button>
                  ))}

                {/* {withCustomButtons && (
                <button
                  onClick={() => customButtonFunction()}
                  className="px-3 py-1 rounded hover:bg-black/5 bg-transparent text-xs text-gray-500"
                >
                  <Sparkle />
                </button>
              )} */}

                {/* Show a preview button to the right */}
                {withPreview && (
                  <a href={url} target="_blank">
                    <button className="px-3 py-1 rounded hover:bg-black/5 bg-transparent text-xs text-gray-500">
                      <Eye />
                    </button>
                  </a>
                )}

                {/* Show a fullscreen button to the right */}
                {withFullScreen && (
                  <button
                    onClick={handle.active ? handle.exit : handle.enter}
                    className="px-3 py-1 rounded hover:bg-black/5 bg-transparent text-xs text-gray-500"
                  >
                    <FrameCorners />
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="h-full w-full bg-white dark:bg-black overflow-y-auto">
          {children}
        </div>
      </div>
    </FullScreen>
  );
};
