import { AppContext } from "@/AppContext";
import { useContext } from "react";

interface EmptyStateProps {
  title: string;
  description: string;
  image?: string | React.ReactNode;
  showImage?: boolean;
  children?: React.ReactNode;
}

export const EmptyState = ({
  title,
  description,
  showImage = true,
  image,
  children,
}: EmptyStateProps) => {
  const { darkMode } = useContext(AppContext);

  
  const Img = () => {
    
    const imageList = [
      `/assets/images/coming-soon${darkMode && "-dark"}.svg`,
      `/assets/images/maintanence${darkMode && "-dark"}.svg`,
    ];

    let useImage =
      image || imageList[Math.floor(Math.random() * imageList.length)];

    if (image && typeof image !== "string") return image; // in case we send a component as the image prop (e.g. an icon)

    return <img src={String(useImage)} alt="" className="mb-8" />;
  }

  // choose randomly between the images in the imageList array
  return (
    <div className="flex items-center justify-center h-full">
      <div className="text-center">
        {showImage && <div className="flex justify-center"><Img /></div>}
        <h2 className="text-2xl font-bold mb-4">{title}</h2>
        <p className="text-gray-500 mb-8">{description}</p>
        {children}
      </div>
    </div>
  );
};
