import {
  filter,
  forEach,
  fromPairs,
  get,
  keys,
  map,
  omit
} from "lodash";
// import settings from "../../../../../../apps/livesite/src/config";
import settings from "../config";
import { getInputValue } from "./getInputValues";
import { addToCollection } from "./collectionManagement";

import { useStripe, useElements } from "@stripe/react-stripe-js";
import { hideButtonLoader, newHideButtonLoader, showButtonLoader } from "../../WebComponents/v2/Button/actions";
import { _postEvent, getTinyBirdData } from "@launchos/client-site-vite/src/plugins/Stats/events";

// import { _postEvent, getTinyBirdData } from "@launchos/plugins/misc/v1/stats/live/events";
// const _postEvent = () => {}
// const getTinyBirdData = () => {}

const performValidation = convertedData => {
  let proceed = true;
  let message = "";

  const validateEmail = email => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const keysOfBlankFields = filter(
    keys(convertedData),
    k => convertedData[k] === ""
  );

  console.log({ keysOfBlankFields, convertedData });

  forEach(keysOfBlankFields, name => {
    // console.log('keysOfBlankFields', { name })
    if (
      get(document.getElementsByName(name), "[0]", {
        getAttribute: () => "false"
      }).getAttribute("isrequired") !== "false"
    ) {
      proceed = false;
      message = `Sorry, but the ${name} field is required to proceed.`;
    }
  });

  if (get(convertedData, "email").length && !validateEmail(get(convertedData, "email"))) {
    proceed = false;
    message = "Sorry, you entered an invalid email address.  Please try again";
  }

  // console.log({ convertedData })

  return {
    proceed,
    message
  };
};

export default async (jsonData, callbackFn, action) => {

  // const getInputValue = (name, data) =>
  //   findIndex(data, itm => itm.name === name) > -1
  //     ? get(head(filter(data, itm => itm.name === name)), "value", false)
  //     : false;

  // const { dispatchData, loading, error } = useAcceptJs({ authData, environment: "PRODUCTION", });

  const data = JSON.parse(jsonData); //window.data = data;
  // console.log("\n\n\n", getInputValue("email", data), "\n\n\n")
  console.log("Inside Send Data", data);

  const isCardPresent = data.findIndex(itm => itm.name === "cardNumber") > -1 || action?.context?.cardIsPresent;
  const credentials = getInputValue("emP", data);
  const mCredentials = getInputValue("pmP", data);

  const toSend1 = fromPairs(map(data, i => [i.name, i.value]));
  const toSend = omit(toSend1, ["emP", "pmP", "nxP"]);

  const name = getInputValue("name", data) || "";
  // console.log('lastName', getInputValue('lastName', data));

  const convertedData = isCardPresent
    ? {
      mCredentials,
      shoppingCartItems: getInputValue("shoppingCartItems", data),
      purchaseType: getInputValue("purchaseType", data),
      firstName:
        getInputValue("firstName", data) || get(name.split(" "), "0", ""),
      // lastName:
      // getInputValue('lastName', data) || name.split(' ').length > 1 ? name.split(' ').slice(-1).join(' ') : '',
      lastName:
        getInputValue("lastName", data) ||
        (name.split(" ").length > 1
          ? name
            .split(" ")
            .slice(-1)
            .join(" ")
          : ""),
      billingFirstName:
        getInputValue("firstName", data) ||
        getInputValue("billingFirstName", data) ||
        get(name.split(" "), "0", ""),
      billingLastName:
        getInputValue("lastName", data) ||
        getInputValue("billingLastName", data) ||
        getInputValue("lastName", data) ||
        (name.split(" ").length > 1
          ? name
            .split(" ")
            .slice(-1)
            .join(" ")
          : ""),
      billingAddress1:
        getInputValue("address", data) ||
        getInputValue("billingAddress1", data),
      billingAddress2:
        getInputValue("address2", data) ||
        getInputValue("billingAddress2", data),
      billingCity:
        getInputValue("city", data) || getInputValue("billingCity", data),
      billingState:
        getInputValue("state", data) || getInputValue("billingState", data),
      billingZip:
        getInputValue("zip", data) || getInputValue("billingZip", data),
      billingCountry:
        getInputValue("country", data) ||
        getInputValue("billingCountry", data) ||
        "US",
      phone: getInputValue("phone", data),
      email: getInputValue("email", data),
      creditCardType:
        getInputValue("cardType", data) ||
        getInputValue("creditCardType", data),
      creditCardNumber:
        getInputValue("cardNumber", data) ||
        getInputValue("creditCardNumber", data),
      expirationDate:
        getInputValue("expirationDate", data) ||
        getInputValue("expirationMonth", data) +
        getInputValue("expirationYear", data), // TODO: FIX TO PARSE BETTER
      CVV: getInputValue("cvv", data) || getInputValue("CVV", data),
      shippingId: getInputValue("shippingMethod", data),
      billingModelId: getInputValue("billingModel", data) || 2,
      tranType: "Sale",
      ipAddress: "0.0.0.0", // TODO: dynamic
      // campaignId: JSON.parse(credentials).campaignId,
      // billingSameAsShipping: 'YES',
      shippingAddress1:
        getInputValue("address", data) ||
        getInputValue("shippingAddress1", data),
      shippingAddress2:
        getInputValue("address2", data) ||
        getInputValue("shippingAddress2", data),
      shippingCity:
        getInputValue("city", data) || getInputValue("shippingCity", data),
      shippingState:
        getInputValue("state", data) || getInputValue("shippingState", data),
      shippingZip:
        getInputValue("zip", data) || getInputValue("shippingZip", data),
      shippingCountry:
        getInputValue("country", data) ||
        getInputValue("shippingCountry", data) ||
        "US",
      amount: get(JSON.parse(mCredentials), "amount", 0),
      pageId: getInputValue("pageId", data),
      campaignId: getInputValue("campaignId", data),

      // productId: JSON.parse(mCredentials).productId,
    }
    : {
      ...toSend,
      email: getInputValue("email", data) || "",
      firstName:
        getInputValue("firstName", data) || get(name.split(" "), "0", ""),
      lastName:
        getInputValue("lastName", data) ||
        (name.split(" ").length > 1
          ? name
            .split(" ")
            .slice(-1)
            .join(" ")
          : ""),
      name: name.length ? name : `${(getInputValue("firstName", data) || "").trim()} ${(getInputValue("lastName", data) || "").trim()}`.trim(),
      address1: getInputValue("address", data) || "",
      pageId: getInputValue("pageId", data),
      campaignId: getInputValue("campaignId", data),
      credentials,
    };

  // console.log({ convertedData })

  const validationResult = performValidation(convertedData);

  // console.log({ validationResult });
  // return false;

  if (!validationResult.proceed) {
    alert(validationResult.message);
    callbackFn(false);
    return false;
  }

  const provider = (isCardPresent)
    ? JSON.parse(get(convertedData, "mCredentials", "{}")).provider
    : JSON.parse(get(convertedData, "credentials", "{}")).provider;

  // console.log({ provider })

  // process data
  const orderURL = settings.orderUrl[provider] && `${settings.serverUrl}${settings.orderUrl[provider]}`;
  const prospectURL = settings.prospectUrl[provider] && `${settings.serverUrl}${settings.prospectUrl[provider]}`;

  console.log({ provider, convertedData, orderURL, prospectURL })
  
  // push data to collection if requested (convertedData)
  if (convertedData.address1) delete convertedData.address;
  // console.log("Collection Data", { convertedData });
  const collectionsResponse = await addToCollection({ 
    pageId: get(convertedData, 'pageId'), 
    campaignId: get(convertedData, 'campaignId'), 
    id: "{userId}-crm" 
  }, omit(convertedData, ['amount']));

  const tinyBirdData = getTinyBirdData(convertedData);
  // console.log({ tinyBirdData });

  console.log("Collections Response", collectionsResponse);

  const url = (isCardPresent) ? orderURL : prospectURL;
  // alert(`url: ${url}`);

  if (!url) { // if there isn't an integration to send the data to, just move forward to next page
    // Track the lead
    if (tinyBirdData?.data) await _postEvent({ ...tinyBirdData.data, action: "lead", payload: JSON.stringify(omit(convertedData, ['credentials'])) });    
    callbackFn();
  } else { // if there is an integration to send the data to, do that here

    // else if (provider === "authorize") { // get payment token
    //   try {
    //     console.log({mCredentials})
    //     // get client id
    //     const res = await fetch(`${url}/getClientKey`, { method: "POST", headers: { "content-type": "application/json" }, body: JSON.stringify({ mCredentials })});
    //     const response = await res.json();

    //     const { clientKey } = response;

    //     console.log({ response });
    //   } catch (error) {
    //     console.error("Failed to get client key:", error);
    //     alert("Failed to get client key. Please try again.");
    //   }

    // }

    if (provider === "stripe") {
      // alert("In the stripe zone, now!");
      const { stripe = {}, elements = {} } = action?.context;
      const return_url = `http://localhost/upsell-test`;

      // try {
      //   // Create the payment method
      //   const { paymentMethod } = await stripe.createPaymentMethod({
      //     elements,
      //     // Optional billing details can be added here
      //     // params: {
      //     //   billing_details: {
      //     //     name: convertedData.firstName + ' ' + convertedData.lastName,
      //     //     email: convertedData.email,
      //     // }
      //   });

      //   if (!paymentMethod) {
      //     throw new Error('Failed to create payment method');
      //   }

      //   alert(`Created payment method: ${JSON.stringify(paymentMethod)}`);
        
      //   console.log('Created payment method:', paymentMethod);

      // } catch (error) {
      //   console.error('Error creating payment method:', error);
      //   alert('Failed to process payment. Please try again.');
      //   return false;
      // }

      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // return_url: `${action.settings.href}${get(action, "payload.forwardParams", false) ? location.search : ""}`
          return_url,
        },
        // redirect: "if_required",
      });

      // hideButtonLoader(action);
      // newHideButtonLoader(action);

      if (error?.type === "card_error" || error?.type === "validation_error") {
        alert(error.message);
      } else {
        alert("An unexpected error occured.");
        console.log({ error })
      }

      return false;
    } else {
      
      showButtonLoader(action);

      const jsonPromise = fetch(url, {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify(
          isCardPresent
            ? convertedData
            : { ...convertedData, collectionsResponse }
        ), // don't include collectionsResponse if submitting to an order form
      }).then((r) => r.json());
    
      jsonPromise.then(async data => {
        // console.log(data)
    
        if (isCardPresent) {
  
          const sanitizedConvertedData = omit(convertedData, [
            "creditCardType",
            "creditCardNumber",
            "expirationDate",
            "CVV",
            "mCredentials",
            "credentials",
          ]);
  
          localStorage.setItem(
            "orderResponse",
            JSON.stringify({
              ...sanitizedConvertedData,
              ...data
            })
          );
    
          // console.log({ isCardPresent })    
          if (data.error_found === "1") {
            alert(data.error_message);
            callbackFn(false);
          } else {
            // Track the sale
            const resp = await _postEvent({ ...tinyBirdData.data, action: "sale", payload: JSON.stringify(sanitizedConvertedData) });
            console.log('sale tracking response', resp);
  
            callbackFn();
          }
        } else {
          console.log({ isCardPresent }, { data });
    
          if (data.error_found === "1") {
            alert(data.decline_reason);
            callbackFn(false);
          } else {
            // Track the lead          
            // const resp = await _postEvent({ ...tinyBirdData.data, action: "lead", payload: JSON.stringify(omit(convertedData, ['credentials'])) });
            // console.log('lead tracking response', resp);
        
            callbackFn();
          }
        }
      }); // this works
    }
  }

};
