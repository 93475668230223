import React, { useContext } from "react";
import { get, uniq } from "lodash";

// import EditorObject from "@launchos/modules/editor/Builder/EditorObject";
import {
  RichEditor,
  PositionPadding,
  Alignment,
  PropertiesWindow,
} from "@/Apps/Pages/Properties";

// import {
//   generateInlineStyle,
//   generateGoogleFontList,
// } from "@launchos/modules/editor/Builder/Properties/RichEditor/utilities";

// import { checkForSmartCopyIntegration } from '@launchos/plugins/misc/v2/smartcopy/client/scripts'

import style from "./style";
import EditorObject from "@/Apps/Pages/EditorObject";
import {
  generateGoogleFontList,
  generateInlineStyle,
} from "@/Apps/Pages/Properties/RichEditor/utilities";
import { parseLiquidString } from "@/Apps/Pages/Page/LiquidRenderer";
import { RichEditorPosition } from "@/Apps/Pages/Properties/RichEditor";
import { EditorMode } from "@/Apps/Pages/Editor/types";
import { wrapWithAncestorBackgrounds } from "@/Apps/Pages/Properties/RichEditor/RichEditor";
import { PageContext } from "@/Apps/Pages/Page/PageContext";
import { handleGetAlternateCopyRequest } from "@/Apps/Pages/Dashboard";
import { ButtonTypes } from "@/ui/types";
import Button from "@/ui/Button";

const HeadlineContentEdit = (props) => {
  const [dragHandleIsShowing, setDragHandleIsShowing] = React.useState(true);
  const [usesSmartCopy, setUsesSmartCopy] = React.useState(false);

  const {
    settings,
    updateComponentSettings,
    pageContent,
    setIsEditing,
    setCanDrag,
    changeState,
    mode,
  } = props;

  const buttonList = [
    ["bold", "italic", "underline", "strikethrough"],
    ["lineheight"],
    ["quicklink"],
    ["removeformat"],
  ];

  const menuList = [
    ["undo", "redo"],
    usesSmartCopy ? ["smartcopy"] : [],
    ["bold", "italic", "underline", "strikethrough"],
    ["fontfamily", "googlefonts", "fontsizes", "align", "lineheight"],
    ["forecolor", "backcolor"],
    ["link", "selectall"],
  ];

  const padding = get(settings, "properties.padding", 0);
  // const display = get(settings, 'properties.display', "inline-block");

  // are we in the middle of a drag?  if so, make the editor read only
  // const isDragging = pageContent.filter((content) => content.type === "Cursor").length;
  // const isDragging = Boolean(props.isDragInProgress);

  // get list of fonts used
  const googleFontList = generateGoogleFontList(settings, pageContent);

  // const handleOnLoad = async () => {
  //   const usesIt = await checkForSmartCopyIntegration();
  //   setUsesSmartCopy(usesIt)
  // }

  // React.useEffect(() => {
  //   handleOnLoad()
  // }, [])

  return (
    <EditorObject
      {...props}
      // style={{ display }}
      PropertiesView={Properties}
      dragHandle={dragHandleIsShowing}
      noOffset={settings.html.length < 20}
    >
      <p style={{ padding }}>
        {/* <h1> produces some bad padding */}
        <span
          id={`ed_${settings.id}`}
          style={{
            ...style.content,
            ...settings.properties,
            marginTop: 0,
            padding: 0,
            display: "inline-block",
          }}
        >
          <RichEditor
            content={parseLiquidString(settings.html)}
            style={generateInlineStyle(settings)}
            buttonList={buttonList}
            menuList={menuList}
            googleFontList={googleFontList}
            onChange={(html, fontsList) => {
              try {
                const currentFontList = get(settings, "fontsUsed", []).map(
                  (arr) => get(arr, "fontFamily")
                );
                const newFontList = uniq([...currentFontList, ...fontsList]);
                const fontsUsed = newFontList.map((font) => ({
                  fontFamily: font,
                }));
                if (mode === EditorMode.EDITOR) {
                  if (html !== settings.html) {
                    console.log("Changed", html, settings.html);
                    updateComponentSettings(settings.id, {
                      ...settings,
                      html,
                      fontsUsed,
                    });
                  } else {
                    console.log("No change", html, settings.html);
                  }
                }
              } catch (e) {}
            }}
            onInstantChange={() => setDragHandleIsShowing(false)}
            onClick={() => {
              setDragHandleIsShowing(true);
            }}
            onMouseEnter={() => {
              setDragHandleIsShowing(true);
            }}
            onFocus={(e) => {
              try {
                // console.log("Testing!");
                setIsEditing(true);
                setCanDrag(false);
                changeState(settings.id, "active");
              } catch (e) {}
            }}
            onBlur={(e) => {
              try {
                setDragHandleIsShowing(true);
                setTimeout(() => {
                  setIsEditing(false);
                  setCanDrag(true);
                }, 200);
              } catch (e) {}
            }}
            disabled={mode === EditorMode.SIMPLE}
            // disabled={props.show === "preview" || isDragging}
            // disabled={props.show === "preview"}
            // disabled={isDragging}
            // disabled
            // placeholder="Enter Your Headline Here"
            settings={settings}
          />
        </span>
      </p>
    </EditorObject>
  );
};

export default (props) => {
  const { settings } = props;
  const marginTop = settings.properties ? settings.properties.marginTop : 0;
  const textAlign = settings.properties
    ? settings.properties.textAlign
    : "inherit";

  return (
    <div style={{ marginTop, textAlign }}>
      <HeadlineContentEdit {...props} />
    </div>
  );
};

const BasicPropertiesTab = (props) => (
  <>
    {/* <Typography {...props} showLineHeight isExpanded /> */}
    <PositionPadding {...props} isExpanded />
    <Alignment {...props} verticalAlign={false} textAlign />
  </>
);

export const SimpleHeadlineProperties = (props) => {

  const { completedItems, updateCompletedItems } = useContext(PageContext);
  const { settings, updateComponentSettings } = props;

  const buttonList = [
    ["bold", "italic", "underline", "strikethrough"],
    ["lineheight"],
    ["removeformat"],
  ];

  const menuList = [
    ["undo", "redo"],
    // usesSmartCopy ? ["smartcopy"] : [],
    ["fontfamily", "googlefonts", "fontsizes", "align", "lineheight"],
    ["forecolor", "backcolor"],
    ["link"],
  ];

  const handleChange = (html, fontsList) => {
    // if the current item is not in the completedItems array, add it
    const isComplete = completedItems.includes(settings.id);
    if (!isComplete) updateCompletedItems([...completedItems, settings.id]);

    const currentFontList = get(settings, "fontsUsed", []).map((arr) =>
      get(arr, "fontFamily")
    );
    const newFontList = uniq([...currentFontList, ...fontsList]);
    const fontsUsed = newFontList.map((font) => ({ fontFamily: font }));
    updateComponentSettings(settings.id, { ...settings, html, fontsUsed });
  };

  return (
    <div className="px-3">
      {/* <h3 className="text-sm font-medium">{settings?.prompt}</h3> */}
      <div className="rounded-lg my-5">
        {wrapWithAncestorBackgrounds(
          <RichEditor
            id={`ed_${settings.id}`}
            content={settings.html}
            style={generateInlineStyle(settings)}
            buttonList={buttonList as any[][]}
            menuList={menuList as any[][]}
            position={RichEditorPosition.TOP}
            onChange={handleChange}
          />,
          settings.id
        )}

        <div className="flex justify-end mt-3">
          <Button
            type={ButtonTypes.SOFT}
            label=" ✨ Improve with AI"
            onClick={async () => {
              await handleGetAlternateCopyRequest({
                settings,
                updateComponent: updateComponentSettings,
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export const Properties = (props) => (
  <PropertiesWindow
    {...props}
    defaultTab="basic"
    tabs={[{ id: "basic", title: "Headline", component: BasicPropertiesTab }]}
  />
);
