import * as React from "react";
import Stats from "./Stats";
import { ComponentRenderHooks } from "@/plugins/types";

export const onComponentRender = (hook, { pageId = "", userId = "", campaignId = "", objectId = "" }) => {
  if (hook.id === ComponentRenderHooks.PAGE_LOAD && pageId.length)
    return [<Stats key="stats" pageId={pageId} userId={userId} campaignId={campaignId} objectId={objectId} />];
};

export default Stats;
