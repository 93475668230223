import { TourProvider } from "@reactour/tour";
import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { get, has, isEqual, uniqWith } from "lodash";
import { getActiveTabFromLocalStorage } from "@/ui/TabsComponent/TabsComponent";

import { Page } from "@/ui/Layout";
import { Button } from "@/ui/Button";
import { TabsComponent } from "@/ui/TabsComponent";
import { ButtonTypes, Variants } from "@/ui/types";
import { AppContext } from "@/AppContext";

import { getCampaign } from "@/api/campaigns";
import CampaignBuilder from "./CampaignBuilder";

import { DashboardPage } from "@/Apps/Dashboards/DashboardPage";
import { Settings } from "@/Apps/Dashboards/Settings/Settings";
import { PublishCampaignModal } from "./Popups/PublishCampaignModal";

import { getSessionToken } from "@/api/auth";
import { Loading } from "@/ui/Layout/Loading";
import { ContactsGallery } from "../CRM/ContactsGallery";
import { getFilterObject } from "../CRM/scripts";
import { Sketchpad } from "../Sketchpad/Sketchpad";
import ListDropdown from "@/ui/ListDropdown";
import { DownCarrotIcon } from "@/ui/Icons";
import { FunnelVisionModal } from "./Popups/FunnelVisionModal";
import VisualBuilder from "./VisualBuilder";

import { triggerHook } from "@/plugins/client";
import { ComponentRenderHooks, HookTriggers } from "@/plugins/types";
import { setContextForView } from "../AITools/utils";
import { TopbarButton } from "@/ui/Layout/Topbar/Topbar";
import { MagicWand } from "@phosphor-icons/react";
import SimpleBuilder from "./SimpleBuilder";
import { ShareCampaignLink } from "@/Settings/CampaignSharing";

const tabs = [
  { label: "Dashboard", value: "overview" },
  { label: "Sketch Pad", value: "sketchpad" },
  { label: "Visual Funnel Builder", value: "builder" },
  // { label: "Visual Builder", value: "canvas" },
  // { label: "Smart Canvas", value: "canvas" },
  { label: "Contacts", value: "contacts" },
  // { label: "Media", value: "media" },
  // { label: "Insights", value: "insights" },
  { label: "Settings", value: "settings" },
];

export const FunnelDashboard = ({ name }) => {
  // Define state variables
  const [activeTab, setActiveTab] = useState(
    getActiveTabFromLocalStorage(tabs) || "overview"
  );
  const [isPublishPopupOpen, setIsPublishPopupOpen] = useState(false);
  const [isFunnelVisionPopupOpen, setIsFunnelVisionPopupOpen] = useState(false);
  const [isFunnelWizardPopupOpen, setIsFunnelWizardPopupOpen] = useState(false);
  const [isShareFunnelPopupOpen, setIsShareFunnelPopupOpen] = useState(false);

  const [campaignData, setCampaignData] = useState({});
  const [profileData, setProfileData] = useState({});
  const [isShowingStats, setIsShowingStats] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  // const [addNewComponents, setAddNewComponents] = useState([]);
  const [copilotSettings, setCopilotSettings] = useState({});

  const [isWidgetSidebarShowing, setIsWidgetSidebarShowing] = useState(false);
  const [addNewDrawerType, setAddNewDrawerType] = useState("OBJECTS");

  // Get the funnel ID from the URL
  const { campaignId } = useParams();
  const id = campaignId;

  const contextObj = useContext(AppContext);
  const {
    darkMode,
    permissions,
    copilotContext,
    setCopilotContext,
    setCurrentlyViewing,
    setCopilotOpen,
    currentlyViewing,
  } = contextObj;

  const loadEverything = async () => {
    const token = getSessionToken();
    const data = await getCampaign({ token, id });

    // setIsFunnelWizardPopupOpen(Boolean(data?.settings?.simple));
    setCampaignData(data);

    // Get the profile data
    // const plannerProfiles = campaignData?.settings?.plannerProfiles || [];
    let profileDataArray = [];

    // flatten the array
    const profileDataFlat = profileDataArray.reduce((acc, current) => {
      return { ...acc, ...current };
    }, {});

    setProfileData(profileDataFlat);

    setContextForView(
      {
        // prompt: funnelDashboardPrompt,
        data: {
          type: data.type,
          name: data.name,
          description: data.description,
          createdAt: data.createdAt,
          updatedAt: data.updatedAt,
        },
        view: `/funnels/${id}`,
      },
      { copilotContext, setCopilotContext }
    );

    const { objects } = data;
    setContextForView(
      {
        data: objects.map(
          ({ page, settings, __typename, width, height, ...rest }) => rest
        ),
        view: "Funnel Objects",
      },
      contextObj
    );

    setCurrentlyViewing(
      uniqWith([...currentlyViewing, { label: "Funnel Objects" }], isEqual)
    );

    // load chat window if withAI is true
    console.log("withAI check", data);
    const { settings } = data;
    if (settings?.withAI && settings?.chatSettings?.chatSessionId) {
      const { chatSettings } = settings;
      if (chatSettings?.chatSessionId) {
        setCopilotOpen(true);
        setTimeout(() => {
          setCopilotSettings({ id: chatSettings.chatSessionId });
        }, 2000);
      }
    }

    setIsLoading(false);
  };

  // Define the function to handle tab changes
  const handleTabChange = async (tab) => {
    setActiveTab(tab);
    // if (tab === "overview" || tab === "builder" || tab === "sketchpad") {
    setIsLoading(true);
    await loadEverything(); // reload the data when the tab changes
    // }
  };

  // Fetch the funnel data from the API when the component mounts
  useEffect(() => {
    loadEverything();

    // get hash from url and set active tab
    const hash = window.location.hash;
    if (hash) {
      const tab = hash.replace("#", "");
      setActiveTab(tab);
    }

    // load add new objects
    const objectsFromPlugins = triggerHook(HookTriggers.onListItems, {
      id: ComponentRenderHooks.WEBCOMPONENT,
      type: "drawer",
    });

    // setAddNewComponents(objectsFromPlugins);

    console.log({ objectsFromPlugins });
  }, []);

  let withPadding = true;
  if (activeTab === "canvas") withPadding = false;

  // Render the component
  return (
    <Page
      topBar={{
        page: [
          { label: name, href: "/funnels" },
          {
            label: get(campaignData, "name", "•••"),
            href: `/funnels/${id}`,
            context: {
              campaignId: id,
              profileData,
            },
          },
        ],
        leftItems: (
          <>
            {activeTab === "canvas" && (
              <div className="mx-3 flex gap-2">
                <Button
                  label="+ Elements"
                  type={ButtonTypes.OUTLINED}
                  onClick={() => {
                    setIsWidgetSidebarShowing(!isWidgetSidebarShowing);
                    setAddNewDrawerType("OBJECTS");
                  }}
                />
                <Button
                  label="+ Sections"
                  type={ButtonTypes.OUTLINED}
                  onClick={() => {
                    setIsWidgetSidebarShowing(!isWidgetSidebarShowing);
                    setAddNewDrawerType("SECTIONS");
                  }}
                />
              </div>
            )}
          </>
        ),
        items: (
          <>
            {activeTab === "canvas" && (
              <>
                {/* <UndoRedoButtons
                  doUndoRedo={() => {}}
                  pageId={DEFAULT_PAGE_ID}
                /> */}
                {/* <MobileResponsiveToggle /> */}
              </>
            )}
            {activeTab === "builder" && (
              <>
                <Button
                  label={isShowingStats ? "Hide Stats" : "Show Stats"}
                  onClick={() => setIsShowingStats(!isShowingStats)}
                  type={ButtonTypes.SOFT}
                  className="bg-transparent"
                  // variant={Variants.SECONDARY}
                />
                {/* <Button
                  onClick={() => {
                    const proceed = window.confirm(
                      "This will generate new content for this campaign.  Are you sure you want to proceed?"
                    );
                    if (proceed) handleCampaignGeneration();
                  }}
                  label="Regenerate Funnel"
                  variant={Variants.INFO}
                /> */}
              </>
            )}
            <ListDropdown
              element={
                <Button
                  label={
                    <div className="flex gap-1">
                      <span className="">Actions</span>
                      <DownCarrotIcon className="pt-1" />
                    </div>
                  }
                  type={ButtonTypes.OUTLINED}
                  variant={Variants.INFO}
                />
              }
              items={[
                {
                  id: "view",
                  label: "Publish this Funnel",
                  onClick: () => setIsPublishPopupOpen(true),
                },
                { id: "divider", type: "divider" },
                {
                  id: "view",
                  label: "Upload Funnel Sketch",
                  onClick: () => setIsFunnelVisionPopupOpen(true),
                },
                {
                  id: "view",
                  label: "Share this Funnel",
                  onClick: () => setIsShareFunnelPopupOpen(true),
                },
              ]}
            />
            <Button
              label="Publish Funnel"
              onClick={() => setIsPublishPopupOpen(true)}
              type={ButtonTypes.OUTLINED}
              variant={Variants.INFO}
            />
            {/* {(Boolean(campaignData?.settings?.simple) ||
              Boolean(permissions.includes("admin"))) && (
              <TopbarButton onClick={() => setIsFunnelWizardPopupOpen(true)}>
                <MagicWand />
              </TopbarButton>
            )} */}
          </>
        ),
      }}
      copilotSettings={copilotSettings}
    >
      {/* Render the publish campaign modal if it's set to open */}
      {isPublishPopupOpen && (
        <PublishCampaignModal
          setIsOpen={setIsPublishPopupOpen}
          campaignId={id}
        />
      )}

      {/* Render the funnel vision modal if it's set to open */}
      {isFunnelVisionPopupOpen && (
        <FunnelVisionModal
          setActiveTab={setActiveTab}
          setIsOpen={setIsFunnelVisionPopupOpen}
          campaignData={campaignData}
        />
      )}

      {isFunnelWizardPopupOpen && (
        <SimpleBuilder
          title="Funnel Builder"
          campaignData={campaignData}
          setCampaignData={setCampaignData}
          onClose={() => setIsFunnelWizardPopupOpen(false)}
        />
      )}

      {isShareFunnelPopupOpen && (
        <ShareCampaignLink
          setIsOpen={setIsShareFunnelPopupOpen}
          campaignId={id}
          unitName="Funnel"
        />
      )}

      {/* Render the dashboard tabs */}
      <div
        className={`w-full h-full ${withPadding && "p-6"}`}
        style={{
          ...(darkMode &&
          (activeTab === "builder" ||
            activeTab === "sketchpad" ||
            activeTab === "canvas")
            ? { backgroundColor: "rgb(22 22 22 / 0.8)" }
            : {}),
          ...(!darkMode &&
          (activeTab === "builder" ||
            activeTab === "sketchpad" ||
            activeTab === "canvas")
            ? { backgroundColor: "rgb(28 28 28 / 0.04)" }
            : {}),
        }}
      >
        <div className="tabs flex flex-col h-full">
          <div
            className={
              !withPadding
                ? `absolute z-10 pt-6 ${
                    isWidgetSidebarShowing && "left-[425px]"
                  } dark:bg-transparent backdrop-blur w-full`
                : undefined
            }
          >
            <TabsComponent
              tabs={tabs}
              activeTab={activeTab}
              setActiveTab={handleTabChange}
              actionSection={false}
            />
          </div>

          {/* Render the appropriate tab content based on the active tab */}
          {!has(campaignData, "id") || isLoading ? (
            <div className="p-96">
              <Loading type="gallery" />
            </div>
          ) : (
            <>
              {activeTab === "overview" && (
                <DashboardPage
                  campaignData={campaignData}
                  widgets={[
                    "kpi",
                    ...(permissions.includes("insights")
                      ? [
                          {
                            type: "columns",
                            settings: { widgets: ["pages", "tracking"] },
                          },
                        ]
                      : ["pages"]),
                  ]}
                />
              )}

              {activeTab === "builder" && (
                <div className="h-[calc(100vh-174px)]">
                  <TourProvider
                    steps={[
                      {
                        selector: ".first-step",
                        content: "This is my first Step",
                      },
                      // ...
                    ]}
                  >
                    <CampaignBuilder
                      id={id}
                      campaignData={campaignData}
                      showStats={isShowingStats}
                      // type="artboard"
                    />
                  </TourProvider>
                </div>
              )}

              {activeTab === "settings" && (
                <Settings
                  campaignData={campaignData}
                  setCampaignData={setCampaignData}
                />
              )}

              {activeTab === "sketchpad" && (
                <div className="h-full">
                  <Sketchpad
                    campaignData={campaignData}
                    setCampaignData={setCampaignData}
                  />
                </div>
              )}

              {activeTab === "canvas" && (
                <VisualBuilder
                  id={id}
                  campaignData={campaignData}
                  addNewDrawerType={addNewDrawerType}
                  isWidgetSidebarShowing={isWidgetSidebarShowing}
                />
              )}

              {activeTab === "contacts" && (
                <ContactsGallery
                  onCreate={() => {}}
                  defaultFilter={getFilterObject({
                    field: "hiddenTags",
                    operator: "contains",
                    value: id,
                  })}
                />
              )}

              {activeTab === "media" && <div>Media</div>}

              {activeTab === "insights" && <div>Insights</div>}
            </>
          )}
        </div>
      </div>
    </Page>
  );
};
