import React from "react";
import { Dot } from "@phosphor-icons/react";
import { orderBy } from "lodash";
import { NavGroup } from "./NavGroup";
import { NavSection } from "./NavSection";
import { AppSectionNavGroupsProps } from "./types";
import { useMatches, useNavigate } from "react-router-dom";

export const AppSectionNavGroups: React.FC<AppSectionNavGroupsProps> = ({
  doToggle = () => null,
  openNavGroups = {},
  menuItems = [],
}) => {
  const path = window.location.pathname;
  const navigation = useNavigate();

  return (
    <div data-testid="AppSectionNavGroups">
      {/* Render the "Apps" section */}
      <NavSection>Apps</NavSection>

      {/* Render the menu items */}
      {Boolean(menuItems) &&
        // Order the menu items based on order key
        orderBy(menuItems, ["order"], ["asc"]).map(
          ({ href, icon = null, label, children = [] }, key) => {
            return (
              <NavGroup
                key={key}
                icon={icon}
                href={href}
                name={label} //href.split("/")[1] + "=" + path.split("/")[1]}
                // isOpen={openNavGroups?.[label]}
                isOpen={
                  Boolean(children.length) &&
                  href.split("/")[1] === path.split("/")[1]
                }
                onClick={() => window.location.href = href}
                items={
                  Boolean(children) && Boolean(children.length)
                    ? children.map(({ href, label, icon = <Dot /> }, key) => {
                        return {
                          href,
                          label,
                          icon,
                          isActive: path === href,
                        };
                      })
                    : []
                }
              />
            );
          }
        )}
    </div>
  );
};
