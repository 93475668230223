import HTML5Backend from "react-dnd-html5-backend";

import PureCanvas from "../../Pages/V1Editor/PureCanvas";
import { AI_DOMAIN } from "@launchos/shared-utils/env";
import { ISettings } from "../../Pages/Editor/types";
import { MobileState } from "@/Apps/Pages/MobileResponsiveToggle/types";
import { recursivelyRetrieveItem } from "@/Apps/Pages/V2Editor/actions";
import {
  handleAskAIComponentRequest,
  handleGetAlternateCopyRequest,
} from "@/Apps/Pages/Dashboard";

import { DndProvider } from "react-dnd";

import { BrowserWrapper } from "@/Apps/Pages/Editor/BrowserWrapper";
import { ButtonTypes } from "@/ui/types";
import { Loading } from "@/ui/Layout/Loading";
import { updateComponentSettings } from "@/Apps/Pages/V2Editor/actions";
import { debounce, keys } from "lodash";
import { useCallback } from "react";
import { BODY_ID } from "@/Apps/Pages/Editor/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { useEffect, useState } from "react";
import Button from "@/ui/Button";
import { BuilderArtifactProps } from "./types";
import { getSessionToken } from "@/api/auth";
import { createPage, updatePage } from "@/api/pages";
import { createObject, getCampaign } from "@/api/campaigns";
import slugify from "slugify";
import { useNavigate } from "react-router-dom";
import { experimental_useObject, useChat } from "ai/react";
import { convertPagetoHTML } from "@/Apps/Pages/utils";
import { object, z } from "zod";

import {
  headlineSchema,
  textSchema,
  buttonSchema,
  pictureSchema,
  videoSchema,
  dividerSchema,
  formFieldSchema,
  progressSchema,
  spacerSchema,
  listItemSchema,
  countdownSchema,
  navigationSchema,
  sectionSchema,
  containerSchema,
  columnSchema,
  columnsSchema,
} from "@/Apps/Pages/schema";
import { cleanThisContent } from "@/Apps/Pages/Editor/FullEditor";

const bodyObj = {
  parent: false,
  state: "normal",
  id: BODY_ID, // AI might be making this a string?
  properties: {
    backgroundColor: "#FFF",
    backgroundImage: "none",
    height: "100%",
    backgroundAttachment: "scroll",
    backgroundSize: "contain",
    backgroundPosition: "center center",
    backgroundRepeat: "repeat-y",
    mobile: {
      desktop: {
        backgroundColor: "#FFF",
        backgroundImage: "none",
        backgroundAttachment: "scroll",
        backgroundSize: "contain",
        backgroundPosition: "center center",
        backgroundRepeat: "repeat-y",
      },
    },
  },
  type: ComponentTypes.BODY,
  canHaveChildren: true,
};

export const addBodyIfNone = (content: any[]): any[] => {
  if (content.findIndex((itm: any) => itm?.id === BODY_ID) === -1) {
    console.log("No Body Found...Adding");
    return [bodyObj, ...content];
  } else {
    return content;
  }
};

export const PageBuilderArtifact: React.FC<BuilderArtifactProps> = ({
  id = "",
  chatId = "",
  title = "",
  prompt = "",
  isLoading = false,
  setTopBarButtons = () => [],
  setArtifactOptions = () => {
    isShowing: false;
  },
  artifactExists = () => false,
  append = () => {},
}) => {
  const [content, setContent] = useState([]);
  const [activeSection, setActiveSection] = useState([]);
  const [html, setHTML] = useState("");
  const navigation = useNavigate();

  const artifactChat = experimental_useObject({
    // api: `${AI_DOMAIN}/api/copilot/artifacts/page/html`,
    api: `${AI_DOMAIN}/api/copilot/artifacts/page/section`,
    // schema: z.array(z.object({})),
    schema: z.array(
      z.union([
        z.object({ id: z.string() }),
        headlineSchema,
        textSchema,
        buttonSchema,
        pictureSchema,
        videoSchema,
        dividerSchema,
        formFieldSchema,
        progressSchema,
        spacerSchema,
        listItemSchema,
        countdownSchema,
        navigationSchema,
        sectionSchema,
        containerSchema,
        columnSchema,
        columnsSchema,
      ])
    ),
    // schema: z.object({}),
    onFinish({ object, error }) {
      // typed object, undefined if schema validation fails:
      console.log("Object generation completed:", object);

      // error, undefined if schema validation succeeds:
      console.log("Schema validation error:", error);
    },
    onError(error) {
      // error during fetch request:
      console.error("An error occurred:", error);
    },
  });

  const generateArtifact = async () => {
    const generatePageSpec = async (prompt) => {
      // get theme id from chat campaign settings
      const chatSettings = await getCampaign({
        token: getSessionToken(),
        id: chatId,
      });
      const themeId = chatSettings?.settings?.themeId;
      console.log({ themeId });

      const r = await fetch(`${AI_DOMAIN}/api/copilot/artifacts/page`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          themeId,
          messages: [{ role: "user", content: prompt }],
        }),
      });

      const response = await r.json();
      return response;
      // return null;
    };

    // const renderHtmlPageFromSpec = (spec) => {
    //   const { page_choices, prompt, section_choices, sections } = spec;
    //   // sections.forEach(async (section, index) => {
    //   const section = section_choices?.['Header']?.[0] || section_choices?.['header']?.[0] || {};

    //   console.log("section", section);

    //   const pageContent = page_choices[section?.pageId];
    //   const sectionContent = recursivelyRetrieveItem(pageContent, section?.id) || [];

    //   console.log("sectionContent", sectionContent);

    //   const sectionHTML = convertPagetoHTML(addBodyIfNone(sectionContent));

    //   console.log("sectionHTML", sectionHTML);

    //   artifactChat.append({ role: "user", content: `${prompt}\n\nUse the following HTML for inspiration:\n\n${sectionHTML}` });
    //   // artifactChat.append({ role: "user", content: `${prompt}\n\nHere is additional context for each of the sections that will make up the page ${JSON.stringify(sections)}` })
    //   // });

    // };

    const generatePageSections = (spec) => {
      const { page_choices, section_choices, sections } = spec;
      // loop through sections
      let renderedPageSections = [];

      sections.forEach((section, index) => {
        // randomly choose from one of the section_choices
        console.log({ section });
        const choices =
          section_choices[
            Object.keys(section_choices).find(
              (key) =>
                key.toUpperCase().replace(/\s+/g, "") ===
                section?.type?.toUpperCase().replace(/\s+/g, "")
            )
          ] || [];
        const randomChoice =
          choices[Math.floor(Math.random() * choices.length)];

        // retrieve the page content from the choice
        const pageContent = page_choices[randomChoice?.pageId];

        // retrieve the section content from the page for the choice
        if (randomChoice && pageContent) {
          try {
            const sectionContent =
              recursivelyRetrieveItem(pageContent, randomChoice?.id) || [];

            // add context info to section settings
            const component = {
              ...sectionContent.find((itm) => itm.id === randomChoice?.id),
              ai: { prompt: section?.prompt, content: section?.content },
            };
            const sectionContentWithContext = updateComponentSettings(
              sectionContent,
              randomChoice?.id,
              component
            );

            // (optional) sanitize the page (no text, placeholder images)
            // console.log("New Section For Rendering", {
            //   index,
            //   randomChoice,
            //   sectionContentWithContext,
            // });

            // append the page with that choice (but don't proceed if duplicate <- it should proceed, because it's sanitized)
            // if (!renderedPageSections.some(section => JSON.stringify(section) === JSON.stringify(sectionContentWithContext))) {
            renderedPageSections.push(sectionContentWithContext);
            // }
          } catch (err) {
            console.log(
              "error retrieving",
              randomChoice,
              "from",
              pageContent,
              err
            );
          }
        } else {
          console.log("nothing found", randomChoice, choices, section);
        }
      });

      return renderedPageSections;
    };

    const existingObject = await artifactExists(id);

    if (existingObject) {
      // load the page content
      const pageData = existingObject?.page;
      console.log("I found a page", existingObject);
      const { content } = pageData;
      setContent(content);

      // content.forEach((item, index) => {
      //   setTimeout(() => {
      //     setContent((prevContent) => [...prevContent, item]);
      //   }, index * 200); // 300ms delay between each item (move this to the generative section, not this section)
      // });
    } else {
      // if (Boolean(prompt.length)) {
      //   // generate a spec for the page
      //   const response = await generatePageSpec(prompt);
      //   console.log("RESPONSE", response);

      //   renderHtmlPageFromSpec(response);
      // }

      if (Boolean(prompt.length)) {
        // console.log("ARTIFACT CONTAINER: Ready to prompt", prompt);
        const response = await generatePageSpec(prompt);
        console.log("RESPONSE", response);

        const pageSections = generatePageSections(response);
        console.log("Final Page to Render", pageSections);

        for (let i = 0; i < pageSections.slice(0, 1).length; i++) {
          const section = pageSections[i];

          // call a new llm, using this section
//           const xsectionPrompt = `
// <user_request>
// ${response?.sections[i]?.prompt}
// </user_request>

// For the content, use the following for general talking points: 
// <talking_points>
// ${response?.sections[i]?.content}
// </talking_points>

// This section is part of a landing page build. 

// The overall page prompt is: ${response?.prompt}

// Design direction: ${response?.design}

// This is section ${i + 1} of ${pageSections.length} total sections. Please ensure this section's content and style aligns with both the overall page goals to maintain visual consistency with other sections.

// Now here is the original object for the section and the html that it corresponds to.

// <the_html>
// ${convertPagetoHTML(addBodyIfNone(section))}
// </the_html>

// <the_corresponding_objects>
// ${JSON.stringify(section)}
// </the_corresponding_objects>

// `;

          const sectionPrompt = `
You are tasked with creating content for a section of a landing page. Your goal is to generate engaging, relevant content that aligns with the overall page goals and maintains visual consistency with other sections. Follow these instructions carefully:

1. First, understand the context of the entire page:
<page_prompt>
${response?.prompt}
</page_prompt>

2. Keep in mind the following design directions:
<design_directions>
${response?.design}
</design_directions>

3. This is section ${i + 1} out of ${pageSections.length} total sections. Ensure that your content aligns with both the overall page goals and maintains visual consistency with other sections.

4. The specific user request for this section is:
<user_request>
${response?.sections[i]?.prompt}
</user_request>

5. Use the following talking points as a guide for the content:
<talking_points>
${response?.sections[i]?.content}
</talking_points>

6. Here is the HTML structure for this section:
<html_structure>
${convertPagetoHTML(addBodyIfNone(section))}
</html_structure>

7. And here is the corresponding section objects:
<section_object>
${JSON.stringify(section)}
</section_object>

8. Create content that fulfills the user request, incorporates the talking points, and fits within the given HTML structure. Ensure that the content is engaging, informative, and relevant to the overall page goals.

9. Maintain consistency with the design directions and the overall page prompt. Your content should seamlessly integrate with the other sections of the landing page.

10. Output your content in a format that matches the structure of the section object. Replace the placeholder content with your newly generated content, keeping the same keys and structure.

11. Provide your final output as a JSON array of objects. Do not include any explanations or additional text outside of these tags.

Remember to focus on creating high-quality, relevant content that enhances the landing page's effectiveness and user experience.`;

          console.log("sectionPrompt", sectionPrompt);

          // by making this a chat, it should, in theory, consider the rest of the page
          // for context.  in other words, we're making it have an entire conversation
          // with itself.
          await artifactChat?.submit({ prompt: sectionPrompt });

          // get html for section
          // call llm using that section (just like test)'s html and system prompt
          // asking it to analyze, modify & return just section changes
          // make it stream those changes back in object chunks
          // update the content after each change <- use a react.useEffect hook that listens for changes in artifactChat.messages & performs updates accordingly
          // ^^ in theory, this should render the sections with copy, one at a time.
          // ...and we can evolve that to be a multi-pass agentic llm
          // ...which will work really great when the sections it's using begin as wireframes

          setActiveSection((prevSection) => {
            const newContent = [...prevSection, ...section];
            return addBodyIfNone(newContent);
          });

          // works, but doesn't call alternative copy afterwards <- because alternate copy is called when page is fully loaded, and the timer tricks it into thinking that it is
          // section.forEach((item, index) => {
          //   setTimeout(() => {
          //     setContent((prevContent) => {
          //       const newContent = [...prevContent, item];
          //       return addBodyIfNone(newContent);
          //     });
          //   }, index * 200);
          // });
        }

        // console.log("ARTIFACT CONTAINER: Ready to prompt", prompt);
        // not sure what the following does... i think maybe it's to update the original settings with the correct prompt data?
        // append({
        //   role: "user",
        //   content: prompt,
        // });
      }
    }
  };

  const handleEditorHasLoaded = async () => {
    const existingObject = await artifactExists(id);
    if (!existingObject) {
      // Now update the content
      // const sections = content.filter(
      //   (itm) => itm.type === ComponentTypes.SECTION
      // );
      // for (const section of sections) {
      //   const sectionContent = recursivelyRetrieveItem(content, section?.id);
      //   await generateAISectionContent(sectionContent);
      // }
    }

    setTopBarButtons([
      <Button
        type={ButtonTypes.OUTLINED}
        label="Edit in Launch Pages"
        onClick={handleSmartPageOpen}
        // className="p-1 -mt-1 dark:bg-white/20 bg-black/20"
      />,
    ]);
  };

  const generateAISectionContent = async (sectionContent) => {
    const section = sectionContent.find(
      ({ type }) => type === ComponentTypes.SECTION
    );
    const system = section?.ai?.prompt;

    const textObjects = sectionContent.filter(
      (settings: ISettings) =>
        settings?.type === ComponentTypes.TEXT ||
        settings?.type === ComponentTypes.PARAGRAPH ||
        settings?.type === ComponentTypes.HEADLINE ||
        settings?.type === ComponentTypes.V1BUTTON
    );

    console.log(
      "About to generate AI content for items in",
      textObjects,
      system
    );

    // loop through them (async loop)
    for (const itm of textObjects) {
      // use AI to generate alternative content for it
      const textPrompt = `You are a worldclass, direct response copywriter.  You are writing copy that is part of a ${section?.label} section on a web page.  
      
      <task>
      Please create compelling copy for this ${itm.type}, but keep in mind that you're writing copy to achieve the following objective for this section:
      
      <objective>
      ${system}.
      </objective>
      
      </task>
      
      <page_details>
      For even more context, the here is the general theme and idea of the page the copy you're writing is a part of:
      ${prompt}
      </page_details>
      `;

      await handleGetAlternateCopyRequest({
        settings: itm,
        flavor: "create",
        prompt: textPrompt,
        updateComponent: updateEditorComponent,
      });

      console.log(
        "generateAISectionContent",
        "Creating content for",
        itm,
        "around the following prompt",
        prompt
      );
    }
  };

  const updateEditorComponent = (componentId, newComponent) => {
    setContent((prevContent) => {
      const changedContent = updateComponentSettings(
        prevContent,
        componentId,
        newComponent
      );
      console.log("updateEditorComponent", {
        componentId,
        newComponent,
        content,
        changedContent,
      });
      return changedContent;
    });
  };

  const cancelFtn = (): void => {};

  const handleSave = async ({ content, id, title }) => {
    if (id && chatId) {
      // does an object exist in the campaign for this artifact?
      const token = getSessionToken();
      let pageData = {};
      const pageType = "blank";

      const existingObject = await artifactExists(id);
      if (existingObject) {
        // if so, just update the page
        pageData = existingObject?.page;
        console.log(
          "ARTIFACT CONTAINER: (found) About to save!",
          pageData,
          content
        );
        // update the page content
        try {
          await updatePage(pageData?.id, {
            token,
            settings: { content },
          });
        } catch (error) {
          console.error("Failed to save page:", error);
          // Implement user feedback for save failure
        }
      } else {
        // if not, create one
        console.log({ id, title });
        const object = await createObject({
          name: title,
          x: 0,
          y: 0,
          campaignId: chatId,
          type: "PageComponent",
          screenshot: "",
          settings: { artifactId: id },
        });
        const objectId = object?.data?.createObject?.id;
        console.log("ARTIFACT CONTAINER: Object Created", objectId, object);
        const slug = slugify(title, { lower: true, trim: true });
        const pgObj = {
          token,
          settings: {
            name: title,
            content, //[bodyObj, ...content],
            slug,
            // campaignId: chatId,
            objectId,
            theme: pageType,
            type: pageType,
          },
        };
        console.log("ARTIFACT CONTAINER: Page Data (to save)", pgObj);
        pageData = await createPage(pgObj);
        console.log("ARTIFACT CONTAINER: Page Created", pageData);
      }
    }
  };

  // Debounce handleSave to trigger no more than once every three seconds
  const debouncedHandleSave = useCallback(
    debounce(({ content, id, title }) => {
      console.log("ARTIFACT CONTAINER: will save", { id, title });
      handleSave({ content, id, title });
    }, 3000),
    []
  );

  const handleChange = ({ content }) => {
    const cleanedContent = content;
    debouncedHandleSave({ content: cleanedContent, id, title });
  };

  const handleSmartPageOpen = async () => {
    const object = await artifactExists(id);
    navigation(`/funnels/${chatId}/${object?.id}`);
  };

  useEffect(() => {
    if (id && chatId) {
      setContent([]);
      console.log("ARTIFACT CONTAINER: Generating Artifact Content", id);
      generateArtifact();
    }
  }, [prompt, id]);

  useEffect(() => {
    console.log("artifactChat?.object", artifactChat?.object);
    
    // const result = artifactChat?.object?.objects;
    const result = artifactChat?.object;
    // console.log("artifactChat?.object?.result", result);
    if (result && Array.isArray(result) && result.length > 2) {
      console.log("artifactChat response", result);
     
      const forChange = result.filter((itm) => keys(itm).length !== 1);
      const newContent = activeSection.map((itm) => {
        // Find matching item from forChange array
        const changeItem = forChange.find((change) => change.id === itm.id);
        // If match found, merge with changeItem values overwriting
        if (changeItem) {
          return { ...itm, ...changeItem };
        }
        // If no match, return original item unchanged
        return itm;
      });

      setContent(cleanThisContent(newContent));
      // setContent(prevContent => {
        
      //   const forChange = result.filter(itm => keys(itm).length !== 1);
      //   return activeSection.map(itm => {
      //     // Find matching item from forChange array
      //     const changeItem = forChange.find(change => change.id === itm.id);
      //     // If match found, merge with changeItem values overwriting
      //     if (changeItem) {
      //       return { ...itm, ...changeItem };
      //     }
      //     // If no match, return original item unchanged
      //     return itm;
      //   })




      //   // const resultIds = result.map((itm) => itm.id);
      //   // return activeSection
      //   //   .filter((itm) => resultIds.includes(itm.id))
      //   //   .map((itm) => {
      //   //     // Find matching item from forChange array
      //   //     const changeItem = forChange.find((change) => change.id === itm.id);
      //   //     // If match found, merge with changeItem values overwriting
      //   //     if (changeItem) {
      //   //       return { ...itm, ...changeItem };
      //   //     }
      //   //     // If no match, return original item unchanged
      //   //     return itm;
      //   //   });

      // });

      // setContent((prevContent) => {
      //   const mergedObj = result.map(itm => {
      //     if (Object.keys(itm).length === 1 && itm.id) {
      //       // If item only has an id, find matching item from testContent
      //       return activeSection.find(prevItem => prevItem.id === itm.id) || itm;
      //     }
      //     return itm;
      //   });

      //   console.log("artifactChat new content", mergedObj);
      //   // const newContent = [...prevContent, ...section];
      //   return addBodyIfNone(mergedObj);
      // });
    }

  }, [artifactChat?.object]);

  // const htmlContent = artifactChat?.messages?.slice(1);

  // return (
  //   <div className="h-full w-full max-w-6xl overflow-y-auto">
  //      {!Boolean(htmlContent?.length) ? (
  //         <div className="p-9">
  //           <Loading type="gallery" />
  //         </div>
  //       ) : (
  //         <>
  //           {htmlContent.map((itm, index) => (
  //             <div key={index} dangerouslySetInnerHTML={{ __html: itm?.content }} />
  //           ))}
  //         </>
  //       )}
  //   </div>
  // );

  return (
    <div className="h-full w-full max-w-6xl overflow-y-auto">
      <DndProvider backend={HTML5Backend}>
        {!Boolean(content.length) ? (
          <div className="p-9">
            <Loading type="gallery" />
          </div>
        ) : (
          <PureCanvas
            content={content}
            onChange={() => {}}
            // onChange={handleChange}
            onItemSelect={cancelFtn}
            onSave={() => {}}
            // onSave={handleSave}
            disableDefaultProperties
            setActiveAddNewTab={cancelFtn}
            mobileState={{ type: MobileState.FULLSCREEN }}
            setMobileState={cancelFtn}
            onLoad={handleEditorHasLoaded}
          />
        )}
      </DndProvider>
    </div>
  );
};
