import { complete } from "@/api/ai";
import { getSessionToken } from "@/api/auth";
import { createCampaignAndObject } from "@/api/campaigns";
import { AppContext } from "@/AppContext";
import { ChatBot } from "@/Apps/AITools";
import { ContextChips } from "@/Apps/AITools/ContextChips";
import { Tag } from "@/Apps/Launchpad/components/Tag";
import { triggerHook } from "@/plugins/client";
import {
  ComponentRenderHooks,
  HookTriggers,
  ListItemsHooks,
} from "@/plugins/types";
import Chip from "@/ui/Chip";
import { CloseButtonIcon } from "@/ui/Icons";
import { TopbarBreadcrumbItem, TopbarProps } from "@/ui/Layout/Topbar/types";
import { Variants } from "@/ui/types";
import { Sparkle, X } from "@phosphor-icons/react";
import {
  KBarProvider,
  KBarPortal,
  KBarPositioner,
  KBarAnimator,
  KBarSearch,
  KBarResults,
  useMatches,
  useKBar,
  createAction,
  useRegisterActions,
} from "kbar";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const actions = [
  // {
  //   id: "funnel",
  //   name: "Funnels",
  //   shortcut: ["f"],
  //   keywords: "writing words",
  //   perform: () => (window.location.pathname = "funnels"),
  // },
  // {
  //   id: "contact",
  //   name: "Contact",
  //   shortcut: ["c"],
  //   keywords: "email",
  //   perform: () => (window.location.pathname = "contact"),
  // },
];

const RenderResults = ({ searchQuery }) => {
  const { results } = useMatches();
  const { query } = useKBar();

  // const askAIAction = {
  //   id: "askAI",
  //   name: `Ask AI: "${results.query}"`,
  //   keywords: "ai assistant help",
  //   perform: () => {
  //     // Implement your AI query function here
  //     console.log(`Asking AI: ${results.query}`);
  //   },
  // };

  // const allResults = results.query ? [askAIAction, ...results] : results;

  // if (!results.length)
  //   return (
  //     <div className="p-12 flex items-center justify-center text-black dark:text-white">
  //       No results found
  //     </div>
  //   );

  // const aiResult = createAction({
  //   // id: "askAI",
  //   name: `Ask AI "${searchQuery}"`,
  //   keywords: "help",
  //   // perform: console.log
  //   perform: () => {
  //     // Implement your AI query function here
  //     console.log(`Asking AI: ${searchQuery}`);
  //     // console.log(`Asking AI: ${query.getInput}`);
  //     query.toggle();
  //   },
  // });

  // console.log("Results", results);

  return (
    <div className="p-1">
      <p
        className="font-semibold text-black/30 dark:text-white/70 text-sm p-3"
        // onClick={() => query.toggle()}
      >
        Results
      </p>
      <KBarResults
        // items={[aiResult, ...results]}
        items={results}
        onRender={({ item, active }) => (
          <div
            className={`px-4 py-3 flex items-center justify-between text-black dark:text-white cursor-pointer ${
              active ? "bg-white/20 dark:bg-black/20 rounded" : ""
            }`}
          >
            <div className="flex items-center">
              {item.id === "askAI" && <Sparkle className="w-5 h-5 mr-2" />}
              <span>{item.name}</span>
            </div>
            {item.shortcut && (
              <span className="text-xs opacity-60">
                {item.shortcut.join(" ")}
              </span>
            )}
          </div>
        )}
      />
      {!results.length && (
        <div className="p-12 flex items-center justify-center text-black dark:text-white">
          No results found
        </div>
      )}
    </div>
  );
};

const AIActionRegistrar = ({ searchQuery }) => {
  const { query } = useKBar();
  const { setCopilotContext, setCopilotOpen } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    const action = {
      id: "askAI",
      name: `Ask Copilot: "${searchQuery}"`,
      keywords: "ai assistant help",
      perform: async () => {
        console.log(`Asking AI: ${searchQuery}`);

        // open copilotWrapper
        // setCopilotOpen(true);
        // const name = await complete(
        //   "Please generate a simple word or phrase (no more than 4 words) to title the attached thread of chatbot messages.  Just return the phrase only.  No before or after messages...just the word or phrase.",
        //   searchQuery
        // );

        // const { campaign, object } = await createCampaignAndObject({
        //   token: getSessionToken(),
        //   name,
        //   type: "chat",
        //   nodeType: "chat_history",
        //   settings: { messages: [] },
        // });

        console.log("Navigating to copilot");
        
        // navigate("/copilot");
        setCopilotOpen(true);

        // window.location.href = '/copilot';

        // setTimeout(() => {
        console.log("Setting the context");
        setCopilotContext((prevContext: any) => ({
          ...prevContext,
          query: searchQuery,
        }));
        // }, 5000);

        // query.toggle();
      },
      priority: 1000,
    };

    query.registerActions([action]);

    // return () => {
    //   query.deregister([action]);
    // };
  }, [searchQuery, query]);

  return null;
};

export const CmdK = ({ children }) => {
  const [useTheseActions, setUseTheseActions] = useState(actions);
  const [searchQuery, setSearchQuery] = useState("");
  const [resultMode, setResultMode] = useState("Search");
  const { darkMode, setCopilotContext, setCopilotOpen } =
    useContext(AppContext);

  const { query } = useKBar();

  useEffect(() => {
    const pluginResponse =
      triggerHook(HookTriggers.onListItems, {
        id: ListItemsHooks.UNIVERSAL_SEARCH_ITEMS,
      }) || [];

    console.log(pluginResponse);

    setUseTheseActions([...actions, ...pluginResponse]);

    // setTimeout(() => {
    //   document.getElementsByTagName("body")[0].style.marginRight = "0px";
    // }, 2000)
  }, []);

  // useRegisterActions([
  //   {
  //     id: "askAI",
  //     name: "Ask AI",
  //     keywords: "ai assistant help",
  //     perform: () => {
  //       console.log(`Asking AI: ${searchQuery}`);
  //       // query.toggle();
  //     },
  //   },
  // ], [searchQuery]);

  // const handleAIQuery = () => {
  //   // setResultMode("AI");
  //   // setCopilotContext((prevContext) => ({
  //   //   ...prevContext,
  //   //   query: searchQuery
  //   // }));

  //   // // open copilotWrapper
  //   // setCopilotOpen(true);

  //   // close the cmdk
  //   // Close the KBar
  //   console.log(kbar);
  // };

  return (
    <KBarProvider actions={useTheseActions}>
      <AIActionRegistrar searchQuery={searchQuery} />
      <KBarPortal>
        <div className={`${darkMode ? "dark" : "light"}`}>
          <KBarPositioner className=" bg-black/60 dark:bg-white/10 z-[9999]">
            <div className=" max-w-2xl w-full overflow-hidden">
              <KBarAnimator className="w-full bg-white dark:bg-black/90 backdrop-blur text-black rounded-xl overflow-hidden shadow-lg">
                <div className="flex">
                  <Sparkle className="my-5 mx-4 w-5 h-5 text-gray-400 dark:text-gray-300" />
                  <KBarSearch
                    className="w-full py-4 text-lg outline-none border-none bg-transparent text-black dark:text-white placeholder-gray-400 dark:placeholder-gray-300 focus:outline-none focus:ring-0"
                    defaultPlaceholder="Ask the copilot a question..."
                    // defaultPlaceholder="Search or ask the AI a question..."
                    style={{ WebkitAppearance: "none" }}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
                {/* Tag List */}
                <div className="px-4 mb-4">
                  <ContextChips onChange={console.log} />
                </div>
                <div className="border-b border-black/10 dark:border-white/10" />

                {/* Results */}
                {resultMode == "AI" && (
                  <div className="max-h-80 overflow-scroll">
                    <ChatBot
                      showHeader={false}
                      title="What can I help you launch today?"
                      subtitle="Generate landing pages, sales funnels, copy, and much more."
                      sessionId="cm15mq8h30eg70811vcipx6vc"
                      pageSettings={{}}
                      suggestions={[]}
                      placeholder="Ask Launch OS a question..."
                    />
                  </div>
                )}
                <RenderResults searchQuery={searchQuery} />

                {/* {resultMode === "Search" && (
                <>
                  <div className="p-1">
                    <p className="font-semibold text-black/5 dark:text-white/70 text-sm p-3">
                      Actions
                    </p>
                    <div
                      className={`px-4 py-3 flex gap-2 items-center text-black dark:text-white cursor-pointer bg-white/20 hover:bg-white/30 dark:bg-black/20 hover:dark:bg-black/10 rounded`}
                      onClick={handleAIQuery}
                    >
                      <Sparkle />
                      <p>Ask AI {searchQuery && `"${searchQuery}"`}</p>
                    </div>
                  </div>
                  <RenderResults searchQuery={searchQuery} />
                </>
              )} */}
              </KBarAnimator>
            </div>
          </KBarPositioner>
        </div>
      </KBarPortal>
      {children}
    </KBarProvider>
  );
};

export const useCmdK = () => {
  const { query } = useKBar();
  return {
    open: query?.toggle,
  };
};
