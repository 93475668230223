import React, { useContext } from "react";
import { get, uniq } from "lodash";

import {
  PositionPadding,
  Alignment,
  Interactions,
  PropertiesWindow,
} from "@/Apps/Pages/Properties";

import RichEditor, { RichEditorPosition } from "@/Apps/Pages/Properties/RichEditor";

import {
  generateInlineStyle,
  generateGoogleFontList,
} from "@/Apps/Pages/Properties/RichEditor/utilities";
import style from "./style";
import EditorObject from "@/Apps/Pages/EditorObject";

import { parseLiquidString } from "@/Apps/Pages/Page/LiquidRenderer";
import { EditorMode } from "@/Apps/Pages/Editor/types";
import { PageContext } from "@/Apps/Pages/Page/PageContext";
import { wrapWithAncestorBackgrounds } from "@/Apps/Pages/Properties/RichEditor/RichEditor";
import Button from "@/ui/Button";
import { ButtonTypes } from "@/ui/types";
import { handleGetAlternateCopyRequest } from "@/Apps/Pages/Dashboard";

const TextContentEdit = (props) => {
  // const [ref, hoverBoundingRect] = useDimensions();
  const [dragHandleIsShowing, setDragHandleIsShowing] = React.useState(true);
  // const [usesSmartCopy, setUsesSmartCopy] = React.useState(false);

  const {
    settings,
    updateComponentSettings,
    pageContent,
    setIsEditing = () => {},
    setCanDrag = () => {},
    getCanDrag = () => {},
    changeState = () => {},
    setCanUndo = () => {},
    mode = EditorMode.EDITOR,
  } = props;

  const buttonList = [
    ["bold", "italic", "underline", "strikethrough"],
    ["bullist", "numlist"],
    ["outdent", "indent"],
    ["lineheight"],
    ["link"],
    ["removeformat"],
  ];

  const menuList = [
    ["undo", "redo"],
    // usesSmartCopy ? ["smartcopy"] : [],
    ["fontfamily", "googlefonts", "fontsizes", "align", "lineheight"],
    ["forecolor", "backcolor"],
    ["link"],
  ];

  // get list of fonts used
  const googleFontList = generateGoogleFontList(settings, pageContent);

  const handleChange = (html, fontsList) => {
    const currentFontList = get(settings, "fontsUsed", []).map((arr) =>
      get(arr, "fontFamily")
    );
    const newFontList = uniq([...currentFontList, ...fontsList]);
    const fontsUsed = newFontList.map((font) => ({
      fontFamily: font,
    }));
    updateComponentSettings(settings.id, {
      ...settings,
      html,
      fontsUsed,
    });
  };

  return (
    <EditorObject
      {...props}
      PropertiesView={Properties}
      dragHandle={dragHandleIsShowing}
      label="Text"
      noOffset={get(settings, "html", "").length < 20}
    >
      <div
        style={{ ...settings.properties, marginTop: 0 }}
        // ref={ref}
      >
        <span style={style.span}>
          <RichEditor
            id={`ed_${settings.id}`}
            content={parseLiquidString(settings.html)}
            style={generateInlineStyle(settings)}
            buttonList={buttonList}
            menuList={menuList}
            googleFontList={googleFontList}
            onChange={handleChange}
            onInstantChange={() => {
              if (mode === EditorMode.EDITOR) {
                setDragHandleIsShowing(false);
              }
            }}
            onClick={() => {
              if (mode === EditorMode.EDITOR) {
                setDragHandleIsShowing(true);
              }
            }}
            onMouseEnter={() => {
              if (mode === EditorMode.EDITOR) {
                setDragHandleIsShowing(true);
              }
            }}
            onFocus={(e) => {
              e.stopPropagation();

              console.log("Text Editor Focus", getCanDrag());
              setIsEditing(true);
              setCanDrag(false);
              changeState(settings.id, "active");
              setCanUndo(false);
            }}
            onBlur={(e) => {
              if (mode === EditorMode.EDITOR) {
                console.log("Text Editor Blur", getCanDrag());
                setDragHandleIsShowing(true);
                setCanUndo(true);
                setTimeout(() => {
                  setIsEditing(false);
                  setCanDrag(true);
                }, 200);
              }
            }}
            disabled={mode === EditorMode.SIMPLE}
            settings={settings}
          />
        </span>
      </div>
    </EditorObject>
  );
};

const BasicPropertiesTab = (props) => (
  <>
    {/* <Typography {...props} showLineHeight isExpanded /> */}
    <PositionPadding {...props} isExpanded />
    <Alignment
      {...props}
      label="Text Alignment"
      verticalAlign={false}
      textAlign
    />
    <Interactions
      {...props}
      label="Text Actions"
      onUpdate={(action) => console.log(action)}
    >
      <div />
    </Interactions>
  </>
);

export const SimpleTextProperties = (props) => {

  const { completedItems, updateCompletedItems } = useContext(PageContext);

  const {
    settings,
    updateComponentSettings,
  } = props;

  const buttonList = [
    ["bold", "italic", "underline", "strikethrough"],
    ["bullist", "numlist"],
    ["outdent", "indent"],
    ["lineheight"],
    ["link"],
    ["removeformat"],
  ];

  const menuList = [
    ["undo", "redo"],
    // usesSmartCopy ? ["smartcopy"] : [],
    ["fontfamily", "googlefonts", "fontsizes", "align", "lineheight"],
    ["forecolor", "backcolor"],
    ["link"],
  ];

  const handleChange = (html, fontsList) => {

    // if the current item is not in the completedItems array, add it
    const isComplete = completedItems.includes(settings.id);
    if (!isComplete) updateCompletedItems([...completedItems, settings.id]);

    const currentFontList = get(settings, "fontsUsed", []).map((arr) => get(arr, "fontFamily"));
    const newFontList = uniq([...currentFontList, ...fontsList]);
    const fontsUsed = newFontList.map((font) => ({ fontFamily: font }));
    updateComponentSettings(settings.id, { ...settings, html, fontsUsed });

  };

  return (
    <div className="px-3">
      <div className="rounded-lg my-5">
        {wrapWithAncestorBackgrounds(
          <RichEditor
            id={`ed_${settings.id}`}
            content={settings.html}
            style={generateInlineStyle(settings)}
            buttonList={buttonList as any[][]} 
            menuList={menuList as any[][]}
            position={RichEditorPosition.TOP}
            onChange={handleChange}
          />,
          settings.id, true
        )}
        <div className="flex justify-end mt-3">
          <Button
            type={ButtonTypes.SOFT}
            label=" ✨ Improve with AI"
            onClick={async () => {
              await handleGetAlternateCopyRequest({ settings, updateComponent: updateComponentSettings });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export const Properties = (props) => (
  <PropertiesWindow
    {...props}
    defaultTab="basic"
    tabs={[{ id: "basic", title: "Text", component: BasicPropertiesTab }]}
  />
);

export default (props) => {
  const { settings } = props;
  const marginTop = settings.properties ? settings.properties.marginTop : 0;
  const textAlign = settings.properties
    ? settings.properties.textAlign
    : "inherit";

  return (
    <div style={{ marginTop, textAlign }}>
      <TextContentEdit {...props} />
    </div>
  );
};
