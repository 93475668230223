import { useEffect, useContext, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import { SignedIn, useSession } from "@clerk/clerk-react";

import Sandcastle from "@/Apps/Sandcastle";
import Sandbox from "@/Apps/Sandcastle/Sandbox";
import { KitchenSink } from "@/ui/Layout";

import {
  Components,
  Elements,
  Charts,
  Widgets,
  Icons,
  DragDrop,
  Tables,
  Forms,
  Pages,
} from "@/ui/Layout/KitchenSink";

import Login from "@/Login";

import { Profile } from "@/Settings/Profile";
import { DomainsDashboard } from "@/Settings/DomainManagement";
import { Integrations } from "@/Settings/IntegrationManagement/Integrations";
import Launchpad from "@/Apps/Launchpad";

import Test, { TestChat } from "@/Test";

import { HookTriggers, ListItemsHooks } from "./plugins/types";
import { triggerAsyncHook } from "./plugins/client";
import Onboarding from "./Login/Onboarding";
import { Scroll } from "./Apps/PFOC/Scroll";
import { AppContext } from "./AppContext";
import Setup from "./Login/Setup";

import CmdK from "./ui/CommunicationView/CmdK";

import { ClerkLogin } from "./Login/ClerkLogin";
import { ClerkVerify } from "./Login/ClerkVerify";
import { getBasicUserInfo, getSessionToken } from "./api/auth";
import { ShareAppConfirmation, ShareCampaignConfirmation } from "./Settings/CampaignSharing";
import { Copilot } from "./Apps/AITools/Copilot";
import { CopilotWrapper } from "./Apps/AITools/CopilotWrapper";
import { Browsers } from "@phosphor-icons/react";
import { TreeStructure } from "@phosphor-icons/react";
import { ChatBot } from "./Apps/AITools/ChatBot";
import { NewspaperClipping } from "@phosphor-icons/react";
import { OfflineChatBot } from "./Apps/AITools/OfflineChatBot";

const AppRoutesFromPlugins = () => {
  const [routes, setRoutes] = useState([]);

  const contextObj = useContext(AppContext);

  const loadInAllAppRoutes = async () => {
    const routes = await triggerAsyncHook(
      HookTriggers.onListItems,
      {
        id: ListItemsHooks.ROUTES,
      },
      null,
      null,
      contextObj
    );

    setRoutes(routes);
  };

  useEffect(() => {
    loadInAllAppRoutes();
  }, [contextObj]);

  return routes.map(({ path, element, children = [] }, key) => (
    <Route key={key} path={path}>
      <Route index element={element} />
      {children.map(({ path, element }, key) => (
        <Route key={key} path={path} element={element} />
      ))}
    </Route>
  ));
};

const CustomSignedInCheck = ({ children }) => {
  
  const [isSignedInWithOldLogin, setIsSignedInWithOldLogin] = useState(false);
  const { session } = useSession();

  useEffect(() => {
    const token = getSessionToken();
    const user = getBasicUserInfo();
    const id = session?.user?.publicMetadata?.id;
    if (token && user && !id) setIsSignedInWithOldLogin(true);
  }, [session])

  if (isSignedInWithOldLogin) return children;

  return (
    <SignedIn>{children}</SignedIn>
  )
}

export const AppRoutes = () => {
  // const [onboardingIsShowing, setOnboardingIsShowing] = useState<boolean>(
  //   !Boolean(localStorage.getItem("dontShowWelcomeVideo"))
  // );
  return (
    <BrowserRouter>
      {/* <CmdK> */}
        <Routes>
          <Route
            path="/setup/:action/:token/:prompt/:settings"
            element={<Setup />}
          />
          <Route path="/authLogin" element={<ClerkLogin />} />
          <Route path="/authVerify" element={<ClerkVerify />} />
          <Route path="/login" element={<Login />} />
          <Route path="/setup/:action/:token/:prompt" element={<Setup />} />
          <Route path="/onboarding" element={<Onboarding />} />
          <Route path="/" element={<Navigate to="/login" />} />
          {/* <Route path="/" element={<Navigate to="/authLogin" />} /> */}
          {/* <Route path="/ui">
              <Route index element={<KitchenSink />} />
              <Route path="components" element={<Components />} />
              <Route path="elements" element={<Elements />} />
              <Route path="charts" element={<Charts />} />
              <Route path="widgets" element={<Widgets />} />
              <Route path="icons" element={<Icons />} />
              <Route path="dragdrop" element={<DragDrop />} />
              <Route path="tables" element={<Tables />} />
              <Route path="forms" element={<Forms />} />
              <Route path="pages" element={<Pages />} />
            </Route> */}
          <Route path="/pfoc-scroll" element={<Scroll />} />
          <Route path="/copilot" element={<OfflineChatBot />} />
        </Routes>

        {/* </SignedOut> */}
        <CustomSignedInCheck>
          <Routes>
            {/* <Route path="/sandcastle" element={<Sandcastle />} /> */}
            {/* <Route path="/sandcastleIDE" element={<Sandbox />} /> */}
            <Route path="/launchpad">
              <Route index element={<Launchpad type="all" />} />
              <Route path="funnels" element={<Launchpad type="funnels" />} />
              <Route path="pages">
                <Route index element={<Launchpad type="pages" />} />
                <Route path=":id" element={<Launchpad type="pages" />} />
              </Route>
              <Route path="sites">
                <Route index element={<Launchpad type="sites" />} />
                <Route path=":id" element={<Launchpad type="sites" />} />
              </Route>
              <Route path="forms">
                <Route index element={<Launchpad type="forms" />} />
                <Route path=":id" element={<Launchpad type="forms" />} />
              </Route>
              <Route path="apps">
                <Route index element={<Launchpad type="apps" />} />
                <Route path=":appId" element={<Launchpad type="apps" />} />
              </Route>
            </Route>
            {/* <Route path="/kitchenSink" element={<KitchenSink />} /> */}
            <Route path="/profile" element={<Profile />} />
            <Route path="/domains" element={<DomainsDashboard />} />
            <Route path="/integrations" element={<Integrations />} />
            {/* <Route path="/test" element={<Test />} /> */}
            {/* <Route path="/test-chat" element={<TestChat />} /> */}
            {/* <Route path="/editor-test" element={<Full />} /> */}
            <Route
              path="/share/:campaignId"
              element={<ShareCampaignConfirmation />}
            />
            {AppRoutesFromPlugins()}
          </Routes>
        </CustomSignedInCheck>
      {/* </CmdK> */}
    </BrowserRouter>
  );
};
