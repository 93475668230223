import * as CSS from "./CSS";
import * as ExitIntent from "./ExitIntent";
import * as SEO from "./SEO";
import * as PageScripts from "./PageScripts";
import * as HiddenFields from "./HiddenFields";
import * as MobileResponsiveness from "./MobileResponsiveness";
import * as Stats from "./Stats";

export {
  CSS,
  HiddenFields,
  PageScripts,
  SEO,
  // ExitIntent,
  MobileResponsiveness,
  Stats,
};

export default [
  CSS,
  HiddenFields,
  PageScripts,
  SEO,
  // ExitIntent,
  MobileResponsiveness,
  Stats,
];
