import * as React from "react";
// import * as moment from "moment";

// import posthog from 'posthog-js';

// import { get } from "lodash";


import { StatsProps } from "./types";
import { _postEvent, getTinyBirdData } from "./events";

export const Stats: React.FC<StatsProps> = ({ pageId, campaignId = "", userId = "", objectId = "" }) => {
  // const checkBrowser = () => {
  //   // let nVer = navigator.appVersion;
  //   let nAgt = navigator.userAgent;
  //   let browserName = navigator.appName;
  //   let fullVersion = "" + parseFloat(navigator.appVersion);
  //   let majorVersion = parseInt(navigator.appVersion, 10);
  //   let nameOffset, verOffset, ix;

  //   // In Opera, the true version is after "Opera" or after "Version"
  //   if ((verOffset = nAgt.indexOf("Opera")) !== -1) {
  //     browserName = "Opera";
  //     fullVersion = nAgt.substring(verOffset + 6);
  //     if ((verOffset = nAgt.indexOf("Version")) !== -1)
  //       fullVersion = nAgt.substring(verOffset + 8);
  //   }
  //   // In MSIE, the true version is after "MSIE" in userAgent
  //   else if ((verOffset = nAgt.indexOf("MSIE")) !== -1) {
  //     browserName = "Microsoft Internet Explorer";
  //     fullVersion = nAgt.substring(verOffset + 5);
  //   }
  //   // In Chrome, the true version is after "Chrome"
  //   else if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {
  //     browserName = "Chrome";
  //     fullVersion = nAgt.substring(verOffset + 7);
  //   }
  //   // In Safari, the true version is after "Safari" or after "Version"
  //   else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {
  //     browserName = "Safari";
  //     fullVersion = nAgt.substring(verOffset + 7);
  //     if ((verOffset = nAgt.indexOf("Version")) !== -1)
  //       fullVersion = nAgt.substring(verOffset + 8);
  //   }
  //   // In Firefox, the true version is after "Firefox"
  //   else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {
  //     browserName = "Firefox";
  //     fullVersion = nAgt.substring(verOffset + 8);
  //   }
  //   // In most other browsers, "name/version" is at the end of userAgent
  //   else if (
  //     (nameOffset = nAgt.lastIndexOf(" ") + 1) <
  //     (verOffset = nAgt.lastIndexOf("/"))
  //   ) {
  //     browserName = nAgt.substring(nameOffset, verOffset);
  //     fullVersion = nAgt.substring(verOffset + 1);
  //     if (browserName.toLowerCase() === browserName.toUpperCase()) {
  //       browserName = navigator.appName;
  //     }
  //   }
  //   // trim the fullVersion string at semicolon/space if present
  //   if ((ix = fullVersion.indexOf(";")) !== -1)
  //     fullVersion = fullVersion.substring(0, ix);
  //   if ((ix = fullVersion.indexOf(" ")) !== -1)
  //     fullVersion = fullVersion.substring(0, ix);

  //   majorVersion = parseInt("" + fullVersion, 10);
  //   if (isNaN(majorVersion)) {
  //     fullVersion = "" + parseFloat(navigator.appVersion);
  //     majorVersion = parseInt(navigator.appVersion, 10);
  //   }

  //   const { appName, userAgent } = navigator;

  //   return { browserName, fullVersion, majorVersion, appName, userAgent };
  // };
  // const statsUrl = `https://dut3lz5vi7.execute-api.us-east-1.amazonaws.com/prod/website-events/website_events_stream/records`;
  // const ipGeoUrl = "https://ipgeolocation.abstractapi.com/v1/?api_key=09f57bcbf1db4d23876fe7312e5a14cb";

  // const postHit = async (data) => {
  //   let headers = new Headers();
  //   headers.append("Content-Type", "application/json");
  //   headers.append("Origin", window.location.origin);
  //   headers.append("Refferer-Policy", "no-referrer");

  //   const body = JSON.stringify({
  //     records: [
  //       {
  //         data: JSON.stringify(data),
  //         "partition-key": pageId,
  //       },
  //     ],
  //   });

  //   const options = {
  //     method: "PUT",
  //     headers,
  //     body,
  //     cache: "no-cache",
  //     mode: "cors",
  //     redirect: "follow",
  //   };

  //   const res = await fetch(statsUrl, options);
  //   const response = await res.json();
  //   return response;
  // };

  // const initialize: () => void = async () => {
  //   let stats_initial = {
  //     pageId,
  //     siteId: "",
  //     domain: window.location.host,
  //     uri: window.location.pathname,
  //     referrer: document.referrer,
  //     enteredPage: moment().format(),
  //     browserLanguage: window.navigator.language,
  //     userLanguage: JSON.stringify(window.navigator.languages),
  //     browser: get(checkBrowser(), "browserName"),
  //     browserCodeName: checkBrowser(),
  //     javaEnabled: false,
  //     platform: window.navigator.platform,
  //     cpuClass: "",
  //     operatingSystem: window.navigator.userAgent,
  //     javascriptVersion: "",
  //     screen: JSON.stringify(window.screen),
  //     colors: window.screen.colorDepth,
  //     cookies: window.navigator.cookieEnabled,
  //     historyLength: window.history.length,
  //     active: true,
  //     subscriber: true,
  //     payload: "",
  //   };

  //   const res = await fetch(ipGeoUrl);
  //   const result = await res.json();

  //   let stats = {
  //     ...stats_initial,
  //     ipAddress: result.ip_address,
  //     countryCode: result.country_code,
  //     countryName: result.country,
  //     city: result.city,
  //     region: result.region,
  //     latitude: result.latitude,
  //     longitude: result.longitude,
  //   };

  //   const response = await postHit(stats);
  //   // console.log("Page Switched", stats, response);

  //   return false;
  // };

  // const initializePostHog = () => {
  //   // console.log(`Initializing Posthog! PageId: ${pageId}, UserId: ${userId}, CampaignId: ${campaignId}`);
  //   posthog.init(
  //     "phc_tQg5ZtkeZbdrja1esNyJ7K49vVKC6ciiOspU21lgibL",
  //     {
  //       api_host: 'https://app.posthog.com'
  //     }
  //   );

  //   posthog.capture('$pageview', { pageId, campaignId, userId });
  //   // posthog.identify(userId); // re-consider - instead of mappinig to the client user, we may want to map to the visitor
  // }

  const initializeTinybird = ({ pageId, campaignId, objectId = "" }) => {

    const { data, payload } = getTinyBirdData({ pageId, campaignId, objectId });
    const stats_initial = {
      ...data,
      action: 'page_hit',
      payload: JSON.stringify(payload),
    };

    // console.log('stats_initial', stats_initial);
    _postEvent(stats_initial);
  }

  React.useEffect(() => {
    // initializePostHog();
    
    // Wait a bit for SPA routers    
    setTimeout(() => {
      initializeTinybird({ pageId, campaignId, objectId });
    }, 300);

    // post a pagehit event here

    // initialize();
  }, []);

  return null;
};

export default Stats;
