export const themes = {
  Wireframes: [
    "cm6pgzxr95q4j08112u02zvmy",
    "cm6ph5mj05q8w0811ll96arlg",
    "cm6phlaqs5qer0811ia7f8k21",
    "cm6ph4pba5q7o0811kf1o0u35",
    "cm6phkhnm5qdj0811if8vrcr8",
    "cm6phnp215qhg0811q5cyxez0",
    "cm6phofz05qja0811sa8sr1b4",
    "cm6php2qb5qkv0811lj4waxxv",
    "cm6tmksjd642c0811woei08wa",
    "cm6tmm762644f0811e9rqnf2l",
    "cm6tmt39k648z0811ptbg7cb7",
    "cm6tmvuq764c10811d4jrkuij",
    "cm6tmwzmz64dm0811bpkij823",
    "cm6tmy6pz64f90811vfvqniyf",
  ],
};

export const xthemes = {
  Achievement: [
    "ckx0cadzp74080811i6db6mnq",
    "ckx0cadln73yo0811azmmwt9g",
    "ckx0cae2b740m0811ovpbvzg4",
    "ckx0cadtb73zg0811cikb3pgk",
    "ckx0cae4t74100811wo6gho0b",
  ],
  "Blue Transformation": [
    "ckv43wkbk00yh0880ry8m7529",
    "ckv43wkhg00z908800n10wzeg",
    "ckv973k3j01qn0880an5nnmal",
    "ckv974r1e01sj0880eb2xcfc3",
    "ckv9745jw01rj0880pmdz15ao",
    "ckv43wk3b00xb08806z3bkx1s",
  ],
  "Brand Story": [
    "ckx0c6ba7735g0811lrl6rr40",
    "ckx0c6bfq73680811s2oyie0q",
    "ckx0c6bd0735u0811v30f68pt",
    "ckx0c6biy736m0811t85alq4a",
  ],
  "Brilliant Idea": [
    "ckxq3zohn904l0811ksqy2838",
    "ckxq3zol5904z08119y28wgkw",
    "ckxq3zor3905r0811kx51aaih",
    "ckxq3zoo9905d0811zmz8lnq1",
  ],
  Brilliance: [
    "ckx0ccpun74gm08114270koot",
    "ckx0ccpxr74h00811bsjpfxqs",
    "ckx0ccpm274fu0811o9ud10ke",
    "ckx0ccqad74ik08111e6a6usw",
    "ckx0ccq5g74hs08118s1iinm7",
  ],
  Coffee: [
    "ckxq3wchm8zun0811nai0zfgm",
    "ckxq3wckg8zv10811wwfg68ln",
    "ckxq3wcn28zvf0811xu8bd6jg",
    "ckxq3wcsa8zw70811fiwa4njo",
    "ckxq3wcpp8zvt0811weoscnct",
  ],
  Confetti: [
    "ckv46pgfk01zu0880wl13fh5b",
    "ckv46pgi7020808805sqyznc8",
    "ckv46pgo702100880mkhc2dav",
    "ckv46pgud021s0880ro4sk7og",
    "ckv46phbm022y0880gab7f9ia",
    "ckv46ph72022k0880kno15usc",
    "ckv46pgcs01zg0880vuj42rak",
  ],
  Couragious: [
    "ckx0c9b3m73uv0811y5nn9jdg",
    "ckx0c9azv73uh08113v3l6vat",
    "ckx0c9axf73u30811h3fmpvrb",
    "ckx0c9auv73tp0811uiw9fgjh",
  ],
  "Cyber Summit": [
    "ckxq3uop78z0j081188j514yl",
    "ckxq3uosh8z1i0811dchscfrj",
    "ckxq3up8i8z620811xdtwd6c6",
    "ckxq3uove8z2a0811d0u3kfp0",
    "ckxq3uoxt8z320811ag39erds",
    "ckxq3up0d8z3u0811vx9tn2wp",
    "ckxq3up2x8z4m0811nkxg3np4",
  ],
  "Dark Discovery": [
    "ckxfy46298pf508110lghdhrj",
    "ckxfy46bs8pgb0811k07z14vd",
    "ckxfy46f38pgp0811qffbi6vu",
    "ckxfy45z88per0811l4xa6wkz",
    "ckxfy468p8pfx0811j3fis8y6",
  ],
  Delivered: [
    "ckx314z7z7j310811qa4qa723",
    "ckx314zgn7j3t08114q3ji6gc",
    "ckx314zcb7j3f0811p0km5pty",
    "ckx314zjz7j470811r38211wv",
    "ckx314zmb7j4l0811619clptx",
    "ckx314zoz7j4z0811gat7sd6c",
    "ckx314zro7j5d0811ggs30ko4",
  ],
  Development: [
    "ckxfy22w28oyx08113fqinead",
    "ckxfy23qi8p21081180jory60",
    "ckxfy22zc8ozb0811mr82qstb",
    "ckxfy23k98p1n08116ipo1md4",
    "ckxfy232a8ozp0811sdh6e5ww",
    "ckxfy23538p030811meh6b5j2",
  ],
  "Digital Agency": [
    "ckx1ywb1m7ek50811sw5fq9jd",
    "ckx1ywbdl7elp0811e9h43a4z",
    "ckx1ywb4g7ekj0811lqvxoq9q",
    "ckx1ywayq7ejr0811xcg0xp67",
    "ckx1ywb7l7ekx0811aot5v5ha",
  ],
  "Fresh Goodness": [
    "ckx315xim7jc608115p2dbdpp",
    "ckx315xs37jdc081154qgyzlg",
    "ckx315xlw7jck0811v97y12wv",
    "ckx315xer7jbs0811p6ispfii",
    "ckx315xod7jcy0811v66tibnz",
  ],
  "Gormet Dining": [
    "ckx1m6y5g7aho0811xd3wwrlg",
    "ckx1m6y8o7ai20811i1tdnbbw",
    "ckx1m6y0h7agw08111tbs36du",
    "ckx1m6xv97ag40811q2gv3kem",
    "ckx1m6y2y7aha0811jm3lszdb",
  ],
  "Investor Secrets": [
    "ckxfy31m38p720811vvzbdeej",
    "ckxfy326b8p9008112rf0q5ok",
    "ckxfy31y68p880811qh0n2www",
    "ckxfy321e8p8m08110g0rltp2",
    "ckxfy32cx8p9s0811rb03oagg",
    "ckxfy32a18p9e0811amb867iy",
  ],
  Masterclass: [
    "ckv43sble00ru08809l5btgxm",
    "ckv43sbuj00t00880enifvli5",
    "ckv43sboq00s80880ko2c3l2x",
    "ckv43sbrp00sm0880uxifx0ng",
    "ckv43sbzy00ts0880f7mge1ks",
    "ckv43sc2o00u60880m2v8w9kw",
  ],
  "Money Mountains": [
    "ckx4f1ys77o760811n84htk4p",
    "ckx4f1z3i7o8c0811nabnbpwe",
    "ckx4f1yvz7o7k0811t40gd3xw",
    "ckx4f1ypd7o6s0811hv5k8y6x",
    "ckx4f1yz57o7y0811fdyjt7oq",
  ],
  "Nonprofit Fun": [
    "ckxfy0y228ot50811kfsqo7lr",
    "ckxfy0y5c8otj081109eeghqt",
    "ckxfy0xyx8osr0811a57wgyxb",
    "ckxfy0y818otx08111fsfncrn",
  ],
  Nutritious: [
    "ckxq3ry468xyr0811yvw0r9ff",
    "ckxq3ryfe8y0b0811a3ropjy4",
    "ckxq3ry7b8xz50811us8qtb8y",
    "ckxq3ryif8y0p08110ae8ih75",
    "ckxq3rya48xzj0811e5c9k2ob",
    "ckxq3rycn8xzx0811ncmqs3xc",
  ],
  Outliers: [
    "ckx4f3yqj7of90811xfgxfbf9",
    "ckx4f3yty7ofn0811t91q2jfv",
    "ckx4f3z4j7ogt0811ajwt5ryr",
    "ckx4f3yni7oev08118c3p729k",
    "ckx4f3ywy7og10811dk3nju0e",
  ],
  Passion: [
    "ckx0c80hf73lm0811kh1p465c",
    "ckx0c80lv73m00811s5k6al13",
    "ckx0c80qu73ms0811q8b0a76s",
    "ckx0c80tm73n608117chq29ie",
    "ckx0c80aw73ku0811rucrachx",
  ],
  "Perfect Care": [
    "ckxfy5w3s8prc0811ur5yrdbr",
    "ckxfy5wjq8pta0811pd2wfzte",
    "ckxfy5w738prq08114cmj5243",
    "ckxfy5vze8pqy0811b18mout8",
    "ckxfy5wgb8psw0811a1alii6e",
    "ckxfy5w9r8ps40811omsc2a1k",
  ],
  Personality: [
    "ckxq3xdrz8zzg0811wedjnzxx",
    "ckxq3xdvi8zzu0811221ge8ow",
    "ckxq3xdyu90080811qi5zrxbh",
  ],
  "Real Results": [
    "ckv6ez9en07l80880t9azmmhj",
    "ckv6ez9jx07m0088057j60idq",
    "ckv6ez8bh07fe0880p4nc5dnd",
    "ckv6ez9ru07n60880jdg9jggp",
    "ckv6ez8qv07hq08803x2pyd2h",
    "ckv6ez83r07e808803ehoocjk",
  ],
  Stronger: [
    "ckx0c2tte72c00811auh6990t",
    "ckx0c2twd72ce0811xjnuzbkf",
    "ckx0c2u1h72d60811dgj0wywd",
    "ckx0c2tyy72cs081195ehk1ts",
  ],
  "Super Easy": [
    "ckv46w3ff02wg0880c3yzdi7z",
    "ckv46w4h4034p0880zcfv3xor",
    "ckv46w3yq02z908804mo5h8rh",
    "ckv46w4ur038l08807c0qooba",
    "ckv46w3qy02xm0880iq6pah66",
    "ckv46w32302uw0880pknehlxy",
  ],
  "Surprised Delight": [
    "ckv46qbpz027f08801q3ctm4y",
    "ckv46qc59029d0880rah1oinz",
    "ckv46qbsq027t0880lyskahmf",
    "ckv46qc8n029r0880pbbo01va",
    "ckv46qcbv02a508801ckozx2n",
    "ckv46qbn202710880qttffs5z",
  ],
  Tool: [
    "ckx0cbi2774a10811v48djwsm",
    "ckx0cbi7n74af0811ffso8rbv",
    "ckx0cbhw374990811o3aflm8s",
    "ckx0cbhpi748h0811wvv1ehhv",
    "ckx0cbhyv749n0811fodqzm2p",
  ],
  Transformation: [
    "ckxfy72tz8qd108113vu131wh",
    "ckxfy73278qdf0811rozy0tf2",
    "ckxfy735r8qdt0811j048ae20",
    "ckxfy738z8qe70811cau8gj34",
    "ckxfy73bu8qel0811e9yph7r0",
    "ckxfy73el8qez08110gxwoy6p",
    "ckxfy73hh8qfd081146ckvh9d",
  ],
  Ultimatium: [
    "ckv46v7xp02gr0880vxn4rx8x",
    "ckv46v83m02hj0880tsqscvar",
    "ckv46v80f02h50880m7qr2r38",
    "ckxarkrnl87be0811csot3po5",
    "ckv46v89m02ib0880tidp2tm1",
    "ckv46v7v602gd0880pqg8rfca",
    "ckv46v8cg02ip0880j1j67eqn",
  ],
  Yoga: [
    "ckx1m5yy77aau08119hsslazg",
    "ckx1m5z5v7ac008114ak1xjeb",
    "ckx1m5z1j7ab80811xl8nfmoj",
    "ckx1m5z3i7abm0811m3zwf0ug",
  ],
  "10MF": [
    "ckp3yt9kzbtmt0780pt1lgy9l",
    "ckp6zsu2lcnxk0780aswlqctw",
    "ckp74ovamcqph0780r4kok8fc",
    "ckp7jyid9cwu50780ic9mila9",
    "ckp87gbrocy1x0780dslk5jp3",
    "ckp8j3rr1d3f50780x8r40ddu",
    "ckplqqtwy00jg088057zg476y",
    "ckplvivdz03aj0880gga4rnl2",
    "ckq2s0l5e2pv00880k4e0nxma",
  ],
};
