import { ClientResponse, QueryError, QuerySQL } from "./types";

// export const getPageViews = async (pageId, { startDate, endDate }) => {
//   // Find all the $pageview events with the pageId property
//   const response = await getEventViews("$pageview", {
//     startDate,
//     endDate,
//     properties: [{ key: "pageId", value: pageId, operator: "exact" }],
//   });
//   return response;
// };

// export const getCampaignViews = async (campaignId, { startDate, endDate }) => {
//   // Find all the $pageview events with the campaignId property
//   const response = await getEventViews("$pageview", {
//     startDate,
//     endDate,
//     properties: [{ key: "pageId", value: campaignId, operator: "exact" }],
//   });
//   return response;
// };

// export const getEventViews = async (
//   eventId = "$pageview",
//   { startDate, endDate, properties }
// ) => {
//   // Find all the $eventId events
//   const myHeaders = new Headers();

//   myHeaders.append("Authorization", `Bearer ${token}`);

//   try {
//     const response = await fetch(
//       `https://app.posthog.com/api/projects/3790/events/?event=${eventId}${
//         startDate ? `&after=${startDate}` : ""
//       }${endDate ? `&before=${endDate}` : ""}&properties=${
//         properties ? encodeURIComponent(JSON.stringify(properties)) : ""
//       }`,
//       {
//         method: "GET",
//         headers: myHeaders,
//         redirect: "follow",
//       }
//     );
//     const result = await response.json();
//     console.log(result);
//     return result;
//   } catch (error) {
//     console.log("error", error);
//     return error;
//   }
// };

// export const getCampaignSales = async (campaignId, { startDate, endDate }) => {
//   // Find all the $purchase events with the campaignId property
//   const response = await getEventViews("$purchase", {
//     startDate,
//     endDate,
//     properties: [{ key: "campaignId", value: campaignId, operator: "exact" }],
//   });
//   // Then sum the total value of all the "amount" property
//   // The amount property is a key in the properties object of the response object
//   // return a sum of all the values of the amount property
//   const total = response.results.reduce((acc, curr) => {
//     return acc + curr.properties.amount;
//   });

//   return total;
// };

// export const getEarningsPerClick = async (
//   pageId,
//   campaignId,
//   { startDate, endDate }
// ) => {
//   // Find all the events of people who visited pageId
//   const pageViews = await getPageViews(pageId, { startDate, endDate });
//   // const campaignViews = await getCampaignViews(campaignId, { startDate, endDate });
//   const campaignSales = await getCampaignSales(campaignId, {
//     startDate,
//     endDate,
//   });

//   // Then divide by the people who visited pageId & also had a purchase event
//   const earningsPerClick = campaignSales / pageViews.results.length;

//   return earningsPerClick;
// };

// export const getKPIs = async () => {
// export const getKPIs = async (campaignId, { startDate, endDate }) => {

export function getConfig() {
  // const params = new URLSearchParams(window.location.search)
  // const token = config.authToken ?? params.get('token')
  const token =
    "p.eyJ1IjogIjA1ZDNhZjFlLTdmODItNDY5Ni1iZjZmLTZiZjYyMzE3N2IwZCIsICJpZCI6ICI3MGFiNDkwYS1mMTAwLTQwNTItOTExNS03NjAyMmNiMWY4OWEiLCAiaG9zdCI6ICJ1c19lYXN0In0.8YlAwFEtk6CHBJRYW0yKRde-L1qX1K_5gW9OQMpSXu0";

  const host = "https://ui.us-east.tinybird.co";

  return {
    token,
    host,
  };
}

export async function client<T>(
  path: string,
  params?: RequestInit
): Promise<ClientResponse<T>> {
  const { host, token } = getConfig();

  if (!token || !host) throw new Error("Configuration not found");

  const apiUrl =
    {
      "https://ui.tinybird.co": "https://api.tinybird.co",
      "https://ui.us-east.tinybird.co": "https://api.us-east.tinybird.co",
    }[host] ?? host;

  const response = await fetch(`${apiUrl}/v0${path}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    ...params,
  });
  const data = (await response.json()) as ClientResponse<T>;

  if (!response.ok) {
    throw new QueryError(
      data?.error ?? "Something went wrong",
      response.status
    );
  }
  return data;
}

export const getKPIs = async (campaignId, objectId = null, action = 'page_hit') => {

  let q = '';
  if (campaignId) q = `/pipes/kpis_obj.json?q=SELECT * FROM kpis_obj WHERE campaign_id = '${campaignId}' AND action = '${action}'`
  if (objectId) q = `/pipes/kpis_obj.json?q=SELECT * FROM kpis_obj WHERE object_id = '${objectId}' AND action = '${action}'`
  if (!campaignId && !objectId) q = `/pipes/kpis_obj.json?q=SELECT * FROM kpis_obj WHERE action = '${action}'`

  const response = await client(q);

  console.log("getKPIS from API", response)
  return response;
};

export function querySQL<T>(sql: string): Promise<QuerySQL<T>> {
  return client(`/sql?q=${sql}`)
}

// export const getCampaignSales = async (campaignId, { startDate, endDate }) => {
export const getCampaignSales = async (campaignId) => {
  // Find all the $purchase events with the campaignId property
  const response = await querySQL(`
    SELECT * FROM analytics_obj_hits
    WHERE campaign_id = '${campaignId}' AND action = 'sale' FORMAT JSON
  `);
    // WHERE timestamp >= '${startDate}' AND timestamp <= '${endDate}' AND campaign_id = '${campaignId}' AND action = 'sale' FORMAT JSON

  console.log("getCampaignSales from API", response);
  // Then sum the total value of all the "amount" property
  // The amount property is a key in the properties object of the response object
  // return a sum of all the values of the amount property
  const total = response.data.reduce((acc, curr) => {
    return Number(acc) + Number(curr.amount);
  }, 0);

  return total;
}

export const getCurrentVisitors = async (campaignId) => {
    const { data } = await querySQL<{ visits: number }>(
      `SELECT uniq(session_id) AS visits FROM analytics_obj_hits
        WHERE timestamp >= (now() - interval 5 minute) AND campaign_id = '${campaignId}' FORMAT JSON`
    )
    // const { data } = await querySQL<{ visits: number }>(
    //   `SELECT uniq(session_id) AS visits FROM analytics_obj_hits
    //     WHERE timestamp >= (now() - interval 5 minute) AND campaign_id = '${campaignId}' FORMAT JSON`
    // )
    console.log("getCurrentVisitors from API", data)
    const [{ visits }] = data
    return visits
}
