import { ReactLiquid, useLiquid } from "react-liquid";
import { Liquid } from 'liquidjs';

import { remapCampaignVariables } from "../utils";
import { parseValue } from "./scripts";
import { useContext } from "react";
import { PageContext } from "./PageContext";
import { ContainerContext } from "../WebComponents/v1/container/ContainerContext";
import StripePaymentElement from "../WebComponents/v2/PaymentElement/live";
import { keys } from "lodash";

export const shouldParseLiquid = (html: string): boolean => {
  const pageContext = useContext(PageContext);
  
  // console.log(
  //   "shouldParseLiquid",
  //   // html,
  //   pageContext?.parseLiquid,
  //   // html.includes("{{"),
  //   // html.includes("}}")
  // );

  if (!html) return false;
  return pageContext?.parseLiquid && html.includes("{{") && html.includes("}}");
};

export const LiquidRenderer = ({ html, data = {} }) => {
  const pageContext = useContext(PageContext);
  const containerContext = useContext(ContainerContext);
  
  // console.log("LiquidRendererComponent", html, shouldParseLiquid(html))
  // if (!shouldParseLiquid(html)) return html; // why is this rendering opposite
  
  if (html?.includes("{{ stripe_payment_element }}")) return <StripePaymentElement />;
  
  return (
    <ReactLiquid
      // template={html}
      template={parseValue(html)}
      data={{
        ...remapCampaignVariables(pageContext?.campaignSettings?.contentVariables),
        ...remapCampaignVariables(pageContext?.campaignSettings?.variables),
        ...data,
        ...containerContext,
      }}
      html
    />
  );
};

export const getRepeatContent = (var_name) => {
  if (!var_name) return;
  const pageContext = useContext(PageContext);
  const data = {
    ...remapCampaignVariables(pageContext?.campaignSettings?.contentVariables),
    ...remapCampaignVariables(pageContext?.campaignSettings?.variables),
  };
  const toLoopAround = data?.[var_name];
  // console.log({ toLoopAround, var_name, data });
  return toLoopAround;
};

// This function recursively parses an object to process any string values using the parseLiquidString function.
// It handles both arrays and objects, ensuring that all string values within the structure are parsed.
export const parseLiquidObject = (obj: any, forceContext = {}): any => {
  if (obj === null || typeof obj !== 'object') return obj;

  if (Array.isArray(obj)) {
    return obj.map(item => parseLiquidObject(item, forceContext));
  }

  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      key,
      typeof value === 'string' ? parseLiquidString(value, forceContext) : parseLiquidObject(value, forceContext),
    ])
  );
};

export const parseLiquidString = (str: string, forceContext = {}) => {
  if (!str) return str;

  if (!shouldParseLiquid(str)) return str;

  let pageContext = {};
  if (keys(forceContext).length > 0) {
    pageContext = forceContext;
  } else {
    pageContext = useContext(PageContext);
  }
  
  const data = {
    ...remapCampaignVariables(pageContext?.campaignSettings?.contentVariables),
    ...remapCampaignVariables(pageContext?.campaignSettings?.variables),
  };
  try {
    const { markup } = useLiquid(str, data);
    return markup || str;
  } catch (e) {
    console.log("Error parsing liquid", e);
    return str;
  }
};

export const parseLiquidStringWithLiquidJS = (str: string, forceContext = {}): string => {

  const data = {
    ...remapCampaignVariables(forceContext?.campaignSettings?.contentVariables),
    ...remapCampaignVariables(forceContext?.campaignSettings?.variables),
  };

  const liquid = new Liquid();
  const result = liquid.parseAndRenderSync(str, data);
  // console.log("Result", result);
  return result;
};

export const parseLiquidObjectWithLiquidJS = (obj: any, forceContext = {}): any => {
  // return Object.fromEntries(
  //   Object.entries(obj).map(([key, value]) => [key, parseLiquidStringWithLiquidJS(value, forceContext)])
  // );
  if (obj === null || typeof obj !== "object") return obj;

  if (Array.isArray(obj)) {
    return obj.map((item) => parseLiquidObjectWithLiquidJS(item, forceContext));
  }

  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      key,
      typeof value === "string"
        ? parseLiquidStringWithLiquidJS(value, forceContext)
        : parseLiquidObjectWithLiquidJS(value, forceContext),
    ])
  );
};
