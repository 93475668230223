// Pages Tour
export const pages = {
  dashboard: [
    {
      selector: "body",
      title: "Welcome to Launch Pages!",
      content: "Create a landing page for your business in minutes.",
      image:
        "https://www.launchos.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fsmart-pages2-light.4c330f97.png&w=3840&q=75",
      // video:
      //   // "https://s3.amazonaws.com/ambid-backup-videos/Launch%20OS%20Videos/LaunchPages%28Quick%29.mp4",
      //   "https://s3.amazonaws.com/ambid-backup-videos/Launch%20OS%20Videos/TourbusClips/Pages-GuidedEditor.mp4",
    },
    {
      selector: "[data-testid='Topbar'] button:last-child",
      title: "Create your first landing page",
      content: "Click this button to start creating your landing page.",
      video:
        "https://s3.amazonaws.com/ambid-backup-videos/Launch%20OS%20Videos/TourbusClips/Pages-CreateButton.mp4",
      // image:
      // "https://www.launchos.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fsmart-pages2-light.4c330f97.png&w=3840&q=75",
    },
  ],
  template: [
    {
      selector: "body",
      title: "Let's choose a design template for your landing page",
      content: null,
      // content:
      // "Choose from our conversion-focused design templates.",
      video:
        "https://s3.amazonaws.com/ambid-backup-videos/Launch%20OS%20Videos/TourbusClips/Pages-ChooseTemplate.mp4",
    },
    {
      selector: "[data-testid='Dropdown']",
      title: "Filter Options",
      content:
        "Use the filter to sort by page type, such as lead capture or sales pages.",
      video:
        "https://s3.amazonaws.com/ambid-backup-videos/Launch%20OS%20Videos/TourbusClips/Pages-TemplateFilters.mp4",
    },
    {
      selector: "[data-testid='ExpressEditorToggle']",
      title: "Template Mode Toggle",
      content:
        "Toggle between guided templates (with step-by-step help) and fully designed templates for full control.",
      video:
        "https://s3.amazonaws.com/ambid-backup-videos/Launch%20OS%20Videos/TourbusClips/Pages-EditorModeToggle.mp4",
    },
    {
      // selector: "[data-testid='CreatePageButton']",
      selector: "body",
      title: "Launch Your Page",
      content:
        "Click 'Create This Page' to start or 'Create with AI' for an auto-built page with smart copy.",
      video:
        "https://s3.amazonaws.com/ambid-backup-videos/Launch%20OS%20Videos/TourbusClips/Pages-LaunchYourPage.mp4",
    },
  ],
  expressEditor: [
    {
      selector: "body",
      title: "Welcome to the Guided Editor",
      content:
        "Enjoy a simple, step-by-step walkthrough designed to make editing effortless.",
      video:
        "https://s3.amazonaws.com/ambid-backup-videos/Launch%20OS%20Videos/TourbusClips/Pages-GuidedEditor.mp4",
    },
    {
      selector: "[data-testid='TemplateSelectionGroup']",
      title: "Appearance Tab",
      content:
        "Begin by setting your color scheme and company name to reflect your brand.",
      video:
        "https://s3.amazonaws.com/ambid-backup-videos/Launch%20OS%20Videos/TourbusClips/Pages-AppearanceTab.mp4",
    },
    {
      selector: "[data-testid='SectionGroup']:first-child",
      content:
        "Open each sidebar section and answer the questions—the page updates in real time.",
      video:
        "https://s3.amazonaws.com/ambid-backup-videos/Launch%20OS%20Videos/TourbusClips/Pages-SidebarSections.mp4",
    },
    {
      selector: "[data-testid='SectionGroup']:first-child",
      title: "Improve Copy",
      content:
        "Need help? Click 'Improve Copy' for expert copywriting suggestions.",
      video:
        "https://s3.amazonaws.com/ambid-backup-videos/Launch%20OS%20Videos/TourbusClips/Pages-ImproveCopy.mp4",
    },
    {
      selector: "[data-testid='PublishButton']",
      title: "Publish",
      content:
        "When you're ready, hit 'Publish' to go live with your landing page.",
      video:
        "https://s3.amazonaws.com/ambid-backup-videos/Launch%20OS%20Videos/TourbusClips/Pages-PublishButton.mp4",
    },
    {
      selector: "[data-testid='ActionsDropdown']",
      title: "Switch to Design Studio",
      content:
        "Prefer more control? Switch to the full editor for advanced customization.",
      video:
        "https://s3.amazonaws.com/ambid-backup-videos/Launch%20OS%20Videos/TourbusClips/Pages-OpenDesignStudio.mp4",
    },
    {
      selector: "[data-testid='SettingsButton']",
      title: "Settings (Gear Icon)",
      content:
        "Configure SEO settings, domain names, tracking scripts, and integrations through the gear icon.",
      video:
        "https://s3.amazonaws.com/ambid-backup-videos/Launch%20OS%20Videos/TourbusClips/Pages-SettingsGearIcon.mp4",
    },
  ],
  designStudio: [
    {
      selector: "body",
      title: "Welcome to Design Studio",
      content:
        "This powerful design studio gives you complete creative control to customize every aspect of your pages.",
      image:
        "https://www.launchos.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fsmart-pages2-light.4c330f97.png&w=3840&q=75",
    },
  ],
};
