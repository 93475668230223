import { pages as tourData } from './tours';

// Storage Interface Abstraction
interface StorageProvider {
  getItem(key: string): string | null;
  setItem(key: string, value: string): void;
  removeItem(key: string): void;
  getKeysWithPrefix(prefix: string): string[];
}

// localStorage Implementation
class LocalStorageProvider implements StorageProvider {
  getItem(key: string): string | null {
    return localStorage.getItem(key);
  }

  setItem(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  getKeysWithPrefix(prefix: string): string[] {
    const keys = [];
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key && key.startsWith(prefix)) {
        keys.push(key);
      }
    }
    return keys;
  }
}

// This can be replaced with a different implementation in the future
// e.g., DatabaseStorageProvider that makes API calls to a backend
let storageProvider: StorageProvider = new LocalStorageProvider();

// Function to set a different storage provider (for future database implementation)
export function setStorageProvider(provider: StorageProvider): void {
  storageProvider = provider;
}

// Constants
const TOUR_COMPLETED_PREFIX = 'tour_completed_';
const TOUR_SKIPPED_GROUP_PREFIX = 'tour_skipped_group_';

/**
 * Check if a tour should be shown for a specific page
 * @param pageKey The key of the page to check (e.g., 'dashboard', 'template')
 * @param groupKey Optional group the page belongs to (e.g., 'pages', 'marketing')
 * @returns boolean indicating whether to show the tour
 */
export function shouldShowTour(pageKey: string, groupKey?: string): boolean {
  // If this specific tour is marked as completed, don't show it
  if (storageProvider.getItem(`${TOUR_COMPLETED_PREFIX}${pageKey}`) === 'true') {
    return false;
  }
  
  // If the group this page belongs to is skipped, don't show it
  if (groupKey && storageProvider.getItem(`${TOUR_SKIPPED_GROUP_PREFIX}${groupKey}`) === 'true') {
    return false;
  }
  
  // Make sure this is a valid tour
  return Object.keys(tourData).includes(pageKey);
}

/**
 * Mark a specific tour as completed
 * @param pageKey The key of the tour to mark as completed
 */
export function markTourCompleted(pageKey: string): void {
  storageProvider.setItem(`${TOUR_COMPLETED_PREFIX}${pageKey}`, 'true');
}

/**
 * Get all completed tour keys
 * @returns Array of completed tour keys
 */
export function getCompletedTours(): string[] {
  return storageProvider.getKeysWithPrefix(TOUR_COMPLETED_PREFIX)
    .map(key => key.replace(TOUR_COMPLETED_PREFIX, ''));
}

/**
 * Skip all tours for a specific group of pages
 * @param groupKey The group key to skip (e.g., 'pages', 'marketing')
 * @param confirmed Whether the user confirmed skipping the tours
 * @returns boolean indicating success
 */
export function skipGroupTours(groupKey: string, confirmed: boolean = false): boolean {
  if (!confirmed || !groupKey) {
    return false;
  }
  
  // Set the group skip flag
  storageProvider.setItem(`${TOUR_SKIPPED_GROUP_PREFIX}${groupKey}`, 'true');
  return true;
}

/**
 * Skip all tours across the application
 * @param confirmed Whether the user confirmed skipping all tours
 * @returns boolean indicating success
 */
export function skipAllTours(confirmed: boolean = false): boolean {
  if (!confirmed) {
    return false;
  }
  
  // Mark all existing tours as completed
  Object.keys(tourData).forEach(pageKey => {
    markTourCompleted(pageKey);
  });
  
  return true;
}

/**
 * Reset tours for a specific group
 * @param groupKey The group key to reset
 * @param confirmed Whether the user confirmed resetting the tours
 * @returns boolean indicating success
 */
export function resetGroupTours(groupKey: string, confirmed: boolean = false): boolean {
  if (!confirmed || !groupKey) {
    return false;
  }
  
  // Remove the group skip flag
  storageProvider.removeItem(`${TOUR_SKIPPED_GROUP_PREFIX}${groupKey}`);
  return true;
}

/**
 * Reset all tour completion status
 * @param confirmed Whether the user confirmed resetting all tours
 * @returns boolean indicating success
 */
export function resetAllTours(confirmed: boolean = false): boolean {
  if (!confirmed) {
    return false;
  }
  
  // Remove all tour-related items from storage
  const tourKeys = storageProvider.getKeysWithPrefix(TOUR_COMPLETED_PREFIX);
  tourKeys.forEach(key => {
    storageProvider.removeItem(key);
  });
  
  // Remove all group skip flags
  const groupSkipKeys = storageProvider.getKeysWithPrefix(TOUR_SKIPPED_GROUP_PREFIX);
  groupSkipKeys.forEach(key => {
    storageProvider.removeItem(key);
  });
  
  return true;
} 