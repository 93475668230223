import { getSessionToken } from "@/api/auth";
import ChatBot from "./ChatBot";
import { Browsers } from "@phosphor-icons/react";
import { TreeStructure } from "@phosphor-icons/react";
import { NewspaperClipping } from "@phosphor-icons/react";
import { useState, useEffect } from "react";
import { AI_DOMAIN } from "@launchos/shared-utils/env";

export const OfflineChatBot = () => {
  const [suggestions, setSuggestions] = useState([]);

  const loadSuggestions = async () => {
    const prompt = "Generate 3 prompt suggestions";
    const response = await fetch(`${AI_DOMAIN}/api/copilot/suggestions`, {
      method: "post",
      body: JSON.stringify({ prompt }),
    });
    const r = response.json();
    const data = await r;
    console.log("Suggestions", data);
    setSuggestions(data.suggestions);
  };

  useEffect(() => {
    loadSuggestions();
  }, []);

  if (Boolean(getSessionToken())) return null;

  return (
    <div className="h-[100vh] w-full flex items-center justify-center">
      <ChatBot
        showHeader={false}
        title="What can I help you launch today?"
        subtitle="Generate landing pages, sales funnels, copy, and much more."
        pageSettings={{}}
        placeholder="Ask Launch OS a question..."
        suggestions={suggestions.map((itm) => {
          let icon;
          if (itm?.icon === "Browsers") icon = Browsers;
          if (itm?.icon === "TreeStructure") icon = TreeStructure;
          if (itm?.icon === "NewspaperClipping") icon = NewspaperClipping;
          return { ...itm, icon };
        })}
      />
    </div>
  );
};
