import { useEffect, useState } from "react";
import { TitleCardProps } from "@/ui/TitleCard/types";
import GoogleMap from "./GoogleMap";
import { getContacts } from "@/api/crm";
import { getSessionToken } from "@/api/auth";

export const Scroll = () => {
  const [data, setData] = useState<TitleCardProps[]>([]);

  const loadAllData = async () => {
    // const token = getSessionToken();
    const data = await getContacts({ startIndex: 0, endIndex: 100, token: 'bypass' });
    console.log("data", data);
    setData(data);
  };

  useEffect(() => {
    loadAllData();
  }, []);

  return (
    <div className="w-full h-full">
      {/* <div style={{ width: 500, padding: 20, backgroundColor: 'red', overflowWrap: "anywhere" }}>
        {data.filter(itm => itm.scrollReceived).map((itm) => <p>{itm.firstName} {itm.lastName} - {itm.churchLocation}</p>)}
      </div> */}
      <GoogleMap
        width={"100%"}
        height={800}
        addresses={data.filter(itm => itm.scrollReceived).map(
          (itm) => {
            const { id, churchLocation, scrollReceived, inQueue, scrollSent, type } = itm;

            if (scrollReceived && inQueue && !scrollSent && type === "Participant") {
              return {
                ...itm,
                id,
                address: churchLocation,
                icon: "https://sandcastleassets.s3.amazonaws.com/a333c7g2a6b06-logowithouttext.png",
              };
            }

            return {
              ...itm,
              id,
              address: churchLocation,
              icon: "https://cdn-icons-png.flaticon.com/512/3448/3448400.png"
            };
          }
        )}
        // addresses={data.map(
        //   (itm) => {
        //     const { id, churchLocation, scrollReceived, inQueue, scrollSent } = itm;
        //     return {
        //       ...itm,
        //       id,
        //       address: churchLocation,
        //       icon: (() => {
        //         // if (!scrollReceived)
        //           // return "//upload.wikimedia.org/wikipedia/commons/thumb/2/2e/Hourglass_icon_wbg.svg/1024px-Hourglass_icon_wbg.svg.png";
        //           // return "";
        //         // if (scrollReceived)
        //           // return "https://cdn-icons-png.flaticon.com/512/3448/3448400.png";
        //         // if (!inQueue) return "";
                
        //         // return "https://cdn-icons-png.flaticon.com/512/1045/1045148.png";


        //         if (scrollReceived) return "https://cdn-icons-png.flaticon.com/512/3448/3448400.png"; // received
        //         // if (scrollSent && !scrollReceived) return "https://png.pngtree.com/png-clipart/20221218/original/pngtree-vector-inbox-orange-icon-clipart-transparent-background-png-image_8770404.png"; // sent, but not received
        //         // if (inQueue) return "";
        //         return "";

        //       })(),
        //     };
        //   }
        // )}
      />
    </div>
  );
};
