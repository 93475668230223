// import { TextInput } from "@/ui/TextInput";
import { complete } from "@/api/ai";
import { getSessionToken } from "@/api/auth";
import { Loading } from "@/ui/Layout/Loading";
import Modal, { Title } from "@/ui/Modal";
import { PopupSizes } from "@/ui/Modal/types";
import TextInput from "@/ui/TextInput";
import { TextInputFlavors } from "@/ui/TextInput/types";
import { useState } from "react";
import promptToGenerateName from "../../AITools/prompts/title";
import { createOrUpdateChat } from "@/api/ai";
import create_page from "../../Pages/prompts/create_page";
import artifacts from "../../AITools/prompts/artifacts";
import { createCampaign, updateCampaign } from "@/api/campaigns";
import { makeSlug } from "@/api/pages";
import { createPage } from "@/api/pages";
import { useNavigate } from "react-router-dom";
import { ISettings } from "@/Apps/Pages/Editor/types";
import { TabsComponent } from "@/ui/TabsComponent";
import { Settings } from "@/Apps/Dashboards/Settings";

interface LaunchWithAIPopupProps {
  campaignId: string;
  catalogItem: any;
  pageTheme: string;
  pageType: string;
  content: ISettings[];
  setIsOpen: (isOpen: boolean) => void;
}

export const LaunchWithAIPopup: React.FC<LaunchWithAIPopupProps> = ({
  campaignId,
  catalogItem,
  pageTheme,
  pageType,
  content,
  setIsOpen,
}) => {
  const [prompt, setPrompt] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<string>("prompt");
  const [campaignData, setCampaignData] = useState<any>({});

  const handleCreatePage = async () => {
    setLoading(true);
    const token = getSessionToken();
    const name = await complete(prompt, promptToGenerateName);

    // 1. [withAI] create a new chat campaign
    // 2. [withAI] add two messages: the prompt and the first response…
    // 3. [withAI] the response should be in artifact format ← set up artifact to respond properly (prompt format)
    const chatCampaign = await createOrUpdateChat({
      messages: [],
      input: prompt,
      lastMessage: {
        id: Math.random().toString(36).substr(2, 9),
        role: "assistant",
        content: await complete(`${create_page}\n\n${prompt}`, artifacts),
      },
      name,
    });

    // clone an empty campaign before adding a new page to it
    const campaignResponse = await createCampaign({
      token,
      name,
      type: "page",
    });

    const useThisCampaignId = campaignResponse?.id;

    await updateCampaign(useThisCampaignId, {
      token,
      settings: {
        // type: "", //"funnel",
        settings: {
          ...campaignData?.settings,
          withAI: true,
          chatSettings: {
            initialPrompt: prompt,
            chatSessionId: chatCampaign?.id,
          },
        },
      },
    });

    // call the api to add a new page
    const pageSettings = {
      token,
      settings: {
        name,
        campaignId: useThisCampaignId, // the newly created page will be added to this campaign
        // action: pageAction, // tells the api to either add the new page to the campaign or replace one of the pages
        theme: pageTheme,
        type: pageType || "blank",
        slug: makeSlug(name),
        content,
      },
    };

    console.log("pageSettings", pageSettings);

    const pageResponse = await createPage(pageSettings);

    if (pageResponse) {
      if (pageResponse.error) {
        alert(pageResponse.error);
        setLoading(false);
      } else {
        setLoading(false);
        // setIsAddPagePopupOpen(false);
        console.log({ pageResponse });

        // load the page editor with the new page
        navigate(`/pages/${useThisCampaignId}`);
      }
    }
  }

  return (
    <Modal
      // title={`Create a ${unitName}`}
      onClose={() => setIsOpen(false)}
      onSuccess={handleCreatePage}
      size={activeTab === "profiles" ? PopupSizes.XXLARGE : PopupSizes.LARGE}
    >
      <div className="px-5">
        {loading ? (
          <div>
            <Title>
              Creating your page...
              <p className="text-lg text-black/50 dark:text-white/50 text-center p-3">
                Hang tight... AI wizards are crafting your page! ✨
              </p>
            </Title>
            <Loading type="gallery" className="py-8 mx-auto max-w-3xl" />
          </div>
        ) : (
          <div>
            <Title>
              What kind of landing page would<br />you like to create?
            </Title>

            <TabsComponent
              tabs={[
                { label: "Prompt", value: "prompt" },
                { label: "Profiles", value: "profiles" },
              ]}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              actionSection={false}
            />

            {activeTab === "prompt" && (
            <div className="grid grid-flow-row gap-7 mb-7">
              <TextInput
                // label={`What would you like to name this ${unitName}`}
                placeholder={`e.g. 'A landing page for a fitness coaching business'`}
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                flavor={TextInputFlavors.MODERN}
                />
              </div>
            )}

            {activeTab === "profiles" && (
              <div>
                <Settings
                  campaignData={campaignData}
                  setCampaignData={setCampaignData}
                  unitName="Page"
                  widgets={["planner"]}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};
