import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, Title } from "@/ui/Modal";
import { Avatar } from "@/ui/Avatar";
import { Button } from "@/ui/Button";
import { Link } from "react-router-dom";
import gravatar from "gravatar";
import _ from "lodash";
import { PopupSizes } from "@/ui/Modal/types";
import { cloneCampaign } from "@/api/campaigns";
import { getSessionToken } from "@/api/auth";
import { client } from "@/client";
import { GET_CAMPAIGN } from "@/api/gql/campaigns";
import { ButtonTypes, Variants } from "@/ui/types";
import { Loading } from "@/ui/Layout/Loading";
import { Copy } from "@phosphor-icons/react";
import { InputGroup } from "@/ui/InputGroup";

export const ShareCampaignConfirmation = ({ closeHref = "/" }) => {
  const [loading, setLoading] = useState(false);
  const [campaign, setCampaign] = useState({});
  const { campaignId } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCampaign = async () => {
      setLoading(true);

      const { data } = await client.query({
        query: GET_CAMPAIGN,
        variables: { id: campaignId },
      });

      console.log({ data });
      setCampaign(data.campaign);
      setLoading(false);
    };

    fetchCampaign();
  }, [campaignId]);

  const handleCloneCampaign = async () => {
    setLoading(true);
    const { id, type, name } = campaign;

    const response = await cloneCampaign(id, {
      token: getSessionToken(),
      name,
    });
    const newCampaignId = response?.data?.cloneCampaign?.response?.id;

    if (type === "page") navigate(`/pages/${newCampaignId}`);
    else if (type === "" || !type) navigate(`/funnels/${newCampaignId}`);
    else navigate(`/${type}/${newCampaignId}`);

    setLoading(false);
  };

  return (
    <Modal size={PopupSizes.XLARGE} footer={false}>
      {loading ? (
        <Loading type="gallery" className="p-10" />
      ) : (
        <div className="flex flex-col items-center px-8 pb-10">
          <Avatar
            type="Rounded"
            src={gravatar.url(_.get(campaign, "user.email"))}
            height="80"
            width="80"
          />
          <Title>
            {_.get(campaign, "user.firstName")}{" "}
            {_.get(campaign, "user.lastName")} would like to share their "
            {_.get(campaign, "name")}" campaign with you.
          </Title>
          <div className="flex justify-evenly w-[400px] mx-auto">
            <Button
              type={ButtonTypes.ROUNDED}
              variant={Variants.PRIMARY}
              label="Accept It"
              onClick={handleCloneCampaign}
            />

            <Link to={closeHref}>
              <Button
                type={ButtonTypes.OUTLINED}
                //   variant={Variants.WARNING}
                label="Maybe Later"
                onClick={() => {
                  window.location.href = closeHref;
                }}
              />
            </Link>
          </div>
        </div>
      )}
    </Modal>
  );
};

export const ShareCampaignLink = ({ campaignId, setIsOpen = () => {}, unitName = "campaign" }) => {
  const shareUrl = `${window.location.origin}/share/${campaignId}`;

  return (
    <Modal
      icon={
        <img
          className="w-8"
          alt=""
          src="https://sandcastleassets.s3.amazonaws.com/images/duplicate-icon.png"
        />
      }
      title={`Share this ${unitName}`}
      size={PopupSizes.XLARGE}
      onSuccess={() => setIsOpen(false)}
      onClose={() => setIsOpen(false)}
    >
      <div className="flex flex-col items-center">
        <Title>Use this link to share your {unitName} with others.</Title>

        <div className="w-full p-3 px-10">
          <InputGroup
            elements={[
              {
                type: "input",
                leftRounded: true,
                placeholder: shareUrl,
                inputType: "text",
                // disabled: true,
                value: shareUrl,
                style: {
                  padding: 18,
                },
              },
              {
                type: "icon",
                rightRounded: true,
                icon: (
                  <Copy
                    className="cursor-pointer"
                    onClick={async () => {
                      await navigator.clipboard.writeText(shareUrl);
                      alert("Copied to clipboard!");
                    }}
                  />
                ),
              },
            ]}
          />
        </div>

      </div>
    </Modal>
  );
};

export const ShareAppConfirmation = () => {
  return (
    <Modal size={PopupSizes.XLARGE} footer={false}>
      <div>ShareAppConfirmation</div>
    </Modal>
  );
};
