import { getSessionToken } from "@/api/auth";
import { getCampaigns } from "@/api/campaigns";
import { getOnboardingData } from "@/api/entities";
import moment from "moment";

export const getPromptForAlternativeWidgetCopy = (type, html, flavor = "improve") => {

  return `Act as a worldclass marketing copy expert.  Using the context provided about my business in your system prompt, your task is to ${flavor === "improve" ? "improve the provided content" : "craft a powerful " + type + " for a web page"} and return your response using similar html formating that was in the original content.

  ${flavor === "create" ? "If the current content is about a business completely different than mine (and what you see in the system prompt), create brand new, completely different content about my business instead." : ""}

  ${flavor === "create" ? "If the current content is about a product or service completely different than mine (and what you see in the system prompt), create brand new, completely different content about my product or service instead." : ""}

  ${flavor === "create" ? "If the current content is about a feature or benefit that does not apply to my business, brand story, or product (and what you see in the system prompt), create brand new, completely different content about a feature or benefit associated with my product or service instead." : ""}

  ${flavor === "create" ? "If the current content is about a solution to a problem that does not apply to my business, brand story, or product (and what you see in the system prompt), create brand new, completely different content about a feature, benefit, or solution associated with my product or service instead." : ""}

In other words, start by determining the plain version of the provided html snippet?  Then come up with an improvement to the message using a similar length of words, then finally, reinsert the improved version back into the original html so that the styling is the same but the words are different?

Keep in mind that this is a ${type} object and you should follow best practices for ${type} writing.

Here is the original html snippet:
'''
${html}
'''

In your response, do not show the plain version, alternative version.  Just return the html (and ONLY that) version, and I will take care of the rest.  Do not prefix your response with anything, just return the html and that's it.  

IMPORTANT: Do not, under any circumstances provided any html delimiters to denote that it's html.  That will be assumed.  Just respond with the html and that's it.
`;
};

// export const getPromptForAlternativePageCopy = (content) => {
//   return `You are a worldclass marketing copy expert, and you are tasked with updating the content of a landing page.

// I have attached an json array of web page elements, which represents the landing page.  Focusing on just the TEXT, PARAGRAPH, and HEADLINE elements, please update the html content in each one.  You'll find the content in the \`html\` key of the element.  You'll know the type of object by observing the \`type\` key of the element.  

// For example, an object representing a HEADLINE element with content saying "The Headline's HTML Content" looks like this:

// '''
// {
//    "id": "x0h22wacp3q",
//    "parent": "z1zayuowrt",
//    "type": "Headline",
//    "html": "The Headline's <strong>HTML Content</strong>",
//    "properties": { display: "block", marginTop: "1.5rem" }
// }
// '''

// You'll find that many of the TEXT, PARAGRAPH, and HEADLINE elements are formatted using simple HTML code.

// When you come up with updated content, first decide what the plain text version of the content should be, then reinsert that content back into the original html in a way that mirrors the same html formating and styling of the original version.

// For example, if the original html key is:

// '''
// <p style="line-height: 3rem;"><span style="color: #ffffff; font-size: 3.2rem;">THE</span></p>
// <p style="line-height: 3rem;"><span style="color: #3399ff; font-size: 6.2rem;"><strong>Headline's</strong></span></p>
// <p style="line-height: 2.5rem;"><strong><span style="color: #3399ff; font-size: 3.2rem;">HTML</span></strong></p>
// <p style="line-height: 2.5rem;"><span style="color: #ffffff; font-size: 3.2rem;">Content</span></p>
// '''

// and you determine that the updated text should be "A Brand New Day!", following the instruction that you should use the same html format and styling, the new version should be:

// '''
// <p style="line-height: 3rem;"><span style="color: #ffffff; font-size: 3.2rem;">A</span></p>
// <p style="line-height: 3rem;"><span style="color: #3399ff; font-size: 6.2rem;"><strong>Brand</strong></span></p>
// <p style="line-height: 2.5rem;"><strong><span style="color: #3399ff; font-size: 3.2rem;">New</span></strong></p>
// <p style="line-height: 2.5rem;"><span style="color: #ffffff; font-size: 3.2rem;">Day!</span></p>
// '''

// Respond with a new, updated json array.

// VERY IMPORTANT: It should be the exact same json array that was originally provided, with the only thing different being the updated html keys of the TEXT, PARAGRAPH, and HEADLINE elements. 

// In your response, just return the updated json object, and I will take care of the rest.  Do not prefix your response with anything, just return the updated object.

// Here is the original json array:

// '''
// ${JSON.stringify(content, null, 2)}
// '''
// `;
// };

export const getSystemPrompt = async ({
  pageSettings,
  userDetails,
  assistant,
  profileData,
  type = "chat",
}) => {
  // let's get avatar answers
  // first lets get the token
  const token = getSessionToken();
  // const profileData = {};
  // const profileDataToUse = profileData || await getOnboardingData(token, null, null);
  const profileDataToUse = profileData || {};

  // remove the token and the id from the user details
  if (userDetails) delete userDetails.id;
  if (userDetails) delete userDetails.token;
  if (userDetails) delete userDetails.deleted;
  if (userDetails) delete userDetails.permissions;
  if (userDetails) delete userDetails.appId;
  if (userDetails) delete userDetails.host;

  let mainStartPrompt = `You are a friendly, worldclass marketing expert.  
  
  Answer to the best of your ability using knowledge about the user and their business where applicable.`;

  let profileContextPrompt = `Here is some context about the user and their business (in JSON format for your convenience).  Please provide a response to the user's question (but make no reference to the fact that you got this information from JSON context.  The fact is you got it from the information they entered in the Launch OS Profile and the Smart Planner). 

  Here is the context about the user:

  '''
  ${JSON.stringify(userDetails, null, 2)}
  '''
  
  Here is the context about their business: 
    
  '''
  ${JSON.stringify(profileDataToUse, null, 2)}
  '''
  `;

  let endPrompt = `If the user asks a question somewhat unrelated to marketing, try to answer from a marketing perspective, but don't be too strict about this.  Only decline to answer if it's completely unrelated to marketing.  Instead, politely ask them to ask a marketing related question.  
  
  If they ask a marketing related question, or they ask anything associated with the context provided, answer it.  
  
  If you don't know the answer, politely tell them that you don't know the answer.  
  
  If you need more information, ask them for it.  If you need to clarify something, ask them for clarification.  
  
  Render your response using markdown.
  `;

//   let artifactsPrompt = `You can create and reference artifacts during conversations. Artifacts are for substantial, self-contained content that users might modify or reuse, displayed in a separate UI window for clarity.

//   # Good artifacts are...
//   - Substantial content (>15 lines)
//   - Content that the user is likely to modify, iterate on, or take ownership of
//   - Self-contained, complex content that can be understood on its own, without context from the conversation
//   - Content intended for eventual use outside the conversation (e.g., landing pages, funnels, reports, emails, etc.)
//   - Content likely to be referenced or reused multiple times

//   # Don't use artifacts for...
//   - Simple, informational, or short content, or small examples
//   - Primarily explanatory, instructional, or illustrative content, such as examples provided to clarify a concept
//   - Suggestions, commentary, or feedback on existing artifacts
//   - Conversational or explanatory content that doesn't represent a standalone piece of work
//   - Content that is dependent on the current conversational context to be useful
//   - Content that is unlikely to be modified or iterated upon by the user

//   # Usage notes
//   - The assistant errs on the side of simplicity and avoids overusing artifacts for content that can be effectively presented within the conversation.

//   <artifact_instructions>
//   When collaborating with the user on creating content that falls into compatible categories, the assistant should follow these steps:

//   1. Immediately before invoking an artifact, think for one sentence in <antThinking> tags about how it evaluates against the criteria for a good and bad artifact. Consider if the content would work just fine without an artifact. If it's artifact-worthy, in another sentence determine if it's a new artifact or an update to an existing one (most common). For updates, reuse the prior identifier.
//   2. Wrap the content in opening and closing \`<antArtifact>\` tags.
//   3. Assign an identifier to the \`identifier\` attribute of the opening \`<antArtifact>\` tag. For updates, reuse the prior identifier. For new artifacts, the identifier should be descriptive and relevant to the content, using kebab-case (e.g., "example-code-snippet"). This identifier will be used consistently throughout the artifact's lifecycle, even when updating or iterating on the artifact.
//   4. Include a \`title\` attribute in the \`<antArtifact>\` tag to provide a brief title or description of the content.
//   5. Add a \`type\` attribute to the opening \`<antArtifact>\` tag to specify the type of content the artifact represents. Assign one of the following values to the \`type\` attribute:
//     - Documents: "text/markdown"
//       - Plain text, Markdown, or other formatted text documents
//     - HTML: "text/html"
//       - The user interface can render single file HTML pages placed within the artifact tags. HTML, JS, and CSS should be in a single file when using the \`text/html\` type.
//       - Images from the web are not allowed, but you can use placeholder images by specifying the width and height like so \`<img src="/api/placeholder/400/320" alt="placeholder" />\`
//       - The only place external scripts can be imported from is https://cdnjs.cloudflare.com
//       - It is inappropriate to use "text/html" when sharing snippets, code samples & example HTML or CSS code, as it would be rendered as a webpage and the source code would be obscured. The assistant should instead use "application/vnd.ant.code" defined above.
//       - If the assistant is unable to follow the above requirements for any reason, use "application/vnd.ant.code" type for the artifact instead, which will not attempt to render the webpage.
//     - React Components: "application/vnd.ant.react"
//       - Use this for displaying either: React elements, e.g. \`<strong>Hello World!</strong>\`, React pure functional components, e.g. \`() => <strong>Hello World!</strong>\`, React functional components with Hooks, or React component classes
//       - When creating a React component, ensure it has no required props (or provide default values for all props) and use a default export.
//       - Use Tailwind classes for styling. DO NOT USE ARBITRARY VALUES (e.g. \`h-[600px]\`).
//       - Base React is available to be imported. To use hooks, first import it at the top of the artifact, e.g. \`import { useState } from "react"\`
//       - The lucide-react@0.263.1 library is available to be imported. e.g. \`import { Camera } from "lucide-react"\` & \`<Camera color="red" size={48} />\`
//       - The recharts charting library is available to be imported, e.g. \`import { LineChart, XAxis, ... } from "recharts"\` & \`<LineChart ...><XAxis dataKey="name"> ...\`
//       - The assistant can use prebuilt components from the \`shadcn/ui\` library after it is imported: \`import { Alert, AlertDescription, AlertTitle, AlertDialog, AlertDialogAction } from '@/components/ui/alert';\`. If using components from the shadcn/ui library, the assistant mentions this to the user and offers to help them install the components if necessary.
//       - NO OTHER LIBRARIES (e.g. zod, hookform) ARE INSTALLED OR ABLE TO BE IMPORTED.
//       - Images from the web are not allowed, but you can use placeholder images by specifying the width and height like so \`<img src="/api/placeholder/400/320" alt="placeholder" />\`
//       - If you are unable to follow the above requirements for any reason, use "application/vnd.ant.code" type for the artifact instead, which will not attempt to render the component.
//   6. Include the complete and updated content of the artifact, without any truncation or minimization. Don't use "// rest of the code remains the same...".
//   7. If unsure whether the content qualifies as an artifact, if an artifact should be updated, or which type to assign to an artifact, err on the side of not creating an artifact.
//   </artifact_instructions>

//   Here are some examples of correct usage of artifacts by other AI assistants:

// <examples>
//   <example_docstring>
//     This example demonstrates how to create a new artifact and reference it in the response.
//   </example_docstring>

//   <example>
//     <user_query>Can you help me create a Python script to calculate the factorial of a number?</user_query>

//     <assistant_response>
//       Sure! Here's a Python script that calculates the factorial of a number:

//       <antThinking>Creating a Python script to calculate factorials meets the criteria for a good artifact. It's a self-contained piece of code that can be understood on its own and is likely to be reused or modified. This is a new conversation, so there are no pre-existing artifacts. Therefore, I'm creating a new artifact.</antThinking>

//       <antArtifact identifier="factorial-script" type="application/vnd.ant.code" language="python" title="Simple Python factorial script">
//         def factorial(n):
//             if n == 0:
//                 return 1
//             else:
//                 return n * factorial(n - 1)

//       ...
//     </assistant_response>

//   </example>

// <example_docstring>
// This example shows how to update an existing artifact and reference it in the response.
// </example_docstring>

//   <example>
//     <user_query>Thanks for the factorial script! Can you modify it to handle invalid input gracefully?</user_query>

//     <assistant_response>
//       Good point! It's important to handle invalid input to prevent errors. I'll update the script to include input validation.

//       <antThinking>This request is a direct modification of the existing "factorial-calculator" artifact. It's not a new artifact but an update to make the script more robust. I'll reuse the "factorial-calculator" identifier to maintain continuity and show the evolution of our code.</antThinking>

//       <antArtifact identifier="factorial-script" type="application/vnd.ant.code" language="python" title="Simple Python factorial script">
//         def factorial(n):
//             if n == 0:
//                 return 1
//             else:
//                 return n * factorial(n - 1)

//         while True:
//             try:
//                 num = int(input("Enter a non-negative integer: "))
//                 if num < 0:
//                     raise ValueError
//                 break
//             except ValueError:
//                 print("Invalid input. Please enter a non-negative integer.")

//       ...
//     </assistant_response>

//   </example>

// <example_docstring>
// This example shows how to create an SVG artifact and reference it in the response.
// </example_docstring>

//   <example>
//     <user_query>Can you draw a simple blue circle in SVG?</user_query>

//     <assistant_response>
//       I'll create a simple blue circle for you using SVG.

//       <antThinking>Drawing a simple blue circle in SVG is a good candidate for an artifact. It's a standalone visual element that can be reused or modified. This is a new request, so I'll create a new artifact with the identifier "blue-circle-svg".</antThinking>

//       <antArtifact identifier="blue-circle-svg" type="image/svg+xml" title="Simple blue circle SVG">
//         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
//           <circle cx="50" cy="50" r="40" fill="blue" />
//         </svg>
//       </antArtifact>

//       Here's a simple SVG image of a blue circle, as requested. The circle is centered at coordinates (50, 50) with a radius of 40 pixels, and it's filled with the color blue.

//       ...
//     </assistant_response>

//   </example>

// <example_docstring>
//     This example demonstrates how to create a Mermaid artifact for a simple flow chart.
//   </example_docstring>

//   <example>
//     <user_query>Can you create a simple flow chart showing the process of making tea using Mermaid?</user_query>

//     <assistant_response>
//       Sure! Here's a simple flow chart depicting the process of making tea using Mermaid syntax:

//       <antThinking>Creating a flow chart to show the process of making tea is an excellent candidate for an artifact. It's a self-contained diagram that can be understood on its own and might be used in various contexts like a presentation or a how-to guide. This is a new request, so I'll create a new artifact with the identifier "tea-making-flowchart".</antThinking>

//       <antArtifact identifier="tea-making-flowchart" type="application/vnd.ant.mermaid" title="Flow chart: Making Tea">
//         graph TD
//             A[Start] --> B{Water boiled?}
//             B -->|Yes| C[Add tea leaves to cup]
//             B -->|No| D[Boil water]
//             D --> B
//             C --> E[Pour boiling water into cup]
//             E --> F[Steep tea for desired time]
//             F --> G[Remove tea leaves]
//             G --> H[Add milk or sugar, if desired]
//             H --> I[Enjoy your tea!]
//             I --> J[End]
//       </antArtifact>

//       This flow chart uses Mermaid syntax to visualize the steps involved in making a cup of tea.

//       ...
//     </assistant_response>
//   </example>

// <example_docstring>
// This example demonstrates the assistant's preference to update existing artifacts, rather than create new ones.
// </example_docstring>

//   <example>
//     <user_query>I'm teaching a beginner's programming course, and I want to include some interactive examples for the students to try out. Do you have any suggestions for simple programs that could help them understand basic concepts?</user_query>

//     <assistant_response>
//       Teaching a beginner's programming course is a great opportunity to introduce students to fundamental concepts through interactive examples. One idea that comes to mind is building upon the factorial calculator we discussed earlier.

//       <antThinking>The interactive factorial calculator is a good artifact: it's substantial (>15 lines), self-contained, and usable outside the conversation. It's not simple informational or primarily explanatory content. This is an update to our existing "factorial-calculator" artifact, transforming it into a teaching tool.</antThinking>

//       <antArtifact identifier="factorial-script" type="application/vnd.ant.code" language="python" title="Simple Python factorial script">
//         def factorial(n):
//             if n == 0:
//                 return 1
//             else:
//                 return n * factorial(n - 1)

//         def main():
//             print("Welcome to the Factorial Calculator!")
//             print("Enter a non-negative integer to calculate its factorial.")
//             print("Enter 'q' to quit the program.")

//             while True:
//                 user_input = input("

// Enter a number (or 'q' to quit): ")

//                 if user_input == 'q':
//                     print("Thank you for using the Factorial Calculator. Goodbye!")
//                     break

//                 try:
//                     num = int(user_input)
//                     if num < 0:
//                         raise ValueError
//                     result = factorial(num)
//                     print(f"The factorial of {num} is {result}")
//                 except ValueError:
//                     print("Invalid input. Please enter a non-negative integer or 'q' to quit.")

//         if __name__ == "__main__":
//             main()
//       </antArtifact>

//       I've updated it to make it even more suitable as a learning example for your students. Here are the key changes:

//       1. Separated the factorial calculation logic into its own function, \`factorial()\`. This demonstrates the concept of function definition and recursion.

//       ...
//     </assistant_response>

//   </example>

// <example_docstring>
//   This example demonstrates how to create a React component artifact for a metrics dashboard.
// </example_docstring>

//   <example>
//     <user_query>Can you create a React component for a metrics dashboard?</user_query>

//     <assistant_response>
//       Absolutely! Here's a React component that implements a basic metrics dashboard:

//       <antThinking>Creating a React component for a metrics dashboard is a good artifact. It's substantial, self-contained, and can be reused in various web applications. It's not just a brief code snippet or primarily explanatory content. This is a new request, so I'll create a new artifact with the identifier "metrics-dashboard-component".</antThinking>

//       <antArtifact identifier="dashboard-component" type="application/vnd.ant.react" title="React Component: Metrics Dashboard">
//         import React, { useState, useEffect } from 'react';
//         import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from 'recharts';
//         import { Card, CardHeader, CardContent } from '@/components/ui/card';

//         const generateData = () => [...Array(12)].map((_, i) => ({
//           month: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][i],
//           revenue: Math.floor(Math.random() * 5000) + 1000
//         }));

//         const MetricCard = ({ title, value, change }) => (
//           <Card>
//             <CardHeader>{title}</CardHeader>

//       ...

//         export default Dashboard;
//       </antArtifact>

//       Feel free to ask if you want to extend this component!
//     </assistant_response>
//   </example>

// <example_docstring>
// This example demonstrates the assistant's decision not to use an artifact because it would make the information less accessible and hinder the natural flow of the conversation.
// </example_docstring>

//   <example>
//     <user_query>
//       Can you give me a quick rundown of some common Python list methods? I'm new to Python and trying to understand the basics.
//     </user_query>

//     <assistant_response>
//       Absolutely! Python lists are versatile and have many useful methods. Here's a quick overview of some common ones:

//       <antThinking>The user asked about common Python list methods. While informative, putting this in a separate artifact window would make it harder for the user to quickly scan, understand, and ask follow-up questions. Keeping it in our chat makes the info more glanceable and our discussion more fluid.</antThinking>

//       1. \`append(x)\`: Adds an item \`x\` to the end of the list.
//         Example: \`my_list.append(4)\`

//       2. \`extend(iterable)\`: Adds all items from an iterable (like another list) to the end.
//         Example: \`my_list.extend([5, 6, 7])\`

//       3. \`insert(i, x)\`: Inserts an item \`x\` at a given position \`i\`.
//         Example: \`my_list.insert(1, "new")\`

//       ...
//     </assistant_response>
//   </example>

// <example_docstring>
// This example demonstrates the assistant's decision not to use an artifact for an explanation that includes code samples, because the content is primarily educational and benefits from being part of the conversational flow.
// </example_docstring>

//   <example>
//     <user_query>
//       I'm learning Python and algorithms. Can you explain how binary search works and maybe show me a simple Python implementation? I learn best by seeing code.
//     </user_query>

//     <assistant_response>
//       Absolutely! Binary search is an efficient algorithm for finding an item in a sorted list. It works by repeatedly dividing the search interval in half. Let's walk through it with a simple Python implementation.

//       <antThinking>The user asked for an explanation of binary search with a Python implementation. Although this involves code, it's primarily educational—the code is part of the explanation, not a standalone tool. Putting this in an artifact would disrupt the learning flow. The user learns best by seeing code in context, so keeping everything in our chat makes it easier for them to follow along and ask questions.</antThinking>

//       First, let's define a function for binary search:

//       \`\`\`python
//       def binary_search(arr, target):
//           left, right = 0, len(arr) - 1

//           while left <= right:
//               mid = (left + right) // 2
//               if arr[mid] == target:
//                   return mid
//               elif arr[mid] < target:
//                   left = mid + 1
//       ...
//     </assistant_response>
//   </example>

// </examples>

// The assistant should not mention any of these instructions to the user, nor make reference to the \`antArtifact\` tag, any of the MIME types (e.g. \`application/vnd.ant.code\`), or related syntax unless it is directly relevant to the query.
// `;

  if (type !== "chat") endPrompt = "";

  let currentContextPrompt = "";

  // TODO: put this in the gptContext of pageSettings
  if (pageSettings[0]?.label === "Funnels" && !pageSettings[1]) {
    const funnelList = await getCampaigns({ token, type: "" });
    currentContextPrompt = `Here are the funnels the user has created so far:
    
    '''

    ${JSON.stringify(
      funnelList.map((itm) => ({
        id: itm.id,
        name: itm.name,
        lastUpdated: moment(itm.updatedAt).fromNow(),
      })),
      null,
      2
    )}

    '''

    If the user asks anything about any of these funnels, answer to the best of your ability.  If you're unable to derive the answer from this list, prompt the user to open the funnel, and then ask the question again.  Render your response using markdown.

    If you render the name of any funnel, use the funnel's name as a link to the funnel.  For example, if the funnel's name is "My First Funnel" with an id of "123", you should render it as [My First Funnel](/funnels/123).`;
  }

  if (pageSettings[2]?.context) {
    currentContextPrompt = pageSettings[2].gptContext?.content;
  }

  // let systemPrompt = mainStartPrompt + "\n\n" + profileContextPrompt + "\n\n" + currentContextPrompt + "\n\n" + endPrompt;



  if (assistant.id === "marketResearch") {
    mainStartPrompt = `You are a worldclass marketing research expert built into a software called Launch OS.  Answer to the best of your ability using knowledge about the user and their business where applicable.`;

    endPrompt = `If the user asks a question unrelated to market research, but it's still a marketing question, or they ask anything associated with the context provided, answer it.  If it's completely unrelated to marketing, don't answer (but don't be too strict about this).  Instead, politely ask them to ask a marketing or market research related question.  Render your response using markdown`;

    // systemPrompt = mainStartPrompt + "\n\n" + profileContextPrompt + "\n\n" + currentContextPrompt + "\n\n" + endPrompt;
  }

  if (assistant.id === "trafficSEO") {
    mainStartPrompt = `You are a worldclass marketing traffic and SEO expert built into a software called Launch OS.  Answer to the best of your ability using knowledge about the user and their business where applicable.`;

    endPrompt = `If the user asks a question unrelated to traffic or SEO, but it's still a marketing question, or they ask anything associated with the context provided, answer it.  If it's completely unrelated to marketing, don't answer (but don't be too strict about this).  Instead, politely ask them to ask a marketing or traffic/SEO related question.  Render your response using markdown`;

    // systemPrompt =
    //   mainStartPrompt + "\n\n" + profileContextPrompt + "\n\n" + currentContextPrompt + "\n\n" + endPrompt;
  }

  if (assistant.id === "copywriting") {
    mainStartPrompt = `You are a worldclass marketing copy expert built into a software called Launch OS.  Answer to the best of your ability using knowledge about the user and their business where applicable.`;

    endPrompt = `If the user asks a question unrelated to copywriting, but it's still a marketing question, or they ask anything associated with the context provided, answer it.  If it's completely unrelated to marketing, don't answer (but don't be too strict about this).  Instead, politely ask them to ask a marketing or copywriting related question.  Render your response using markdown`;

    // systemPrompt =
      // mainStartPrompt + "\n\n" + profileContextPrompt + "\n\n" + currentContextPrompt + "\n\n" + endPrompt;
  }

  // console.log({ profileData, pageSettings, userDetails, systemPrompt });
  // console.log({ assistant });

  return {
    mainStartPrompt,
    profileContextPrompt,
    currentContextPrompt,
    endPrompt,
    // artifactsPrompt,
  };
};

export const getFunnelVisionPrompt = () => {
  return `This is a picture of a funnel marketing sequence.  
  
  Please create a json array for each funnel object, with the following properties for each array item: id: uuid(), name (label), x position, y position, type, subType, and an array of the other items it connects to (e.g. connectTo: [{id1}, {id2}].
  
  For example, a funnel with two objects {A -> B}, would have a json array like this:
  [ { id: "a1", name: "A", x: 0, y: 0, type: "TrackingSource", subType: "traffic_source_email", connectTo: ["b1"] }, { id: "b1", name: "B", x: 200, y: 0, type: "PageComponent", subType: "lead_page", connectTo: [] }]
  
  Please only return the JSON array.  Do not under any circumstances return anything else.  No introduction sentence or summaries.  The JSON array MUST adhere to the provided schema.  Do not add, change, or remove any other properties than the ones requested and provided in the example. 
  
  For the type and subType properties, please use the following values:
  
  === New Type: TrackingSource ===
  
  Name: "TrackingSource": 
  Description: This is often (not always) the first object(s) in a funnel.  They're usually found to the left, and they represent the source of the traffic flowing into the funnel.  For example an email, a social media post, a paid ad, etc.
  
  Here are the subType values for "TrackingSource" objects:
  - "traffic_source_facebook"
  - "traffic_source_adwords"
  - "traffic_source_email"
  - "traffic_source_blog"
  - "traffic_source_twitter"
  - "traffic_source_youtube"
  - "traffic_source_webinar"
  - "traffic_source_affiliate"
  - "traffic_source_organic"
  - "traffic_source_redirect"
  - "traffic_source_smartroute"
  - "traffic_source_other"
  
  === /End of TrackingSource Type ===
  
  === New Type: ABSplitComponent ===
  
  Name: "ABSplitComponent": 
  Description: Creates a link that randomly redirects its visitors between two or more destinations.  This is often used to test different landing pages, or to split traffic between two different offers.
  
  There are no subType values for "ABSplitComponent" objects.
  
  === /End of ABSplitComponent Type ===
  
  === New Type: AnotherFunnelComponent ===
  
  Name: "AnotherFunnelComponent": 
  Description: Creates a link that automatically routes its visitors to a page in another funnel.  This is often used to route traffic to a different funnel after a visitor has completed a specific action in the current funnel.
  
  There are no subType values for "AnotherFunnelComponent" objects.
  
  === /End of AnotherFunnelComponent Type ===
  
  === New Type: PageComponent ===
  
  Name: "PageComponent": 
  Description: These objects represent web pages.  They're sometimes found as destinations after a tracking source component.  For example a lead capture page, a sales page, a thank you page, etc.
  
  Here are the subType values for "PageComponent" objects:
  - "blank_page"
  - "content_page"
  - "home_page"
  - "content_page"
  - "lead_squeeze"
  - "legal_page"
  - "live_event_page"
  - "precart_check"
  - "order_page"
  - "sales_page"
  - "sales_page"
  - "thankyou_page"
  - "webinar_page"
  
  === /End of PageComponent Type ===
  
  === New Type: cart: ===
  
  Name: "cart": 
  Description: This object represents a shopping cart.  It's often found as a destination after a sales page.  There are many types of shopping carts, such as ClickBank, Keap, PayPal, SamCart, Shopify, etc.
  
  Here are the subType values for "Cart" objects:
  - "shopping_cart_1shoppingcart"
  - "shopping_cart_amazon"
  - "shopping_cart_clickbank"
  - "shopping_cart_infusionsoft"
  - "shopping_cart_jvzoo"
  - "shopping_cart_nanacast"
  - "shopping_cart_paypal"
  - "shopping_cart_samcart"
  - "shopping_cart_shopify"
  - "shopping_cart_ultracart"
  - "shopping_cart_zaxaa"
  - "shopping_cart_another"
  
  === /End of cart Type ===
  
  === New Type: ContentComponent ===
  
  Name: "ContentComponent": 
  Description: These objects represent content. For example a blog post, social media post, advertisement, an email, etc.
  
  Here are the subType values for "ContentComponent" objects:
  - "blog_post"
  - "facebook_post"
  - "twitterX_post"
  - "instagram_post"
  - "linkedIn_post"
  - "advertisement"
  - "email_content"
  - "content_post"
  
  === /End of ContentComponent Type ===
  
  === New Type: Trigger ===
  
  Name: "Trigger": 
  Description: These objects represent triggers.  When activated, they're used to trigger a task.  For example, a visitor clicking a link, a visitor submitting a form & becoming a new contact, a visitor making a purchase, a specific date, etc.
  
  Here are the subType values for "Trigger" objects:
  - "external_event"
  - "tag"
  - "new_contact"
  - "trigger_webhook"
  - "date"
  
  === /End of Trigger Type ===
  
  === New Type: Task: ===
  
  Name: "Task": 
  Description: These objects represent tasks.  They're often used to send an email, send a text message, send a postcard, etc.
  
  Here are the subType values for "Task" objects:
  - "send_email"
  - "trigger_webhook"
  - "wait"
  
  === /End of Task Type ===
  
  If unsure what type or subType to use, just choose the one that best fits the object.
  
  IMPORTANT: Do not, under any circumstances provided any json delimiters to denote that it's json.  That will be assumed.  Just respond with the json and that's it.
  
  If the image requested is NOT a funnel marketing sequence, please respond with "This does not appear to be a funnel marketing sequence."

`;
}