import React from "react";
import { PrimaryIcon, SuccessIcon, WarningIcon, DangerIcon } from "../Icons";
import { ButtonTypes, Variants } from "../types";
import { Link } from "react-router-dom";
import { ButtonProps } from "./types";

export const Button: React.FC<ButtonProps> = ({
  type = ButtonTypes.DEFAULT,
  label = "Primary",
  variant = Variants.PRIMARY,
  defaultIcon,
  onClick = () => null,
  onMouseEnter = () => null,
  onMouseLeave = () => null,
  className = "",
  style = {},
}) => {
  // Set Flavor Classes
  let flavorClasses = "";
  let color = "indigo-300";
  switch (variant) {
    case Variants.PRIMARY:
      color = "indigo-300";
      break;
    case Variants.SECONDARY:
      color = "lightpurple-200";
      break;
    case Variants.SUCCESS:
      color = "lightgreen-100";
      break;
    case Variants.INFO:
      color = "lightblue-200";
      break;
    case Variants.WARNING:
      color = "lightyellow dark";
      break;
    case Variants.DANGER:
      color = "lightred dark";
      break;
    case Variants.SOFT:
      color = "white dark";
      break;
    case Variants.BLACK:
      color = "black dark:bg-white text-white dark:text-black font-semibold";
      break;
    default:
      break;
  }

  let disabledClasses = "disabled:pointer-events-none disabled:opacity-60";

  flavorClasses = `bg-${color} dark:bg-${color} border-${color}`;

  let typeClasses = "";
  if (type === ButtonTypes.ROUNDED) typeClasses = "rounded-full";

  if (type === ButtonTypes.OUTLINED) {
    // Render an outlined button
    return (
      <button
        type="button"
        className={`btn ${typeClasses} text-black dark:text-white border hover:bg-${color} dark:hover:bg-${color} border-${color} bg-transparent hover:text-black ${className}`}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={style}
      >
        {label}
      </button>
    );
  }

  if (
    type === ButtonTypes.WITH_LABEL ||
    type === ButtonTypes.WITH_ICON ||
    type === ButtonTypes.ICON_BUTTON
  ) {
    let icon = <></>;
    switch (variant) {
      case Variants.PRIMARY:
        icon = <PrimaryIcon />;
        break;
      case Variants.SECONDARY:
        icon = <PrimaryIcon />;
        break;
      case Variants.SUCCESS:
        icon = <SuccessIcon />;
        break;
      case Variants.INFO:
        icon = <WarningIcon />;
        break;
      case Variants.WARNING:
        icon = <WarningIcon />;
        break;
      case Variants.DANGER:
        icon = <DangerIcon />;
        break;
    }

    if (type === ButtonTypes.WITH_LABEL) {
      // Render a button with a label and an icon
      return (
        <button
          type="button"
          className={`btn inline-flex bg-${color} dark:bg-${color} p-0 align-middle text-black dark:text-black hover:bg-${color.replace(
            "-300",
            "-600"
          )} dark:hover:bg-${color.replace("-300", "-600")} ${className}`}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          style={style}
        >
          <p
            data-testid="icon"
            className="bg-white bg-opacity-20 w-10 h-10 py-3 align-middle rounded-l"
          >
            {defaultIcon || icon}
          </p>
          <span className="px-3 leading-[2.8]">{label}</span>
        </button>
      );
    }

    if (type === ButtonTypes.WITH_ICON) {
      // Render a button with an icon and a label
      return (
        <button
          type="button"
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          className={`btn inline-flex items-center bg-${color} dark:bg-${color} align-middle text-black dark:text-black hover:bg-${color.replace(
            "-300",
            "-600"
          )} dark:hover:bg-${color.replace("-300", "-600")} ${className}`}
          style={style}
        >
          <span data-testid="icon" className="mr-1">
            {defaultIcon || icon}
          </span>
          <span>{label}</span>
        </button>
      );
    }

    if (type === ButtonTypes.ICON_BUTTON) {
      // Render an icon button
      return (
        <button
          type="button"
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          className={`btn px-2 bg-${color} dark:bg-${color} text-black dark:text-black hover:bg-${color.replace(
            "-300",
            "-600"
          )} dark:hover:bg-${color.replace("-300", "-600")} ${className}`}
          style={style}
        >
          <span data-testid="icon">{icon}</span>
        </button>
      );
    }
  }

  if (type === ButtonTypes.SOFT) {
    // Render a soft button
    return (
      <button
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        // to="#"
        // className={`btn px-2 py-[5px] bg-lightred text-white text-xs ${className}`}
        className={`btn px-2 bg-black/10 text-black/70 text-xs ${className}`}
        style={style}
      >
        {label}
      </button>
    );
  }

  // Render a default button
  return (
    <button
      type="button"
      disabled={type === ButtonTypes.DISABLED}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={style}
      className={`btn ${typeClasses} text-black dark:text-black border ${flavorClasses} hover:bg-transparent hover:text-black dark:hover:bg-transparent dark:hover:text-white ${
        Boolean(type === ButtonTypes.DISABLED) && disabledClasses
      } ${className}`}
    >
      {label}
    </button>
  );
};
