import { useContext, useEffect, useState } from "react";
import moment from "moment";

import { Page, Container } from "@/ui/Layout/Page";
import { Grid } from "@/ui/Grid";
import { Card } from "@/ui/Card";
import { getCampaigns } from "@/api/campaigns";
import { ButtonTypes, Status, Variants } from "@/ui/types";
import { getSessionToken } from "@/api/auth";
import { Link } from "react-router-dom";
import Button from "@/ui/Button";
import { EmptyState } from "@/ui/Layout";
import { Loading } from "@/ui/Layout/Loading";
import { AppContext } from "@/AppContext";
import { setContextForView } from "../AITools/utils";
import { BigBanner } from "../Launchpad/components/BigBanner";
import { BrowserSize, BrowserWrapper } from "./Editor/BrowserWrapper";
import * as tours from "@/tours";

export const Pages = ({ name = "" }) => {
  const [pages, setPages] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const contextObj = useContext(AppContext);
  // get the pages from the server
  const loadPages = async () => {
    const token = getSessionToken();
    const response = await getCampaigns({ token, type: "page" });
    if (response) {
      // convert the response to the format that the Card components expect
      const pgs = response
        ?.filter(({ deleted }) => !deleted)
        .map((page: any) => {
          return {
            id: page.id,
            label: page.name,
            caption: `Last Updated: ${moment(page.updatedAt).fromNow()}`,
            img: (
              <img
                className="w-[42px] h-[42px] flex-none rounded-full overflow-hidden object-cover"
                src="https://flowbite.com/docs/images/logo.svg"
                alt=""
              />
            ), // funnel.screensnhot
            status: Status.INPROGRESS,
          };
        });

      setPages(pgs);

      setContextForView(
        {
          data: pgs.map(({ id, label, caption }) => ({
            id,
            name: label,
            caption,
          })),
          view: name,
        },
        contextObj
      );
    }
    setIsLoading(false);
  };

  // load the pages when the page loads
  useEffect(() => {
    loadPages();
  }, []);

  const image = (
    <div style={{ width: 400 }} className="p-7">
      <BrowserWrapper size={BrowserSize.SMALL} withPreview withFullScreen>
        <img
          src="https://www.launchos.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fsmart-pages2-light.4c330f97.png&w=3840&q=75"
          alt=""
          className="w-full"
        />
      </BrowserWrapper>
    </div>
  );

  return (
    <Page
      tour={tours?.pages.dashboard}
      tourKeys={{ groupKey: 'pages', pageKey: 'dashboard' }}
      topBar={{
        page: [{ label: name }],
        items: (
          <Link to="/launchpad/pages" className="justify-end">
            <Button
              label="+ Create a Landing Page"
              type={ButtonTypes.OUTLINED}
            />
          </Link>
        ),
      }}
    >
        <Container className="first-step">
          {isLoading && <Loading type="gallery" />}
          {!isLoading && !Boolean(pages.length) && (
            <div className="relative">
              <img
                src="https://www.launchos.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fsmart-pages2-light.4c330f97.png&w=3840&q=75"
                alt=""
                className="absolute inset-0 z-0 blur-xl opacity-5 w-full max-w-[60vw] left-1/2 -translate-x-1/2 translate-y-[10%]"
              />
              <div className="relative z-10">
                <EmptyState
                  title="Welcome to Launch Pages"
                  description="An easy way to create landing pages for your business."
                  showImage
                  image={image}
                  // image="https://www.launchos.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fsmart-pages2-light.4c330f97.png&w=3840&q=75"
                >
                  <Link to="/launchpad/pages">
                    <Button
                      label="Create a Page"
                      type={ButtonTypes.ROUNDED}
                      variant={Variants.BLACK}
                      className="text-black"
                    />
                  </Link>
                </EmptyState>
              </div>
            </div>
          )}
          {!isLoading && Boolean(pages.length) && (
            <>
              <BigBanner
                title="Welcome to Launch Pages"
                caption="A simple way to create landing pages for your business."
              />
              <Grid className="pb-6">
                {pages.map(
                  (
                    {
                      id,
                      label,
                      caption,
                      img = "[logo]",
                      status,
                      // sharedWith,
                      taskDetails,
                    },
                    key
                  ) => {
                    return (
                      <Card
                        key={key}
                        label={label}
                        caption={caption}
                        image={img}
                        href={`/pages/${id}`}
                        status={status}
                        // sharedWith={sharedWith}
                        taskDetails={taskDetails}
                      />
                    );
                  }
                )}
              </Grid>
            </>
          )}
        </Container>
    </Page>
  );
};
