import { useState } from "react";
import { Avatar } from "@/ui/Avatar/Avatar";
import { Tag } from "./Tag";
import { BrowserSize, BrowserWrapper } from "@/Apps/Pages/Editor/BrowserWrapper";
import { MobileState } from "@/Apps/Pages/MobileResponsiveToggle/types";
import { PopupSizes } from "@/ui/Modal/types";
import theme from "@/ui/theme";

export enum CardTypes {
  APP = "app",
  PAGE = "page",
}

interface CardProps {
  title?: string;
  backgroundImage: string;
  backgroundVideo?: string;
  price: number;
  description?: string;
  className?: string;
  customStyles?: Record<string, string>;
  tags?: string[];
  onClick?: () => void;
  children?: React.ReactNode;
  type?: CardTypes;
  size?: PopupSizes;
}

export const AppThumbnail: React.FC<CardProps> = ({
  backgroundImage,
  backgroundVideo,
  title,
  size = PopupSizes.SMALL,
}) => {
  return (
    <div
      // style={{
      //   background: `linear-gradient(to bottom right, ${
      //     ["#4F46E5", "#3B82F6", "#6366F1", "#EC4899", "#F43F5E"][
      //       Math.abs(title?.split("").reduce((acc, char) => acc + char.charCodeAt(0), 0) || 0) % 5
      //     ]
      //   }, ${
      //     ["#3B82F6", "#4F46E5", "#EC4899", "#F43F5E", "#6366F1"][
      //       Math.abs((title?.split("").reduce((acc, char) => acc + char.charCodeAt(0), 0) || 0) + 1) % 5
      //     ]
      //   })`,
      // }}
      style={{
        background: `linear-gradient(to bottom right, ${
          [theme.primaryColor, "#4F46E5", theme.secondaryColor, theme.tertiaryColor, ...theme.altColors][
            Math.abs(title?.split("").reduce((acc, char) => acc + char.charCodeAt(0), 0) || 0) % 11
          ]
        }, ${
          [theme.primaryColor, "#3B82F6", theme.secondaryColor, theme.tertiaryColor, ...theme.altColors][
            Math.abs(title?.split("").reduce((acc, char) => acc + char.charCodeAt(0), 0) || 0) % 11
          ]}88)`,
      }}
      className={`transition-colors duration-300`}
    >
      <h3 className={`text-lg text-white font-bold translate-x-7 pt-4 ${size === PopupSizes.LARGE ? "pb-8" : "pb-3"}`}>{size === PopupSizes.LARGE ? " " : title}</h3>
      <div className="overflow-hidden">
        <div
          className="object-cover w-full h-full transform translate-x-7
          shadow-xl"
        >
          <BrowserWrapper
            mobileState={{ type: MobileState.DESKTOP }}
            withUrl={true}
            withPreview={false}
            withUndoRedo={false}
            withFullScreen={false}
            withCustomButtons={false}
            size={size === PopupSizes.LARGE ? BrowserSize.MEDIUM : BrowserSize.SMALL}
          >
            {backgroundVideo ? (
              <video src={backgroundVideo} autoPlay muted loop />
            ) : (
              <img src={backgroundImage} alt={title} />
            )}
          </BrowserWrapper>
        </div>
      </div>
    </div>
  );
};

export const AppCard: React.FC<CardProps> = ({
  title = "by Ten Minute Funnels",
  backgroundImage,
  backgroundVideo,
  price,
  description = "A simple landing page for a SaaS product.",
  className = "",
  customStyles = {},
  tags = [],
  onClick = () => null,
  children,
  size = PopupSizes.SMALL,
  // type = CardTypes.PAGE,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      onClick={onClick}
      className={`cursor-pointer dark:shadow-3xl dark:bg-white/5 rounded-lg overflow-hidden mb-3 border border-black/10 dark:border-white/10 ${className}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="overflow-hidden" style={{ maxHeight: 220 }}>
        <AppThumbnail
          backgroundImage={backgroundImage}
          backgroundVideo={backgroundVideo}
          title={title}
          size={size}
        />
      </div>
      <div className="p-4">
        <Avatar
          type="Text"
          src="https://flowbite.com/docs/images/logo.svg"
          label={title}
          caption={description}
        />
      </div>
    </div>
  );
};

export const PageCard: React.FC<CardProps> = ({
  title = "by Launch OS",
  backgroundImage,
  price,
  description = "A simple landing page for a SaaS product.",
  className = "",
  customStyles = {},
  tags = [],
  onClick = () => null,
  children,
  type = CardTypes.PAGE,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const cardStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    minHeight: "180px",
    backgroundPosition: isHovered ? "center bottom" : "top",
    marginTop: isHovered ? "-5px" : "0px",
    backgroundColor: "transparent",
    ...customStyles,
  };

  return (
    <div className="mb-7" onClick={onClick}>
      <div
        className={`bg-white cursor-pointer dark:shadow-3xl dark:bg-white/5 rounded-2xl p-4 mb-3 border border-black/20 dark:border-white/10 ${className}`}
        style={cardStyle}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {price && <Tag key="price">{price}</Tag>}

        {tags.map((tag, key) => (
          <Tag key={key}>{tag.toUpperCase()}</Tag>
        ))}

        {children}
      </div>
      <Avatar
        type="Text"
        src="https://flowbite.com/docs/images/logo.svg"
        label={title}
        caption={description}
      />
    </div>
  );
};

export const Card: React.FC<CardProps> = props => {
  if (props.type === CardTypes.APP) {
    return <AppCard {...props} />;
  }

  return <PageCard {...props} />;
};
