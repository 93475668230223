import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";

import Modal from "@/ui/Modal";
import TabsComponent from "@/ui/TabsComponent";
import { getPagesInCampaign } from "../widgets/PageList";
import { TabTypes } from "@/ui/TabsComponent/types";
import { PopupSizes } from "@/ui/Modal/types";
import { cleanThisContent } from "@/Apps/Pages/Editor/FullEditor";
import { MobileState } from "@/Apps/Pages/MobileResponsiveToggle/types";
import { getSessionToken } from "@/api/auth";

import { useCallback, useContext, useEffect, useState } from "react";
import * as v2editorActions from "@/Apps/Pages/V2Editor/actions";

import Confetti from "react-confetti";

import TemplateSelectionGroup from "@/Apps/Pages/Properties/components/sections/Appearance";

import { PageContext } from "@/Apps/Pages/Page/PageContext";
import { getTokenInfo } from "@/api/auth";
import { loadFonts } from "@/Apps/Pages/Page/Preview";

import { getCampaign, updateCampaign } from "@/api/campaigns";
import { FormFieldTypes } from "@/Apps/CRM/types";
import { defaultColorPalettes } from "@/Apps/Pages/Properties/components/sections/Appearance/Appearance";
import { Loading } from "@/ui/Layout/Loading";
import { getCatalogItem, getCatalogItems } from "@/api/catalog";
import { getPage, updatePage } from "@/api/pages";

import {
  ComponentTypes,
  EditorMode,
  ISettings,
} from "@/Apps/Pages/Editor/types";

import PureCanvas from "@/Apps/Pages/V1Editor/PureCanvas";
import { setContextForView } from "@/Apps/AITools/utils";
import { debounce } from "lodash";
import { AppContext } from "@/AppContext";
import { SimpleProperties } from "./SimpleProperties";
import { PageContent } from "@/Apps/Pages/Page/Page";
import { BrowserWrapper } from "@/Apps/Pages/Editor/BrowserWrapper";
import { Calout } from "@/ui/Calout";

import { UploadGroup } from "@/Apps/Pages/Properties";
import SectionWrapper from "@/Apps/Pages/Properties/components/sections/SectionWrapper";
import { TextPromptGroup } from "@/Apps/Pages/Properties/components/groups/TextPromptGroup/TextPromptGroup";
import { ButtonExpansionGroup } from "@/Apps/Pages/Properties/components/groups/ButtonExpansionGroup/ButtonExpansionGroup";
import { EditorContext } from "@/Apps/Pages/Editor/EditorContext";
import { GET_PAGE_CAMPAIGNS } from "@/api/gql/pages";
import { client } from "@/client";
import { getPromptForAlternativeWidgetCopy } from "@/Apps/AITools/ChatBot/prompts";
import { complete } from "@/api/ai";
import Button from "@/ui/Button";
import { EditorObjectState } from "@/Apps/Pages/V2Editor/components/EditorObject/types";

const templateType = "wireframes";
const templateCategory = "home";

const ThemeProperties = ({
  campaignData = {},
  setCampaignData = () => {},
  setIsLoading = () => {},
  setContent = (content: any) => {},
  isExpanded = true,
}) => {
  // const [isChecked, setIsChecked] = useState(true);
  const [isLogoExpanded, setIsLogoExpanded] = useState(isExpanded);

  const applyTheme = async (id, content) => {
    setContent(content);

    const templates = await getCatalogItems({
      type: templateType,
      category: templateCategory,
    });

    console.log("all templates", templates);

    // 2. [ ] Loop through each campaign object
    const pageObjects = await getPagesInCampaign(campaignData);
    console.log("getPagesInCampaign", pageObjects);

    if (!pageObjects?.length) return;

    setIsLoading(true);

    for (const pageObject of pageObjects) {
      // 3. [ ] In each object, retrieve the page's content & type
      const pageContent = pageObject?.page?.content;
      const pageType = pageObject?.page?.type;

      // 4. [ ] Retrieve the templates for the theme group chosen
      const template = templates
        .filter((template) => template.tags === id)
        .find((template) => template.categories === pageType);

      // 5. [ ] Now retrieve the page content for the template that matches the page type
      const templatePageContent = template?.page?.content;

      // 6. [ ] Do a deep comparison between the template content and the page's content
      // Compare template content with page content
      const isContentMatching =
        JSON.stringify(templatePageContent) === JSON.stringify(pageContent);
      console.log("Content matching:", isContentMatching, {
        templatePageContent,
        pageContent,
      });

      // 7. [ ] If it doesn't match, update the page's content with the template's content
      if (!isContentMatching && templatePageContent) {
        // Update local state if this is the currently displayed page

        // setContent(content);

        // 8. [ ] Save the changes
        // Update the page content with template content
        const token = getSessionToken();
        await updatePage(pageObject.page.id, {
          token,
          content: templatePageContent,
        });
      }
    }

    setIsLoading(false);
  };

  const handleThemeChange = async (id, content) => {
    // 1. [ ] When theme is changed, inform the user that it will be applied to all unedited pages.
    const toContinue = window.confirm(
      "Changing the theme will update all unedited pages in this funnel. Do you want to continue?"
    );
    if (!toContinue) return;

    const newSettings = {
      settings: {
        ...campaignData?.settings,
        variables: [
          ...(campaignData?.settings?.variables || []).filter(
            (v) => v.id !== "themeId"
          ),
          {
            id: "themeId",
            label: "Theme",
            value: id,
            fieldType: FormFieldTypes.DROPDOWN,
          },
        ],
      },
    };

    setCampaignData({ ...campaignData, ...newSettings });

    const token = getSessionToken();
    const response = await updateCampaign(campaignData?.id, {
      token,
      settings: newSettings,
    });

    console.log("handleThemeChange", response);

    applyTheme(id, content);
  };

  const handleColorChange = async (id) => {
    const newSettings = {
      settings: {
        ...campaignData?.settings,
        variables: [
          ...(campaignData?.settings?.variables || []).filter(
            (v) => v.id !== "colors" && v.id !== "palette"
          ),
          {
            id: "colors",
            label: "Colors",
            value: defaultColorPalettes.find((itm) => itm.id === id)?.values,
            fieldType: FormFieldTypes.TEXT,
          },
          {
            id: "palette",
            label: "Color Palette",
            value: id,
            fieldType: FormFieldTypes.DROPDOWN,
          },
        ],
      },
    };

    console.log({ newSettings });

    setCampaignData({ ...campaignData, ...newSettings });

    const token = getSessionToken();
    const response = await updateCampaign(campaignData?.id, {
      token,
      settings: newSettings,
    });

    console.log("handleColorChange", response);
  };

  const handleLogoChange = async (url) => {
    console.log("url", url);

    const newSettings = {
      settings: {
        ...campaignData?.settings,
        variables: [
          ...(campaignData?.settings?.variables || []).filter(
            (v) => v.id !== "logo"
          ),
          {
            id: "logo",
            label: "Logo",
            value: url,
            fieldType: FormFieldTypes.TEXT,
          },
        ],
      },
    };

    setCampaignData({ ...campaignData, ...newSettings });

    const token = getSessionToken();

    const response = await updateCampaign(campaignData?.id, {
      token,
      settings: newSettings,
    });

    console.log("handleLogoChange", response);
  };

  const handleCompanyNameChange = async (companyName) => {
    const newSettings = {
      settings: {
        ...campaignData?.settings,
        variables: [
          ...(campaignData?.settings?.variables || []).filter(
            (v) => v.id !== "companyName"
          ),
          {
            id: "companyName",
            label: "Company Name",
            value: companyName,
            fieldType: FormFieldTypes.TEXT,
          },
        ],
      },
    };

    setCampaignData({ ...campaignData, ...newSettings });

    const token = getSessionToken();

    const response = await updateCampaign(campaignData?.id, {
      token,
      settings: newSettings,
    });

    console.log("handleCompanyNameChange", response);
  };

  return (
    <>
      {/* <SwitchGroup
        label="Enable this page"
        isChecked={isChecked}
        onClick={() => setIsChecked(!isChecked)}
        className="p-5"
      >
        <p>Display this page or hide it and redirect to your website.</p>
      </SwitchGroup> */}

      {/* Template */}
      <div data-testid="TemplateSelectionGroup">
        <TemplateSelectionGroup
          type={templateType}
          isExpanded={isExpanded}
          filter={{ category: templateCategory }}
          payload={campaignData?.settings?.variables || []}
          onThemeChange={handleThemeChange}
          onColorChange={handleColorChange}
        />
      </div>

      {/* About You */}
      <SectionWrapper label="About You" isExpanded={isExpanded}>
        <TextPromptGroup
          label="Company Name"
          value={
            campaignData?.settings?.variables?.find(
              (v) => v.id === "companyName"
            )?.value
          }
          onChange={handleCompanyNameChange}
        />

        <ButtonExpansionGroup
          label="Your Logo"
          value="Upload Logo"
          isExpanded={isLogoExpanded}
          onClick={() => {
            setIsLogoExpanded(!isLogoExpanded);
          }}
        >
          <UploadGroup
            sectionWrap={false}
            createMediaItem={() => {}}
            uploadURLToS3={() => {}}
            settings={{}}
            url={
              campaignData?.settings?.variables?.find((v) => v.id === "logo")
                ?.value || ""
            }
            accept="image/jpeg, image/png"
            highlighted
            onChange={(files: any[]) => handleLogoChange(files?.[0]?.preview)}
            isExpanded
            label="Logo"
          />
        </ButtonExpansionGroup>
      </SectionWrapper>
    </>
  );
};

export const SimpleBuilder = ({
  title = "Page Editor",
  pageId = "theme",
  campaignData = {},
  widgets = [],
  showAsModal = true,
  setCampaignData = (data: { settings: any }) => {},
  onClose = () => {},
}) => {
  const [activeTab, setActiveTab] = useState(pageId);
  const [isLoading, setIsLoading] = useState(false);
  const [mobileState, setMobileState] = useState<{ type: MobileState }>({
    type: MobileState.FULLSCREEN,
  });

  const [confettiIsShowing, setConfettiIsShowing] = useState(false);
  const [systemPrompt, setSystemPrompt] = useState("");

  const [user, setUser] = useState(false);
  const [content, setContent] = useState([]);
  const [activeSettings, setActiveSettings] = useState({});
  const [pageCampaigns, setPageCampaigns] = useState([]);
  const token = getSessionToken();

  const contextObj = useContext(AppContext);

  const mode = EditorMode.SIMPLE;

  const loadChosenTheme = async () => {
    // get chosen theme's content
    const themeId = campaignData?.settings?.variables?.find(
      ({ id }) => id === "themeId"
    )?.value;

    if (themeId) {
      const theme = await getCatalogItem(themeId);
      const theContent = theme?.page?.content;

      // const theContent = campaignData?.objects[0].page?.content;
      setContent(theContent);
      loadFonts(theContent);

      console.log({ theContent });
    }
  };

  const handleTabChange = async (tab) => {
    const token = getSessionToken();
    const user = await getTokenInfo(token);
    setUser(user);

    setActiveTab(tab);

    console.log("Switching tabs", tab);

    if (tab !== "theme") {
      setIsLoading(true);

      const { data } = await getPage(tab, { token });

      setContent(cleanThisContent(data?.content));
      loadFonts(data?.content);

      // console.log("just set the content with", data?.content);

      // Wait 3 seconds before trying to generate page
      // await new Promise((resolve) => setTimeout(resolve, 3000));
      handleGeneratePage(cleanThisContent(data?.content));

      setIsLoading(false);
    } else {
      loadChosenTheme();
    }
  };

  const updateCompletedItems = async (items) => {
    const campaignSettings = {
      ...campaignData?.settings,
      completedItems: items,
    };

    const token = getSessionToken();
    const response = await updateCampaign(campaignData?.id, {
      token,
      settings: { settings: campaignSettings },
    });

    console.log("updateCompletedItems", response);

    setCampaignData({ ...campaignData, settings: campaignSettings });
  };

  const updateEditorContent = (pageId, newContent) => {
    setContent(newContent); // update the conteont
    debouncedHandleSave({ content: newContent });

    // console.log("updateEditorContent", newContent); // <-- gets here
  };

  const updateEditorComponent = (componentId, newComponent) => {
    const changedContent = v2editorActions.updateComponentSettings(
      content,
      componentId,
      newComponent
    );

    console.log("updateEditorComponent", newComponent, changedContent);
    updateEditorContent(activeTab, changedContent);
  };

  const updateEditorComponentStyle = (componentId, newStyle) => {
    // console.log("updateEditorComponentStyle", newStyle);
    const newComponent = v2editorActions.updateComponentStyle(
      content,
      componentId,
      newStyle
    );

    // console.log("updateEditorComponentStyle", newComponent);
    updateEditorComponent(componentId, newComponent);
  };

  const handleSave = async ({ content = [] }: { content: ISettings[] }) => {
    const cleanedContent = cleanThisContent(content);

    console.log("handleSave", content); // <-- fails here

    try {
      // purposefully not doing await, because saves happen in the background
      updatePage(activeTab, {
        token,
        settings: { content: cleanedContent },
      });

      console.log("Saving Page", activeTab, { content });

      setContextForView(
        { data: cleanedContent, view: "Page Content" },
        contextObj
      );
    } catch (error) {
      console.error("Failed to save page:", error);
      // Implement user feedback for save failure
    }
  };

  const handleChange = ({ content = [] }) => {
    setContent(content);
    // debouncedHandleSave({ content });
  };

  const handleItemSelect = (id, settings) => {
    console.log("handleItemSelect", id, settings);
    const item = content.find((itm) => itm.id === settings?.id);
    setActiveSettings(item);
  };

  const debouncedHandleSave = useCallback(
    debounce(({ content }) => handleSave({ content }), 3000),
    []
  );

  const loadPages = async () => {
    const token = getSessionToken();
    // const response = await getCampaigns({ token, type: "page" });
    // console.log("pages", response);
    const user = await getTokenInfo(token);

    const pages = await client.query({
      query: GET_PAGE_CAMPAIGNS,
      variables: { userId: user?.id },
    });

    console.log("pages", pages?.data?.campaigns);

    const pageCampaignData = {
      id: campaignData?.id,
      objects: pages?.data?.campaigns
        ?.filter(({ objects }) => objects[0])
        .map(({ objects }) => objects[0]),
    };

    console.log("pageCampaignData", pageCampaignData);

    setPageCampaigns(pageCampaignData);

    // Set the System Prompt

    // @ts-ignore
    // const theSystemPrompt = await getSystemPrompt({
    //   pageSettings: '', // getPageSettings(),
    //   userDetails: user,
    //   assistant: { id: "copywriting" },
    //   type: "completion",
    // });

    // setSystemPrompt(
    //   Object.keys(theSystemPrompt)
    //     .map((key) => theSystemPrompt[key])
    //     .join("\n\n")
    // );
  };

  const cleanUpHtml = (html) => {
    return html.replace(/\[|\]/g, "");
  };

  const handleProgressiveReveal = (content = [], callback = () => {}) => {
    // First, hide all content items
    const newContent = content.map((item: any) => ({
      ...item,
      state: EditorObjectState.HIDDEN,
    }));

    // Update the state with all hidden items
    setContent(newContent as any);

    // Then reveal each item one by one
    newContent.forEach((item: any, index: number) => {
      setTimeout(() => {
        setContent((prevContent: any[]) =>
          prevContent.map((contentItem: any, i: number) => {
            if (i === index) {
              return {
                ...contentItem,
                state:
                  contentItem.type !== ComponentTypes.TEXT &&
                  contentItem.type !== ComponentTypes.HEADLINE
                    ? EditorObjectState.NORMAL
                    : EditorObjectState.HIDDEN,
              };
            }
            return contentItem;
          })
        );
      }, index * 80); // Reveal each item with a 80ms delay
    });

    // When it's all done, execute the callback
    setTimeout(
      () => {
        if (typeof callback === "function") callback(content);
      },
      newContent.length * 80 + 100
    ); // Add a little extra time to ensure all items have been processed
  };

  const handleAlternativePageCopyLocally = async (
    incomingContent,
    chatMessages
  ) => {
    // const contentToUse = content;
    const contentToUse = incomingContent || content;

    // const token = getSessionToken();
    // const { data } = await getPage(pageId, { token });

    const textObjects = contentToUse.filter(
      (settings) =>
        settings?.type === ComponentTypes.TEXT ||
        settings?.type === ComponentTypes.PARAGRAPH ||
        settings?.type === ComponentTypes.HEADLINE ||
        settings?.type === ComponentTypes.V1BUTTON
    );

    // Set all text objects to have a hidden state initially
    // if (contentToUse) {
    const hiddenTextContent = contentToUse.map((item) => {
      if (textObjects.some((textObj: any) => textObj.id === item.id)) {
        return {
          ...item,
          state: EditorObjectState.HIDDEN,
        };
      }
      return item;
    });

    console.log("hiddenTextContent", hiddenTextContent);
    setContent(hiddenTextContent);
    // }

    // console.log("textObjects", textObjects);
    console.log("chatMessages", chatMessages);

    let newContent = hiddenTextContent;

    // update the system prompt with the chat messages
    const updatedSystemPrompt = `
      ${systemPrompt}

      Here are the chat messages so far.  When you fulfill the users request, consider the following as their original request & content talking points:
      ${JSON.stringify(chatMessages)}

      Here is the users request, which will include the text you need to rewrite:
    `;

    for (const itm of textObjects) {
      const prompt = getPromptForAlternativeWidgetCopy(
        itm.type,
        itm.html,
        "improve"
      );
      const response = await complete(prompt, updatedSystemPrompt);

      newContent = newContent.map((item) => {
        if (item.id === itm.id) {
          return {
            ...item,
            html: cleanUpHtml(response),
            state: EditorObjectState.NORMAL,
            revealContent: true,
          };
        }
        return item;
      });

      setContent(newContent);
    }

    console.log("newContent", newContent);
    setConfettiIsShowing(true);

    // Reset revealContent to false after animation is complete
    const resetContent = newContent.map((item) => {
      if (textObjects.some((textObj) => textObj.id === item.id)) {
        return { ...item, revealContent: false };
      }
      return item;
    });

    setTimeout(() => {
      setContent(resetContent);
    }, 3000); // Wait for animations to complete

    handleSave({ content: resetContent });
  };

  const handleGeneratePage = async (content) => {
    if (campaignData?.settings?.withAI) {
      const token = getSessionToken();

      // get the chat content
      const chatSettings = campaignData?.settings?.chatSettings;
      const chatCampaign = await getCampaign({
        token,
        id: chatSettings?.chatSessionId,
      });
      const chatMessages = chatCampaign?.objects[0]?.settings?.messages;

      console.log("Will attempt to generate page");
      await handleProgressiveReveal(content, () => {
        handleAlternativePageCopyLocally(content, chatMessages);
      });

      // update the campaign settings
      const newCampaignSettings = {
        ...campaignData?.settings,
        withAI: false,
      };
      const response = await updateCampaign(campaignData?.id, {
        token,
        settings: {
          settings: newCampaignSettings,
        },
      });
      console.log("updateCampaign Response", response);
      setCampaignData({
        ...campaignData,
        settings: newCampaignSettings,
      });
    }
  };

  useEffect(() => {
    // go through and get all the text & headline content
    const textObjects = content.filter(
      (settings) =>
        settings?.type === ComponentTypes.TEXT ||
        settings?.type === ComponentTypes.PARAGRAPH ||
        settings?.type === ComponentTypes.HEADLINE ||
        settings?.type === ComponentTypes.V1BUTTON
    );

    const textContent = textObjects.map((itm) => itm.html).join("\n\n");

    const systemPrompt = `
You are an expert copywriter.  You will be asked to rewrite a block of text, that is part of a landing page.  Your job is to rewrite the text in a way that is more engaging and persuasive.

Here is the landing page content:
${textContent}

    `;
    // console.log("systemPrompt", systemPrompt);
    setSystemPrompt(systemPrompt);
  }, [content]);

  useEffect(() => {
    const initializeBuilder = async () => {
      await loadPages();
      await handleTabChange(pageId);
      console.log("Mounted!");
    };

    initializeBuilder();
  }, [pageId]); // Add pageId as dependency

  if (!campaignData || !user) return null;

  const pageTabs = [
    { label: "Overview", value: "theme" },
    ...getPagesInCampaign(campaignData || { id: "", objects: [] }).map(
      (object) => ({
        label: object?.name,
        value: object?.page.id,
      })
    ),
  ];

  const children = (
    <EditorContext.Provider
      value={{
        pageId,
        objectId: activeTab, // not sure if this is correct
        // objectId: campaignData?.objects[0]?.id,
        pageCampaigns,
        mode,
        campaignId: campaignData?.id,
        content,
        mobileState: mobileState.type,
      }}
    >
      <PageContext.Provider
        value={{
          activeContent: content,
          setContent,
          activeSettings,
          campaignSettings: campaignData?.settings,
          parseLiquid: true,
          updateCompletedItems,
          completedItems: campaignData?.settings?.completedItems || [],
          mobileState: mobileState.type,
          setMobileState: (state) => setMobileState({ type: state }),
        }}
      >
        <div className="w-full h-full flex">
          {confettiIsShowing && (
            <Confetti
              // width={width}
              // height={height}
              recycle={false}
              onConfettiComplete={() => setConfettiIsShowing(false)}
            />
          )}
          {/* Properties */}
          <div className="w-[500px] z-10 divide-y divide-black/10 dark:divide-white/10 border-r border-black/10 dark:border-white/10 h-full ml-2 overflow-y-auto">
            {activeTab === "theme" ? (
              <ThemeProperties
                campaignData={campaignData}
                setCampaignData={setCampaignData}
                setIsLoading={setIsLoading}
                setContent={setContent}
              />
            ) : (
              <>
                <ThemeProperties
                  campaignData={campaignData}
                  isExpanded={false}
                  setCampaignData={setCampaignData}
                  setIsLoading={setIsLoading}
                  setContent={setContent}
                />
                <SimpleProperties
                  campaignData={campaignData}
                  pageId={activeTab}
                  content={content}
                  setCampaignData={setCampaignData}
                  setIsLoading={setIsLoading}
                  setContent={setContent}
                  updateEditorContent={updateEditorContent}
                  updateEditorComponent={updateEditorComponent}
                  updateEditorComponentStyle={updateEditorComponentStyle}
                />
              </>
            )}
          </div>

          {/* Preview */}
          {activeTab === "theme" && content && (
            <div className="h-full w-full overflow-auto bg-black/5">
              <div className="px-9 py-6 flex-col" key={content[1]?.id}>
                <Calout title="Let's choose a theme for your funnel" />
                <div className="my-3" />
                {/* {JSON.stringify(content)} */}
                <BrowserWrapper
                  url={`https://${campaignData?.domain?.name}`}
                  mobileState={{ type: MobileState.DESKTOP }}
                >
                  <PageContent
                    // id={campaignData?.objects[0].page?.id}
                    id="theme"
                    content={JSON.stringify(content)}
                    nextPage="#"
                    getPageData={() => content}
                    trackingPayload=""
                    seoPayload=""
                    merchantPayload=""
                    emailPayload=""
                    cssPayload=""
                    exitPayload=""
                    // objectId={campaignData?.objects[0]?.id}
                    campaignId={campaignData?.id}
                    userId={user?.id}
                  />
                </BrowserWrapper>
              </div>
            </div>
          )}

          {/* Editor */}
          {activeTab !== "theme" && content && (
            <div
              className="h-full w-full overflow-auto bg-black/5 p-9"
              // key={Math.random().toString(36).slice(2)}
            >
              <BrowserWrapper mobileState={{ type: MobileState.DESKTOP }}>
                <DndProvider backend={HTML5Backend}>
                  {/* <Button
                    label="Rewrite Page"
                    onClick={handleAlternativePageCopyLocally}
                  />
                  <Button
                    label="Progressive Reveal"
                    onClick={() => handleProgressiveReveal(content)}
                  />
                  <Button
                    label="Both"
                    onClick={() => {
                      handleProgressiveReveal(content, (newContent) => {
                        handleAlternativePageCopyLocally(newContent);
                      });
                    }}
                  /> */}
                  <PureCanvas
                    content={content}
                    onChange={handleChange}
                    onItemSelect={handleItemSelect}
                    // onSave={handleSave}
                    disableDefaultProperties
                    setActiveAddNewTab={() => {}}
                    mobileState={{ type: MobileState.FULLSCREEN }}
                    setMobileState={() => MobileState.FULLSCREEN}
                    onLoad={async () => {
                      // // after one second, check if the object settings has the withAI flag
                      // // if it does, then call the handleAlternativePageCopyLocally function
                      // // then remove the withAI flag from the object settings
                      // const token = getSessionToken();
                      // const { data } = await getObject(activeTab);
                      // console.log("The Editor has loaded!", data?.object);
                      // // setPageIsLoaded(true);
                      // if (data?.object?.settings?.withAI) {
                      //   await handleAlternativePageCopyLocally();
                      //   const r = await updateObject(activeTab, {
                      //     token,
                      //     ...data?.object?.settings,
                      //     settings: {
                      //       ...data?.object?.settings?.settings,
                      //       withAI: false,
                      //     },
                      //   });
                      //   console.log("updateObject Response", r);
                      // }
                    }}
                    mode={mode}
                  />
                </DndProvider>
              </BrowserWrapper>
            </div>
          )}
        </div>
      </PageContext.Provider>
    </EditorContext.Provider>
  );

  if (!showAsModal) return children;

  return (
    <Modal
      title={<p className="px-3">{title}</p>}
      tabs={
        <div className="max-w-3xl mx-auto">
          <TabsComponent
            tabs={pageTabs}
            activeTab={activeTab}
            setActiveTab={handleTabChange}
            actionSection={false}
            className="p-0"
            type={TabTypes.BUTTON}
          />
        </div>
      }
      size={PopupSizes.FULLSCREEN}
      onClose={onClose}
      footer={false}
      childrenStyle={{ padding: 0 }}
    >
      {isLoading ? (
        <div className="w-full h-full p-20">
          <Loading type="gallery" />
        </div>
      ) : (
        children
      )}
    </Modal>
  );
};
