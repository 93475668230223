import { OptionProps } from "./OptionGroup/types";

export enum Status {
  INPROGRESS = "In Progress",
  PENDING = "Pending",
  COMPLETED = "Completed",
}


export enum Variants {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  SUCCESS = "success",
  INFO = "info",
  WARNING = "warning",
  DANGER = "danger",
  DISABLED = "disabled",
  SOFT = "soft",
  BLACK = "black",
}

export enum ButtonTypes {
  DEFAULT,
  ROUNDED,
  OUTLINED,
  DISABLED,
  WITH_LABEL,
  WITH_ICON,
  ICON_BUTTON,
  SOFT,
}

export enum DropDownTypes {
  BASIC,
}

export interface BigOptionGroupProps {
  options: OptionProps[];
  selectedOption: string;
  onChange: (option: string) => void;
  className?: string;
}
