import { Link } from "react-router-dom";
import { Button } from "@/ui/Button";
import { ButtonTypes } from "@/ui/types";

export const BigBanner = ({
  title = "Choose Your Plan",
  caption = "Simple pricing. No hidden fees. Advanced features for you business.",
  // backgroundImage = "../images/pricing-bg.svg",
  backgroundImage = "../images/pricing-bg.svg",
  children,
}) => {

  return (
    <div
      className={`text-center relative mb-7 bg-cover rounded-2xl py-16 bg-black overflow-hidden`}
      // className={`text-center relative mb-7 bg-[url(${backgroundImage})] bg-cover rounded-2xl py-16 bg-black overflow-hidden`}
    >
      <div className="relative z-10">
        <h1 className="text-5xl font-semibold text-white mb-4">{title}</h1>
        <p className="text-md text-white mb-7">{caption}</p>
        {children}
      </div>
      <div className="w-full h-full absolute inset-0 z-0">
        <video
          src="https://files-us-east-1.t-cdn.net/files/MBdMHAKgL4tLCgFDGjA39"
          autoPlay
          loop
          playsInline
          className="w-full h-full object-cover object-center"
        >
          Sorry, your browser doesn't support embedded videos!
        </video>
        <div
          className="w-full h-full absolute inset-0"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}
        ></div>
      </div>
    </div>
  );
};

export const BannerButton = ({ label, href = "#" }) => {
  return (
    <Link to={href}>
      <Button
        className="w-full"
        style={{ backgroundColor: "rgb(66,66,66,0.9)", color: "white" }}
        label={label}
        type={ButtonTypes.SOFT}
      />
    </Link>
  );
};
