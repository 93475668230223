import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { cloneCampaign } from "@/api/campaigns";
import { getSessionToken, getTokenInfo } from "@/api/auth";
import { ItemGroup } from "./components/ItemGroup";

import Avatar from "@/ui/Avatar";

import { CampaignBuilder } from "../Funnels";
import { PopupSizes } from "@/ui/Modal/types";
import Button from "@/ui/Button";
import { ButtonTypes } from "@/ui/types";
import { Tag } from "./components/Tag";
import Modal from "@/ui/Modal";
import { Sparkle } from "@phosphor-icons/react";
import { Loading } from "@/ui/Layout/Loading";
import { getCatalogItems } from "@/api/catalog";
import { AppContext } from "@/AppContext";
import { updateUserData, getUserPermissions } from "@/api/users";
import { AppThumbnail, CardTypes } from "./components/Card";
import { MobileState } from "../Pages/MobileResponsiveToggle/types";
import { BrowserSize } from "../Pages/Editor/BrowserWrapper";
import { BrowserWrapper } from "../Pages/Editor/BrowserWrapper";

interface AppItemDetailsProps {
  // campaignId: string;
  title?: string;
  description?: string;
  categories?: string[];
  permissions?: string[];
  ownerDetails?: { src: string; label: string; caption: string };
  onClose?: () => void;
}

export const AppItemDetails: React.FC<AppItemDetailsProps> = ({
  // campaignId,
  // image = "https://sandcastleassets.s3.amazonaws.com/templates/pageid/ckxq3zol5904z08119y28wgkw.png",
  title = "Funnel Framework",
  description = "This is a funnel framework that you can use to build your own funnel. It's a great starting point for your funnel and you can customize it however you want.",
  categories = ["Sales", "Sales", "Sales", "Sales"],
  ownerDetails = {
    src: "https://flowbite.com/docs/images/logo.svg",
    label: "By Launch OS",
    caption: "support@launchos.com",
  },
  permissions = [],
  onClose = () => false,
  backgroundImage,
  backgroundVideo,
}) => {
  const [hasApp, setHasApp] = useState(false);
  const context = useContext(AppContext);

  useEffect(() => {
    (async () => {
      // get user permissions
      // const token = localStorage.getItem("token");
      // const permissions = await getUserPermissions({ token });

      if (permissions) {
        // determine if any of the permissions coming in from the props are in the user's permissions
        const hasPermission = permissions.some((permission: string) =>
          context.permissions.includes(permission)
        );
        // console.log("context", context, permissions, { hasPermission });
        setHasApp(hasPermission);
      }
    })();
  }, []);

  const handleAppInstall = async () => {
    const confirm = window.confirm(
      "Are you sure you want to install this app?"
    );
    if (confirm) {
      const token = getSessionToken();
      const { id } = await getTokenInfo(token);
      const response = await updateUserData(id, {
        token,
        data: { permissions: [...context.permissions, ...permissions] },
      });
      if (response) {
        context.setPermissions([...context.permissions, ...permissions]);
        setHasApp(true);
        onClose();
        alert("App installed successfully");
      }
    }
  };

  const handleAppUninstall = async () => {
    const confirm = window.confirm(
      "Are you sure you want to uninstall this app?"
    );
    if (confirm) {
      const token = getSessionToken();
      const { id } = await getTokenInfo(token);
      const response = await updateUserData(id, {
        token,
        data: {
          permissions: context.permissions.filter(
            (permission) => !permissions.includes(permission)
          ),
        },
      });
      if (response) {
        context.setPermissions(
          context.permissions.filter(
            (permission) => !permissions.includes(permission)
          )
        );
        setHasApp(false);
        onClose();
        alert("App uninstalled successfully");
      }
    }
  };

  return (
    <Modal footer={false} size={PopupSizes.XXXLARGE} onClose={onClose}>
      <div className="grid md:grid-cols-12 gap-7 px-3 w-full">
        <div className="flex flex-col gap-3 md:col-span-4 dark:bg-white/5 rounded-lg p-7">
          <div className="rounded-lg overflow-hidden flex flex-col md:col-span-8">
            {/* <div className="rounded-lg overflow-hidden"> */}
            <h1 className="text-2xl font-bold mb-4">{title}</h1>
            <p
              className="text-md mb-2"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
          {/* <div className="flex-1 flex-col dark:bg-white/5 rounded-lg p-7 w-2/12"> */}
          {!hasApp ? (
            <Button
              label="Install this App"
              type={ButtonTypes.DEFAULT}
              className="w-full"
              onClick={handleAppInstall}
            />
          ) : (
            <Button
              label="Uninstall this App"
              type={ButtonTypes.DEFAULT}
              className="w-full"
              onClick={handleAppUninstall}
            />
          )}

          {Boolean(categories.length) && (
            <>
              <div className="h-px dark:bg-white/5 bg-black/5 block my-8"></div>
              <h2 className="text-lg font-bold mb-2">Tags</h2>
              <div className="mb-7">
                {categories.map((category, key) => (
                  <Tag key={key}>{category.toUpperCase()}</Tag>
                ))}
              </div>
            </>
          )}

          {/* divider */}
          <div className="h-px dark:bg-white/5 bg-black/5 block my-8"></div>

          <div className="mb-7">
            <Avatar type="Text" {...ownerDetails} />
          </div>

          <p className="text-xs text-black/40 dark:text-white/40">
            Last updated 6 months ago
          </p>
          <p className="text-xs text-black/40 dark:text-white/40">
            support@tenminutefunnels.com
          </p>
        </div>
        <div className="rounded-lg overflow-hidden md:col-span-8 mb-7 border dark:border-white/5">
          <AppThumbnail
            backgroundImage={backgroundImage}
            backgroundVideo={backgroundVideo}
            title={title}
            size={PopupSizes.LARGE}
          />
        </div>
      </div>
    </Modal>
  );
};

interface AppGroupProps {
  type?: string;
  showAll?: boolean;
  tagFilter?: string[];
}
export const AppGroup: React.FC<AppGroupProps> = ({
  type,
  showAll = false,
}) => {
  const [appCampaigns, setAppCampaigns] = useState<any[]>([]);
  const [appItemDetailsIsShowing, setAppItemDetailsIsShowing] = useState(false);
  const [activeCatalogItem, setActiveCatalogItem] = useState(null);

  const loadCatalogCampaigns = async () => {
    const items = await getCatalogItems({ type: "apps" });
    setAppCampaigns(items);
  };

  const { appId } = useParams();

  useEffect(() => {
    loadCatalogCampaigns();
  }, []);

  useEffect(() => {
    if (appId && appCampaigns.length) {
      setActiveCatalogItem(appCampaigns.find((item) => item.id === appId));
      setAppItemDetailsIsShowing(true);
    }
  }, [appId, appCampaigns]);

  if (!appCampaigns.length) return <Loading type="gallery" className="py-8" />;

  return (
    <>
      {appItemDetailsIsShowing && (
        <AppItemDetails
          backgroundImage={activeCatalogItem?.image}
          backgroundVideo={activeCatalogItem?.video}
          title={activeCatalogItem?.name}
          description={activeCatalogItem?.description}
          categories={activeCatalogItem?.categories?.split(",") || []}
          permissions={activeCatalogItem?.permissions || []}
          onClose={() => setAppItemDetailsIsShowing(false)}
        />
      )}
      <ItemGroup
        title={type.charAt(0).toUpperCase() + type.slice(1)}
        {...(!showAll && { amountToShow: 3 })}
        items={appCampaigns.map((item) => ({
          ...item,
          // categories: "Coming Soon, Preview Now",
        }))}
        customCardStyles={{
          backgroundSize: "contain",
          transition: "margin-top 0.25s ease",
        }}
        cardType={CardTypes.APP}
        href={`/launchpad/${type}`}
        onItemClick={(item) => {
          setActiveCatalogItem(item);
          setAppItemDetailsIsShowing(true);
        }}
      />
    </>
  );
};
