import { useChat } from "ai/react";
import { head } from "lodash";
import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

import { AI_DOMAIN } from "@launchos/shared-utils/env";

import Avatar from "@/ui/Avatar";
import ChatConversation from "@/ui/CommunicationView/ChatConversation";
import { Loading } from "@/ui/Layout/Loading";

import { getSessionToken, getTokenInfo, isLoggedIn } from "@/api/auth";
import { complete, createOrUpdateChat } from "@/api/ai";
import {
  createCampaignAndObject,
  getObjects,
  updateObject,
} from "@/api/campaigns";

import { triggerAsyncHook, triggerHook } from "@/plugins/client";
import { CopilotHooks, HookTriggers } from "@/plugins/types";

import { AppContext } from "@/AppContext";

import { ArtifactOptions, ChatBotProps } from "../types";
import { OptinPopup } from "./OptinPopup";

export const ChatBot = forwardRef<HTMLDivElement, ChatBotProps>(
  (
    {
      pageSettings = {},
      placeholder,
      // showHeader = true,
      title,
      subtitle,
      suggestions = [],
      sessionId,
      setCampaignId = () => {},
      setCampaignName = () => {},

      // artifactOptions = {},
      setArtifactOptions = () => {},
      createOrUpdate = true,
      endPoint = `${AI_DOMAIN}/api/copilot`,
      systemPromptOverride,
      children = <></>,
    }: ChatBotProps,
    ref
  ) => {
    const [systemPrompt, setSystemPrompt] = useState<string>("");
    const [useTheseTools, setUseTheseTools] = useState([]);
    const [userDetails, setUserDetails] = useState<any>({});
    const [autoScrollingIsEnabled, setAutoScrollingIsEnabled] = useState(true);
    const [pageIsLoading, setPageIsLoading] = useState<boolean>(false);
    const [context, setContext] = useState([]);
    const [optInPopupIsShowing, setOptInPopupIsShowing] = useState(false);

    const [
      shouldOpenArtifactWithThisOnFinish,
      setShouldOpenArtifactWithThisOnFinish,
    ] = useState<ArtifactOptions>();

    const contextObj = useContext(AppContext);
    const { copilotContext, setCopilotContext, currentlyViewing } = contextObj;

    // const getArtifactMessage = () => shouldOpenArtifactWithThisOnFinish;

    const {
      messages,
      input,
      append,
      handleInputChange,
      handleSubmit,
      setMessages,
      isLoading,
    } = useChat({
      api: endPoint,
      body: {
        type: "chat",
        systemPrompt: systemPromptOverride || systemPrompt,
        tools: useTheseTools,
      },
      onResponse: () => setAutoScrollingIsEnabled(true),
      onFinish: async (message) => {
        if (createOrUpdate && typeof createOrUpdateChat === "function") {
          console.log("input", input);
          const campaign = await createOrUpdateChat({
            lastMessage: message,
            messages,
            input: input || copilotContext?.query,
            sessionId,
          });
          
          // clear the query so it doesn't get processed again
          setCopilotContext({ ...copilotContext, query: null });

          if (campaign) {
            // returns null if update was called
            setCampaignId(campaign?.id);
            setCampaignName(campaign?.name);
          }


        }
        
        
        console.log(
          "Finished rendering the message",
          shouldOpenArtifactWithThisOnFinish
        );
        if (shouldOpenArtifactWithThisOnFinish) {
          console.log(
            "Ok, opening the artifact container now with",
            shouldOpenArtifactWithThisOnFinish
          );
          setArtifactOptions({
            ...shouldOpenArtifactWithThisOnFinish,
            isShowing: true,
          });
        }
      },
    });

    useEffect(() => {
      const wn = document.getElementById("chat-conversation-container");
      if (wn && autoScrollingIsEnabled)
        wn.scrollTo({ top: wn.scrollHeight /*, behavior: "smooth" */ });
      // if (messages.length > 0) {
      //   console.log("Last message:", messages[messages.length - 1]?.content);
      // }
      setMessages(messages);
    }, [messages]);

    useEffect(() => {
      (async () => {
        const token = getSessionToken();
        const user = await getTokenInfo(token);
        setUserDetails(user);
      })();
    }, [pageSettings]);

    useEffect(() => {
      if (sessionId) loadChatSession(sessionId);
      else setMessages([]);
    }, [sessionId]);

    useEffect(() => {
      setContext(currentlyViewing);
    }, [currentlyViewing]);

    // the way we can auto-trigger the chatbot
    useEffect(() => {
      if (copilotContext && copilotContext?.query) {
        const { query } = copilotContext;

        console.log("new query to process", query);

        append({ role: "user", content: query });
      }
    }, []);
    // }, [copilotContext?.query]);

    const chatMessages = messages.map((msg) => {
      const icon =
        msg.role === "user" ? (
          <div className="mr-4">
            <Avatar
              type="Rounded"
              email={userDetails?.email}
              height="8"
              width="8"
            />
          </div>
        ) : (
          <div className="h-8 w-8 -my-1 rounded-full mr-4">
            <img src="https://flowbite.com/docs/images/logo.svg" />
          </div>
        );
      return {
        ...msg,
        id: msg.id,
        type: "text",
        caption: msg.content,
        from: msg.role === "user" ? "User" : "Bot",
        icon,
      };
    });

    const handleInputKeyDown = (e) => {
      // If Shift+Enter is pressed, add a new line instead of submitting
      if (e.shiftKey && e.key === "Enter") return null;

      // If Enter is pressed, go ahead & submit the form
      if (e.key === "Enter") {
        e.preventDefault();
        if (!isLoading) {
          console.log("Should submit now!!");
          doFormSubmission(e);
        }
      }
    };

    const loadChatSession = async (id) => {
      setPageIsLoading(true);
      const response = await getObjects(id);
      const object = head(response?.data?.objects);
      const { settings = {} } = object;
      const { messages = [] } = settings;
      console.log({ object, messages });
      setMessages(messages);
      setPageIsLoading(false);
    };

    const doFormSubmission = async (e) => {
      e.preventDefault();
      e.stopPropagation();
      console.log("About to submit", e);

      const isTheUserLoggedIn = await isLoggedIn();
      if (!isTheUserLoggedIn) {
        // alert("Please log in!");
        setOptInPopupIsShowing(true);
        return null;
      }

      const newSystemPrompt: string[] = triggerHook(
        HookTriggers.onChatPrompt,
        {
          id: CopilotHooks.GET_SYSTEM_PROMPT,
        },
        { context },
        contextObj,
        {}
      );

      setSystemPrompt(newSystemPrompt.join("\n\n"));

      const toolsToUse =
        triggerHook(
          HookTriggers.onChatPrompt,
          {
            id: CopilotHooks.GET_TOOLS,
          },
          { context },
          contextObj,
          {}
        ) || [];

      console.log("Assigning these tools to use", toolsToUse);

      setUseTheseTools(toolsToUse);

      console.log(
        "Submitting now",
        "System Prompt:",
        newSystemPrompt.join("\n\n")
      );
      handleSubmit(e);
    };

    // Expose the ability to submit new chat messages from outside of the component hierarchy
    useImperativeHandle(ref, () => ({
      append,
      createOrUpdateChat,
    }));

    return (
      <div className="h-full pb-10">
        {optInPopupIsShowing && (<OptinPopup setOptInPopupIsShowing={setOptInPopupIsShowing} />)}
        {/* <div className="p-3 border absolute bg-green-500 w-3/6">{messages[messages.length - 1]?.content}</div> */}
        {/* {JSON.stringify(keys(shouldOpenArtifactWithThisOnFinish))} */}
        {pageIsLoading ? (
          <Loading />
        ) : (
          <div className="flex h-full gap-2">
            <ChatConversation
              containerSettings={{
                messages: chatMessages,
                withFeedbackButtons: false,        
                shouldOpenArtifactWithThisOnFinish,
                setArtifactOptions,
                setShouldOpenArtifactWithThisOnFinish: (obj) => {
                  if (obj.id !== shouldOpenArtifactWithThisOnFinish?.id) {
                    console.log(
                      "Setting the artifact content",
                      obj?.id,
                      shouldOpenArtifactWithThisOnFinish?.id
                    );
                    setShouldOpenArtifactWithThisOnFinish(obj);
                  }
                },
              }}
              title={title}
              subtitle={subtitle}
              inputSettings={{
                type: "Rounded",
                value: input,
                withButtons: true,
                onContextChange: (newContext) => setContext(newContext),
                onChange: handleInputChange,
                onKeyDown: handleInputKeyDown,
                onSubmit: doFormSubmission,
                suggestions,
                placeholder,
              }}
              isLoading={isLoading}
              children={children}
            />
          </div>
        )}
      </div>
    );
  }
);
